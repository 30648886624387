import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  ProgressBar: {
    //overflow : "visible"
  },
  Lable: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 37,
    height: 19,
    padding: 5,
    fontFamily: theme.fonts.family.secondary,
    fontWeight: 700,
    fontSize: 13,
    backgroundColor: "#275DA6",
    color: "white",
    textAlign: "center",
  },
  CompleteText: {
    "&.MuiTypography-root": {
      fontFamily: theme.fonts.family.tertiary,
      fontSize: 10,
      fontWeight: 500,
      color: theme.colors.primary.Grey,
      marginBottom: "15px",
    },
  },
  barContainer: {
    height: '5.25px',
    background: '#EAF2FB',
    borderRadius: '0px',
    width: '100%'
  }
}));
