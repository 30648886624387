import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../../auth/jwt/Jwt'
import { catalogAssetDeclaration, customerAssetsAdapter, initialInput } from './adapters'

export const getAssets = createAsyncThunk('assetsDeclaration/getAssets', async () => {
  try {
    const response = await UseJwt.getAssets()
    return catalogAssetDeclaration(response.data || [])
  } catch (error) {
    console.log('error', error)
    return error
  }
})

export const getAssetsByCustomer = createAsyncThunk('assetsDeclaration/getAssetsByCustomer', async () => {
  try {
    const response = await UseJwt.getAssetsByCustomer()
    return customerAssetsAdapter(response.data)
  } catch (error) {
    return error
  }
})

export const saveAssetByCustomer = createAsyncThunk('assetsDeclaration/saveAssetByCustomer', async (data = []) => {
  try {
    const response = await UseJwt.saveAssetByCustomer({customerAssets: data})
    return response.data
  } catch (error) {
    return error
  }
})

export const deleteAssetByCustomer = createAsyncThunk('assetsDeclaration/deleteAssetByCustomer', async (params) => {
  const { id } = params
  try {
    const response = await UseJwt.deleteAssetByCustomer(id)
    return response.data
  } catch (error) {
    return error
  }
})

export const updateAssetByCustomer = createAsyncThunk('assetsDeclaration/updateAssetByCustomer', async (params = []) => {
  try {
    const response = await UseJwt.updateAssetByCustomer({customerAssets: params})
    return response.data
  } catch (error) {
    return error
  }
})

const initialState = {
  assets: [],
  tempAssets: [],
  customerAssets: {},
  errors: {}
}

export const AssetsDeclaration = createSlice({
  name: 'AssetsDeclaration',
  initialState,
  reducers: {
    addInputInState: (state, action) => {
      const { id } = action.payload
      state.customerAssets[id] = action.payload
    },
    deleteInputInState: (state, action) => {
      const { id } = action.payload
      delete state.customerAssets[id]
      delete state.errors[id]
      if (Object.keys(state.customerAssets).length === 0) state.customerAssets = { ...initialInput }
    },
    onChangeSelect: (state, action) => {
      const { id, assetId } = action.payload
      state.customerAssets[id] = { ...state.customerAssets[id], select: { assetId } }
    },
    onChangeText: (state, action) => {
      const { id, value } = action.payload
      state.customerAssets[id] = { ...state.customerAssets[id], text: { value }  }
  },
    setTempAssets: (state, action) => {
      const assetIds = Object.values(state.customerAssets).map(({ select }) => select?.assetId)
      const tempAssets = state.assets.filter(({ id }) => !assetIds.includes(id))
      state.tempAssets = tempAssets
    },
    setStateErrors: (state, action) => {
      state.errors = { ...state.errors, ...action.payload }
    }
},
  extraReducers: builder =>
  builder.addCase(getAssets.fulfilled, (state, action) => {
    state.assets = action.payload
  })
  .addCase(getAssetsByCustomer.fulfilled, (state, action) => {
    state.customerAssets = action.payload
  })
})

export const {
  addInputInState,
  deleteInputInState,
  onChangeSelect,
  onChangeText,
  setTempAssets,
  setStateErrors
} = AssetsDeclaration.actions

export default AssetsDeclaration.reducer