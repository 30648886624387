import CardItem from "../../molecules/CardItem/CardItem"

const LoanList = ({LoanList, isActive, onClick, handleActive, setting = {} }) => {

  return (

  <div onClick={onClick}>
      <CardItem LoanList={LoanList} isActive={isActive} handleActive={handleActive}/>
  </div>
  )

}

export default LoanList