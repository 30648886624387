import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import services from '../../../../../auth/jwt/Jwt'
import { LoanAmountDetails, LoanTermsDetails } from './options'
import LoanRequestReducer from '../../../../../Store/Reducers/LoanRequestReducer'

export const getCashLoanCollateral = createAsyncThunk(
  'appCollateral/getCashLoanCollateral',
  async () => {
    const response = await services.getCashLoanCollateral()
    return response.data
  }
)

const setActiveOption = (options, value) => {
  return options.map((item) => ({
    ...item,
    active: item.value === value
  }))
}

const initialState = {
  requestedAmount: 0,
  requestedTerms: 0,
  totalAmount: 0,
  currentStep: 1,
  collateralAmount: 0,
  loanOptions: {
    amount: 0,
    terms: 0
  },
  loanAmounts: LoanAmountDetails,
  loanTerms: LoanTermsDetails,
  otherFields: {
    amount: false,
    terms: false
  },
  values: {
    otherAmount: 0,
    otherTerms: 0
  },
  scheduleRequired: false,
  pending: true
}

export const appCollateralSlice = createSlice({
  name: 'appCollateral',
  initialState,
  reducers: {
    setLoanOptions: (state, action) => {
      const { option, value } = action.payload
      // agregar la prop active a la opcion seleccionada
      const nameState = option === 'amount' ? 'loanAmounts' : 'loanTerms'
      state[nameState] = setActiveOption(state[nameState], value)

      state.otherFields[option] = value === 'AMOUNT' || value === 'TERMS'
      // si el valor es OTHER, activar el campo de texto
      if (!state.otherFields[option]) {
        state.loanOptions[option] = value
      }
      
    },
    setAmount: (state, action) => {
      state.loanOptions.amount = action.payload
    },
    setTerms: (state, action) => {
      state.loanOptions.terms = action.payload
    },
    setOtherValules: (state, action) => {
      const { option, value } = action.payload
      state.values[option] = value || 0
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(getCashLoanCollateral.fulfilled, (state, action) => {
        const { requestedAmount, requestedTerms, terms, amount } = action.payload
        
        state.loanOptions = { ...action.payload, amount: action.payload.totalLoanRequests, terms: action.payload.requestedTerms }
        state.loanAmounts = setActiveOption(state.loanAmounts, amount ? amount : requestedAmount)
        state.loanTerms = setActiveOption(state.loanTerms, terms ? terms : requestedTerms)
        state.otherFields.amount = !state.loanAmounts.some(
          (item) => item.active
        )
        state.otherFields.terms = !state.loanTerms.some((item) => item.active)

        // Activar la opción 'OTHER' si ninguna de las opciones predeterminadas está activa
        if (state.otherFields.amount) {
          state.loanAmounts[state.loanAmounts.length - 1].active = true
          state.values.otherAmount = state.loanOptions.amount
        }
        if (state.otherFields.terms) {
          state.loanTerms[state.loanTerms.length - 1].active = true
          state.values.otherTerms = state.loanOptions.terms
        }
        state.pending = false
      })
      .addCase(getCashLoanCollateral.pending, (state, action) => {
        state.pending = true
      })
})

export const { setLoanOptions, setAmount, setTerms, setOtherValules } =
  appCollateralSlice.actions

export default appCollateralSlice.reducer
