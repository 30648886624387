import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../../auth/jwt/Jwt'

export const hasActiveLoan = createAsyncThunk('appTypeOfLoan/hasActiveLoan', async () => {
  const response = await Jwt.getTypeOfLoan()
  return response.data
})

const initialState = {
  customerLoans: {
    hasActiveSecureLoan: true,
    hasActiveUnsecureLoan: true
  },
  pending: true,
  screen: {
    types_of_loans: true,
    types_guarantees: false
  },
  selectOption: {
    loanType: null,
    guarantee: null
  },
  loanSelected: {
    loanType: null,
    collateral: null
  }
}

export const appInitialLoanProcessSlice = createSlice({
  name: 'appTypeOfLoan',
  initialState,
  reducers: {
    handleScreen: (state, action) => {
      const { screen, active } = action.payload
      state.screen[screen] = active
    },
    handleSelectOption: (state, action) => {
      const { selected, option } = action.payload
      state.selectOption[selected] = option
    },
    handleLoanSelected: (state, action) => {
      const { loanType, collateral } = action.payload
      state.loanSelected.loanType = loanType
      state.loanSelected.collateral = collateral
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(hasActiveLoan.fulfilled, (state, action) => {
        state.customerLoans = action.payload
        state.pending = false
      })
      .addCase(hasActiveLoan.pending, (state, action) => {
        state.pending = true
      })
  }
})

export const { handleScreen, handleSelectOption, handleLoanSelected } = appInitialLoanProcessSlice.actions

export default appInitialLoanProcessSlice.reducer