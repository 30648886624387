import React, { useRef } from "react"
import { Box, Stack } from "@mui/material"
import ArrowButton from "../ArrowButton/ArrowButton"
import { useStyles } from "./ScheduleAppointmentStyle3"
import Calendar from "../../assets/Images/Grupo 1001.svg"
import { Typography } from "@material-ui/core"
import Button from "../Button/Button"
import clsx from "clsx"
import { Link } from "react-router-dom"
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom"
import LocalStorageShedule from "../../Utils/LocalStorageShedule"
import moment from "moment"

const ScheduleThankYou = () => {
  const customer_schedule = JSON.parse(LocalStorageShedule.getShedule())

  const classes = useStyles()
  const inputFile = useRef(null)
  const navigate = useNavigate()
  const { dataSchedule } = useSelector((state) => state.ScheduleRequestReducer)
  // const [firstName, lastName, branch] = ['name', 'lastname', 'branchName']

  const [firstName, lastName, branch] = [
    dataSchedule.firstName,
    dataSchedule.lastName,
    dataSchedule.branch,
  ]

  return (
    <Box>
      <Box className={classes.ScheduleAppointmentContainer}>
        <Box className={clsx(classes.ThankYouPoppup, classes.ApprovedPoppup)}>
          <Box
            className={clsx(
              classes.ApprovedPoppupImage,
              classes.ThankYouPoppupImage
            )}
          >
            <img
              className={clsx(classes.ApprovedImage, classes.ThankYouImage)}
              src={Calendar}
              alt=""
            />
          </Box>
          <Typography className={classes.ThankYouText}>
            <strong>Dear {`${firstName} ${lastName}`}</strong> <br />
             Your appointment has been successfully scheduled. Please visit {` ${branch}`} on {moment(dataSchedule?.appointmentDate).format('DD-MM-YY')} at {moment(dataSchedule?.appointmentTime).format('LT')} Thank you for giving us the opportunity to serve you.
          </Typography>
          </Box>
      </Box>

    </Box>
  )
}

export default ScheduleThankYou
