import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    backgroundColor: theme.colors.secondary.White,
    width: "100%",
    height: 62.4,
    "@media (max-width: 400px)": {
      height: 20,
    },
    // filter: "drop-shadow(2.835px 2.835px 2.834px rgba(91, 91, 91, 0.349))",
  },
  ContainerWrapper: {
    // width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "10px 10px",
  },
  MenuIcon: {
    color: "#BF2828",
    cursor: "pointer",
  },
  Logo: {
    width: 188.61,
    height: 37.83,
    marginLeft: 30,
  },
}));
