import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const Index = ({ onNext, titleNext = "Continue", onPrevious }) => {
  return (
    <Stack direction="row" spacing={2} justifyContent="center" marginTop={30}>
      {onPrevious && (
        <Button variant="outlined" onClick={onPrevious}>
          Previous
        </Button>
      )}
      <Button
        variant="contained"
        color="success"
        onClick={onNext}
        sx={{ width: onPrevious ? 'auto' : '80%' }} // Adjust width based on presence of Previous button
      >
        {titleNext}
      </Button>
    </Stack>
  );
};

export default Index;
