import axios from 'axios'
import Actions from '../Types'
import LocalStoreServices from '../../Utils/LocalStoreServices'
import { baseUrl } from '../../baseUrl'
import { Interceptor } from '../../Utils/Interceptor'
import toast, { Toaster } from 'react-hot-toast'

const localStorageServices = LocalStoreServices.getService()

const showAlert = () => {
  toast.success('Successfully verified')
  return <Toaster />
  }

export function otpValidation(data) {
  const AxiosInterceptor = Interceptor()
  return async (dispatch, getState) => {
    const url = `${baseUrl}/customers/confirmation/phone`
    dispatch({
      type: Actions.LOADING,
      payload: true,
    })
    await AxiosInterceptor.post(url, data)
      .then((response) => {
        dispatch({
          type: Actions.OTPVALIDATION,
          payload: response.data,
        })
        dispatch({
          type: Actions.OTPSUCCESS,
        })
        dispatch({
          type: Actions.REFERENCESTATUS,
          payload: true,
        })
        // localStorageServices.setToken(response.data.token)
        localStorageServices.setReferenceStatus(true)
        //  localStorageServices.setExpirationDate(response.data.expirationDate)
        if (Number(response.data.status) === 0) showAlert()
      })
      .catch((e) => {
        if (
          e.response.data.error.message === 'Invalid code, please try again.' ||
          e.response.data.error.message === 'Your code has expired.'
        ) {
          dispatch({
            type: Actions.OTPERROR,
            payload: true,
          })
        }
      })
      .finally(() => {
        dispatch({
          type: Actions.LOADING,
          payload: false,
        })
      })
  }
}
