import Actions from "../Types";
import { Interceptor } from "../../Utils/Interceptor";

export function loanRequest(data) {

  const AxiosInterceptor = Interceptor();
  return async (dispatch, getState) => {
    sessionStorage.setItem('cusprocode', '')
    const url = "/loan-requests";
    await AxiosInterceptor.post(url, data)
      .then((response) => {
        dispatch({
          type: Actions.LOANREQUEST,
          payload: response.data,
        });
        if(response.data.promoCode){
          sessionStorage.setItem('cusprocode', response.data.promoCode)
          dispatch({
            type: Actions.SETISVALIDPROMOCODE,
            payload: { valid: true}
          })
        }      
      })
      .catch((e) => {
        
        if(e.response.status == 404 || e.response.status == 400 ){
          sessionStorage.setItem('cusprocode', '')
          dispatch({
            type: Actions.SETISVALIDPROMOCODE,
            payload: { valid: false}
          })
          dispatch({
            type: Actions.PROMOCODEINVALID,
            payload: ''
          })
          dispatch({
            type: Actions.SETMONTHLYINSTALLMENTWITHPROMOCODE,
            payload: null
          })
        }
       
      });
  };
}
