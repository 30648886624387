import React, { useRef } from "react";
import { Box, Stack } from "@mui/material";
import ArrowButton from "../../ArrowButton/ArrowButton";
import { useStyles } from "./SignDocumentStyle"
import Approved from "../../../assets/Images/Grupo 999.svg";
import Calendar from "../../../assets/Images/Calendar.png";
import { Typography } from "@material-ui/core";
import Button from "../../Button/Button";
import Select from "../../Inputs/Select";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DatePicker from "../../Inputs/DatePicker";
import TimePicker from "../../Inputs/TimePicker";
import clsx from "clsx";

const ScheduleApproved = () => {
  const classes = useStyles();
  const inputFile = useRef(null);

  return (
       <Box className={classes.ScheduleAppointmentContainer}>
        <Box className={classes.ApprovedPoppup}>
          <Box className={classes.ApprovedPoppupImage}>
            <img className={classes.ApprovedImage} src={Approved} alt="" />
          </Box>
          <Typography className={classes.ApprovedText}>
          <strong>   As your approved loan facility is above BZ$7,000 ,</strong> <br/>
please set up an appointment with the nearest branch in order to sign all necessary documents.
          </Typography>
        </Box>
      </Box>
  );
};

export default ScheduleApproved;
