import React, { useRef, useEffect, useState } from "react";
import { Interceptor } from "../../../Utils/Interceptor";
import * as yup from "yup";
import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useStyles } from "./MissingDocsUploadStyle";
import Button from "../../Button/Button";
import LoadingButton from "../../Button/LoadingButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Select from "../../Inputs/Select";
import { FormikProvider, useFormik, Field } from "formik";
import { useNavigate } from "react-router-dom";

import ClearIcon from "@mui/icons-material/Clear";
import { baseUrl } from "../../../baseUrl";
const MissingDocsUpload = ({ Files }) => {
  const classes = useStyles();
  const inputJobLetterImageRef = useRef(null);
  const navigate = useNavigate();
  
  const inputSsnImageImageRef = useRef(null);
  const [customerPrevData, setCustomerPrevData] = useState({});
  const [proofSalaryPrevData, setProofSalaryPrevData] = useState({});
  const AxiosInterceptor = Interceptor();
  const [schema, setSchema] = useState({});

  const validationSchema = yup.object(schema);
  useEffect(() => {
    getUserInformation();
  }, []);
  useEffect(() => {
    let shape = {};
    if (!Files?.ssnImage) {
      shape.ssnImage = yup
        .mixed()
        .required("This is required")
        .test("file-size", "The file cannot exceed 2MB ", function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4);
            return filesize <= 2;
          } else {
            return true;
          }
        });
    }
   
    if (!Files?.jobLetterImage) {
      shape.jobLetterImage = yup
        .mixed()
        .required("This is required")
        .test("file-size", "The file cannot exceed 2MB ", function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4);
            return filesize <= 2;
          } else {
            return true;
          }
        });
    }
    setSchema(shape);
  }, [Files]);
  const getUserInformation = async () => {
    await AxiosInterceptor.get("/customers")
      .then((response) => {
        setCustomerPrevData(response.data);
      })
    await AxiosInterceptor.get("/income-proof-salary-users")
      .then((response) => {
        setProofSalaryPrevData(response.data);
      })
  };
  const formik = useFormik({
    initialValues: {
      jobLetterImage: null,
   
      ssnImage: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const convertBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve("");
      });
    }
  };
  const handleSubmit = (values) => {
    uploadFiles(values).then(async function () {
      await changeStatus();
      formik.setSubmitting(false);
      navigate("/Customer/Congratulations");
    });
  };
  const changeStatus = async () => {
    await AxiosInterceptor.post("/LoanStatus/SendToReview");
  };
  const uploadFiles = async (values) => {
    let formData = {
      jobLetterImage: await convertBase64(values.jobLetterImage),
    
      ssnImage: await convertBase64(values.ssnImage),
    };

    let data = customerPrevData;
    if (!Files?.ssnImage) {
      data = {
        ...data,
        proofOfSsnImg: formData.ssnImage,
      };
    }
  
    await AxiosInterceptor.put(
      "/schedule-appointment-missing-personal-data",
      data
    )
    if (!Files?.jobLetterImage) {
      let jobLetterData = {
        ...proofSalaryPrevData,
        jobLetterFile: formData.jobLetterImage,
      };
      await AxiosInterceptor.put(
        "/schedule-appointment-missing-job-letter",
        jobLetterData
      )
    }
  };

  const onJobLetterImageRefClick = () => {
    inputJobLetterImageRef.current.click();
    formik.setFieldTouched("jobLetterImage")
  };

  const onSsnImageImageRefClick = () => {
    inputSsnImageImageRef.current.click();
    formik.setFieldTouched("ssnImage")
  };

  return (
    <Box>
      <FormikProvider value={formik}>
        <Box className={classes.ScheduleAppointmentContainer}>
          <Typography className={classes.Heading}>
          THE FOLLOWING DOCUMENTS ARE REQUIRED. PLEASE UPLOAD THE REQUIRED DOCUMENTS IN ORDER TO PROCEED
          </Typography>
          <Stack direction="row" spacing={2}>
          <Stack
            direction="column"
            s
            spacing={{ xs: 1, sm: 2 }}
            width="100%"
            mb={4}
          >
            {!Files?.ssnImage && (
              <>
                <Button
                  Title="Support documents for loan purpose selected"
                  variant="h7"
                  className={classes.UploadFiles}
                  endIcon={<AttachFileIcon />}
                  onClick={onSsnImageImageRefClick}
                />
                <Field name="ssnImage">
                  {({ field, form, meta }) => (
                    <>
                      <input
                        {...field}
                        type="File"
                        accept="application/pdf,image/*"
                        onChange={(event) => {
                          form.setFieldValue(
                            "ssnImage",
                            event.currentTarget.files[0]
                          );
                        }}
                        value=""
                        style={{ display: "none" }}
                        ref={inputSsnImageImageRef}
                        error={meta.touched && meta.error}
                      />
                      <Typography className={classes.UploadFileText}>
                      Only .jpeg, .pdf, .png, .jpg files are permitted with a maximum of 2MB
                      </Typography>
                      { meta.touched && meta.error ? (
                        <p
                          className={classes.fileText}
                          style={{ color: "red" }}
                        >
                          {meta.error}
                        </p>
                      ) : (
                        meta?.value?.name && (
                          <p className={classes.uploadFileName}>
                            {`${String(meta.value.name).substring(0, 25)}...`}
                            <span>
                              <ClearIcon
                                className={classes.deleteFileIcon}
                                onClick={() => {
                                  formik.setFieldValue(field.name, null);
                                }}
                              />
                            </span>
                          </p>
                        )
                      )}
                    </>
                  )}
                </Field>
              </>
            )}
          </Stack>
     
          <Stack
            direction="column"
            spacing={{ xs: 1, sm: 2 }}
            width="100%"
            mb={6}
          >
            {!Files?.jobLetterImage && proofSalaryPrevData.idJobLetter != 2 && (
              <>
                <Button
                  Title="Job letter"
                  variant="h7"
                  className={classes.UploadFiles}
                  endIcon={<AttachFileIcon />}
                  onClick={onJobLetterImageRefClick}
                />
                <Field name="jobLetterImage">
                  {({ field, form, meta }) => (
                    <>
                      <input
                        {...field}
                        type="File"
                        accept="application/pdf,image/*"
                        onChange={(event) => {
                          form.setFieldValue(
                            "jobLetterImage",
                            event.currentTarget.files[0]
                          );
                        }}
                        value=""
                        style={{ display: "none" }}
                        ref={inputJobLetterImageRef}
                        error={meta.touched && meta.error}
                      />
                      <Typography className={classes.UploadFileText}>
                      Only .jpeg, .pdf, .png, .jpg files are permitted with a maximum of 2MB
                      </Typography>
                      { meta.touched && meta.error ? (
                        <p
                          className={classes.fileText}
                          style={{ color: "red" }}
                        >
                          {meta.error}
                        </p>
                      ) : (
                        meta?.value?.name && (
                          <p className={classes.uploadFileName}>
                            {`${String(meta.value.name).substring(0, 25)} ...`}
                            <span>
                              <ClearIcon
                                className={classes.deleteFileIcon}
                                onClick={() => {
                                  formik.setFieldValue(field.name, null);
                                }}
                              />
                            </span>
                          </p>
                        )
                      )}
                    </>
                  )}
                </Field>
              </>
            )}
          </Stack>
          </Stack>
          <Box className={classes.SubmitBtn} onClick={formik.submitForm}>
            <LoadingButton Title="SUBMIT" loading={formik.isSubmitting} />
          </Box>
        </Box>
      </FormikProvider>
    </Box>
  );
};

export default MissingDocsUpload;
