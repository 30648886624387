import { Stack, TextField, Typography } from "@mui/material"
import { useStyles } from './Style'

export const QuestionOpen = ({questionInf, dataForm, setData}) => {
  const classes = useStyles()
  const handleChange = (answer) => {
    setData({...dataForm, [questionInf.id]: {questionId: questionInf.id, answer: String(answer)}})
  }
  return (
    <>
      <Typography className={classes.QuestionTitle}>
        {questionInf?.question}
      </Typography>
      <Stack direction="row" className={classes.QuestionContenedor}>
          <TextField multiline placeholder={questionInf?.question} onChange={(e) => handleChange(e.target.value)}/>
      </Stack>
    </>
  )
}
