import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../auth/jwt/Jwt'

export const hasRegistrationSection = createAsyncThunk(
  'appRegistrationSection/RegistrationSection',
  async (districtId) => {
    const response = await Jwt.getRegistrationSection(districtId)
    return response.data
  }
)

const initialState = {
    count: 0,
    data: []
}

export const appRegistrationSectionSlice = createSlice({
  name: 'appRegistrationSection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hasRegistrationSection.fulfilled, (state, action) => {
      state.count = action.payload.count
      state.data = action.payload.data
    })
  }
})

export default appRegistrationSectionSlice.reducer
