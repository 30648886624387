import * as yup from 'yup'
function isAmountValid(value) {
  if (value) {
    return parseFloat(value) < 100000000
  } else {
    return true
  }
}
function toFloat(value) {
  let float
  if (value) {
    float = parseFloat(value.replace(/,/g, ''))
    if (isNaN(float)) {
      float = 0
    }
  } else {
    float = 0
  }
  return float
}
export const validationSchema = yup.object({
  loanPayments: yup.array().of(
    yup.object({
      amount: yup
        .string()
        .test('digitAmount', '**Invalid amount', function (value) {
          return isAmountValid(value)
        }),
      file: yup
        .mixed()
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        }),
      bankId: yup.object('').when('amount', (val, schema) => {
        if (toFloat(val) > 0) {
          return yup.object().required('Please Select bank')
        } else return yup.object('').notRequired()
      }),
    })
  ),
  morageOrRent: yup.array().of(
    yup.object({
      amount: yup
        .string()
        .nullable()
        .test('digitAmount', '**Invalid amount', function (value) {
          return isAmountValid(value)
        }),
      file: yup
        .mixed()
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        }),
      bankId: yup.object('').when('amount', (val, schema) => {
        if (toFloat(val) > 0) {
          return yup.object('').required('Please Select bank')
        } else return yup.object('').notRequired()
      }),
    })
  ),
  creditCard: yup.array().of(
    yup.object({
      amount: yup
        .string()
        .test('digitAmount', '**Invalid amount', function (value) {
          return isAmountValid(value)
        }),
      file: yup
        .mixed()
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        }),
      bankId: yup.object('').when('amount', (val, schema) => {
        if (toFloat(val) > 0) {
          return yup.object('').required('Please Select bank')
        } else return yup.object('').notRequired()
      }),
    })
  ),
  overDraft: yup.array().of(
    yup.object({
      amount: yup
        .string()
        .test('digitAmount', '**Invalid amount', function (value) {
          return isAmountValid(value)
        }),
      file: yup
        .mixed()
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        }),
      bankId: yup.object('').when('amount', (val, schema) => {
        if (toFloat(val) > 0) {
          return yup.object('').required('Please Select bank')
        } else return yup.object('').notRequired()
      }),
    })
  ),
  other: yup.array().of(
    yup.object({
      amount: yup
        .string()
        .test('digitAmount', '**Invalid amount', function (value) {
          return isAmountValid(value)
        }),
      file: yup
        .mixed()
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        }),
      bankId: yup.object('').when('amount', (val, schema) => {
        if (toFloat(val) > 0) {
          return yup.object('').required('Please Select bank')
        } else return yup.object('').notRequired()
      }),
    })
  ),
})

export const template = {
  amount: '0',
  bankId: '',
  file: null,
  id: 0,
}
