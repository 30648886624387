import Actions from "../Types";


export function SetValidPromoCode(data) {
    return async (dispatch, getState) => { 
        dispatch({
            type: Actions.SETISVALIDPROMOCODE,
            payload: { valid: data}
        })
    }
};
