import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useStyles } from '../../../Components/Inputs/Style'
import clsx from 'clsx'

export const InputSelect = ({
  open,
  setOpen,
  options = [],
  onChange,
  onBlur,
  value,
  placeholder,
  QuestionId,
  SelectID,
  dataForm,
  multiple = false,
}) => {
  // console.log('OPTIONS:', options)
  // console.log('QUESTION ID:', QuestionId)
  // console.log('dataform:', dataForm)

  const [loadSelect, setLoadSelect] = useState([])

  useEffect(() => {
    const newData = options.map(option => ({value: option.id, label: option.option}))
    setLoadSelect(newData)
    // console.log(loadSelect)
  }, [options])

  useEffect(() => {
    // setLoadSelect([{}])
    onChange({...dataForm})
  }, [])

  const handleChange = ({value}) => {
    // console.log('ID del select', dataForm) // dropdownId
    onChange({...dataForm, [QuestionId]: { ...dataForm[QuestionId], questionId: QuestionId, dropdownId: value}})
  }

  const handleMultiple = (e) => {
    const options = e.map(val => ({[val.value]: {selectId: SelectID, dropdownId: val.value, questionId: QuestionId}}))
    // console.log(options);
    onChange({...dataForm, [QuestionId]: { ...dataForm[QuestionId], questionId: QuestionId, dropdownId: options}})
  }

  const classes = useStyles()

  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      // value={[]} // Establecer un array vacío si es múltiple, de lo contrario, nulo
      fullWidth={true}
      // onOpen={() => {
      //   setOpen(true)
      // }}
      // onClose={() => {
      //   setOpen(false)
      // }}
      multiple={multiple}
      clearIcon={false}
      // isOptionEqualToValue={(option, value) => option?.name === value?.name}
      // getOptionLabel={(option) => option?.name || ''}
      options={loadSelect}
      className={clsx(classes.SelectFeedBack)}
      onChange={(e, value) => multiple ? handleMultiple(value) : handleChange(value)}
      // onChange={(e, value) => handleChange(value)}
      // value={loadSelect[0]}
      classes={{ paper: classes.paper }}
      renderInput={(params) => (
        <TextField
        // value={params[0]}
        className={classes.input}
        // value={loadSelect[0].label}
          placeholder={'Select option'}
          onBlur={onBlur}
          {...params}
          label=''
          // sx={{
          //   '& legend': { display: 'none' },
          //   '& fieldset': { top: 0 },
          // }}
          // InputProps={{
          //   ...params.InputProps,
          //   endAdornment: (
          //     <React.Fragment>
          //       {/* {loading ? (
          //           <CircularProgress color="inherit" size={20} />
          //         ) : null} */}
          //       {params.InputProps.endAdornment}
          //     </React.Fragment>
          //   ),
          // }}
        />
      )}
    />
  )
}
