import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  OnlineLoanContainer: {
    minHeight: "298.09px",
    backgroundColor: "#F4EBFE",
    padding: "10px",
  },
  OnlineLoanText: {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "300",
    lineHeight: "27.32px",
    color: "#706F6F",
    fontFamily: theme.fonts.family.tertiary,
  },
  OnlineLoanInput: {
    width: "95%",
  },
  Btn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "74px",
    marginBottom: "84px",
  },
  ScheduleBtn: {
    "& .MuiTypography-root": {
      fontFamily: theme.fonts.family.primary,
      fontSize: "13px",
      fontWeight: "600",
      lineHeight: "15.51px",
      letterSpacing: "0.2em",
     
    },
    width: "176px",
    height: "39px",
  },
  Links: {
    textDecoration: "none",
  },
  li: {    
    fontFamily: theme.fonts.family.primary,
    color: theme.colors.primary.Darkblue,
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '24px'
  },
  title: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '30px'
  },
  span:{
    fontFamily: theme.fonts.family.primary,
    color: '#575756',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '24px'
  }
}));
