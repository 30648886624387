import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  PopupContainer: {
    width: "467.05px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EDEDED",
    filter: "drop-shadow(4px 4px 3px rgba(91, 91, 91, 0.349))",
    borderRadius: "10px",
    ["@media (max-width: 600px)"]: {
      width: "100%",
    },
  },
  PopupHeader: {
    height: "62.92px",
    // background: " #56AF31",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  PopupHeaderLogo: {
    height: "111.14px",
    width: "111.14px",
    // backgroundColor: "#ffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    position: "absolute",
    top: "-70px",
    filter: " drop-shadow(2.835px 2.835px 2.834px rgba(91, 91, 91, 0.349))",
  },
  Logo: {
    // height: "90px",
    // width: "90px",
  },
  PopupMainSection: {
    width: "100%",
    position: "relative",
    background: "#FFFFFF",
    margin: "20px auto",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ["@media (max-width: 600px)"]: {
      width: "95%",
    },
  },
  PopupText: {
    "&.MuiTypography-root": {
      textAlign: "center",
      fontFamily: theme.fonts.family.secondary,
      fontSize: "16px",
      lineHeight: "21.86px",
      fontWeight: "300",
      margin: "14.53px 0",
      color: "#706F6F",
      paddingTop: "50px",
      paddingLeft: "19.53px",
      paddingRight: "16.54px",
      FontFamily: "Avenir",
    },
  },
  PopupExpireTime: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: "34.55px",
    marginBottom: "23.91px",
    gap: "6.23px",
  },
  ClockImage: {
    width: "24px",
    height: "24px",
  },
  ExpireTimeText: {
    "&.MuiTypography-root": {
      fontSize: "16px",
      fontWeight: "500",
      color: "#005FAA",
      fontFamily: theme.fonts.family.secondary,
    },
  },
  PopupBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    width: "180px",
    height: "39.01px",
    background: "#005FAA",
    marginBottom: "39px",
  },
  PopupButton: {
    "&.MuiButton-root": {
      color: "#ffff",
      fontSize: "13px",
      lineHeight: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0.2em",
      fontFamily: theme.fonts.family.primary,
    },
  },
  AlertBoxContainer: {
    "& .MuiDialog-paper": {
      width: "50%",
      borderRadius: "10px",
      marginTop: "100px",
      marginRight: "48px",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      ["@media (max-width: 600px)"]: {
        width: "100%",
      },
    },
  },
  AlertBoxHeader: {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    height: "55.04px",
    // backgroundColor: "#E30613",
    filter: "drop-shadow(4px 4px 3px rgba(91, 91, 91, 0.349))",
  },
  AlertMainSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  HeaderLogoContainer: {
    position: "relative",
    width: "111.14px",
    height: "111.14px",
    backgroundColor: "#ffff",
    top: "-42px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    filter: "drop-shadow(2.835px 2.835px 2.834px rgba(91, 91, 91, 0.349))",
  },
  HeaderLogoImage: {
    width: "90.02px",
    height: "90.02px",
    borderRadius: "50%",
    backgroundColor: "#E30613",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    filter: "drop-shadow(2.835px 2.835px 2.834px rgba(91, 91, 91, 0.349))",
  },
  CrossImage: {
    // width: "61.18px",
    // height: "61.18px",
  },

  AlertText: {
    "&.MuiTypography-root": {
      padding: "0 30px",
      fontSize: "15.87px",
      fontWeight: "500",
      lineHeight: "22px",
      letterSpacing: "0.1em",
      fontFamily: theme.fonts.family.secondary,
      color: "#A3A3A3",
      marginBottom: "48.23px",
    },
  },
  AlertBtn: {
    "&.MuiButton-root": {
      width: "176.01px",
      height: "39.01px",
      backgroundColor: "#005FAA",
      margin: " 9px",
      color: "white",
      fontFamily: theme.fonts.family.primary,
      fontSize: "13px",
      fontWeight: "600",
      lineHeight: "15.51px",
      letterSpacing: "3px",
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      ["@media (max-width: 460px)"]: {
        width: "auto",
      },
    },
  },
  AlertBtnTry: {
    "&.MuiButton-root": {
      width: "176.01px",
      height: "60px",
      backgroundColor: "#005FAA",
      margin: " 9px",
      color: "white",
      fontFamily: theme.fonts.family.primary,
      fontSize: "13px",
      fontWeight: "600",
      lineHeight: "15.51px",
      letterSpacing: "3px",
      padding: "10px",
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      ["@media (max-width: 460px)"]: {
        width: "auto",
      },
    },
  },

  // seccess Section//

  SuccessBoxContainer: {
    width: "469.2px",
    height: "220.19px",
    position: "relative",
    margin: "40px auto",
    borderRadius: "10px",
    boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.25)",
  },

  SuccessBoxHeader: {
    width: "469.2px",
    height: "55.04px",
    backgroundColor: "#F07F3C",
    position: "relative",
  },
  SuccessMainSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  SuccessLogoContainer: {
    position: "relative",
    width: "111.14px",
    height: "111.14px",
    backgroundColor: "#ffff",
    top: "-44px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    filter: "drop-shadow(2.835px 2.835px 2.834px rgba(91, 91, 91, 0.349))",
  },
  SuccessLogoImage: {
    width: "90.02px",
    height: "90.02px",
    borderRadius: "50%",
    backgroundColor: "#F07F3C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    filter: "drop-shadow(2.835px 2.835px 2.834px rgba(91, 91, 91, 0.349))",
  },
  SuccessText: {
    "&.MuiTypography-root": {
      fontSize: "20px",
      fontWeight: "800",
      lineHeight: "27.32px",
      letterSpacing: "0.1em",
      fontFamily: theme.fonts.family.secondary,
      color: "#706F6F",
    },
  },
  SuccessDiscription: {
    maxWidth: "85%",
    height: "118.83px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#EAF2FB",
    margin: "30px auto",
    padding: "0px 20px",
  },

  SuccessDiscriptionText: {
    "&.MuiTypography-root": {
      fontSize: "20px",
      lineHeight: "27.32px",
      letterSpacing: "0.024em",
      textAlign: "center",
      color: "#706F6F",
      fontFamily: theme.fonts.family.tertiary,
    },
  },
}));
