import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../auth/jwt/Jwt'

export const hasCreditCardVerify = createAsyncThunk(
  'appcreditCard/creditCard',
  async () => {
    const response = await Jwt.postCreditCard()
    return response.data
  }
)

const initialState = {
    hasCreditCard: false
}

export const appCreditCardSlice = createSlice({
  name: 'appCreditCard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hasCreditCardVerify.fulfilled, (state, action) => {
        state.hasCreditCard = action.payload.hasCreditCard
    }).addCase(hasCreditCardVerify.pending, (state, action) => {
      state.pending = false
    })
  }
})


export default appCreditCardSlice.reducer
