
import React, { useRef } from "react";
import { Box, Stack,Typography } from "@mui/material";
import { useStyles } from "./ScheduleAppointmentStyle3";
import Button from "../../Button/Button";
import Select from "../../Inputs/Select";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DatePicker from "../../Inputs/DatePicker";
import TimePicker from "../../Inputs/TimePickerAppointment";
import SalaryAssignment from "../../../assets/docs/SalaryAssignment.doc";
import ScheduleApproved from "./ScheduleApproved";
import SheduleThankYou from "./SheduleThankYou";
import { useFormik, Field, FormikProvider } from "formik";
import { baseUrl } from "../../../baseUrl";
import { useState } from "react";
import { useEffect } from "react";
import { ScheduleRequestAppointments } from "../../../Store/Actions/ScheduleRequestAppointment";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import {validationSchema} from "./validation"
import moment from "moment";
import { getMinHours, hoursIntervals, minDate, minTime } from "../../../Utils/useMinDate";
import { toast, Toaster } from "react-hot-toast";
import { useMovil } from "../../../hooks/useMovil";
import AlertInfo from "../../Alert";
import CircularProgress from "@mui/material/CircularProgress";

//import { gapi } from "gapi-script";

const hoursInterval = [
  `${moment().format('YYYY-MM-DD')}T08:00:00`,
  `${moment().format('YYYY-MM-DD')}T09:00:00`,
  `${moment().format('YYYY-MM-DD')}T10:00:00`,
  `${moment().format('YYYY-MM-DD')}T11:00:00`,
  `${moment().format('YYYY-MM-DD')}T12:00:00`,
  `${moment().format('YYYY-MM-DD')}T13:00:00`,
  `${moment().format('YYYY-MM-DD')}T14:00:00`,
  // `${moment().format('YYYY-MM-DD')}T15:00:00`,
]


const loadTime = (today) => {
  const newTime = minTime(today, hoursInterval, '08:00:00')
  if (newTime !== null) {
    return newTime
  } 

  return `${moment().format('YYYY-MM-DD')}T08:00:00`
}

const MakeAppointment = () => {
  const { hasDownload, message } = useMovil('salaryassignment')
  const dispatch = useDispatch();
  const classes = useStyles();
  const inputFile = useRef(null);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([])

  // // // const calendarID = process.env.REACT_APP_CALENDAR_ID;
  // // // const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  // // // const accessToken = process.env.REACT_APP_GOOGLE_ACCESS_TOKEN;

  const formik = useFormik({
    initialValues: {
      salary: null,
    },
    //  enableReintialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const countPage = useRef(0)
  useEffect(() => {
    if (countPage.current === 0) {
      countPage.current = 1
      window.scroll(0, 0)
      return
    }
  }, [])

  const [valueTime, setValueTime] = useState("")
  const [upload, setupload] = useState("")
  const [salaryLetter, setSalaryLetter] = useState("")
  const [showScheduleThankyou, setShowScheduleThankyou] = useState(false)

  const [errorTime, setErrorTime] = useState(false)
  const [errorDate, setErrorDate] = useState(false)
  
  const [dataSchedule, setDataSchedule] = useState({
    branchId: "",
    date: minDate('14:00:00Z'),
    time: "",
  });

  useEffect(() => {
    setDataSchedule({ ...dataSchedule, time: valueTime });
  }, [valueTime]);

  const handleSubmit = async () => {
    if (dataSchedule.branchId === "" || dataSchedule.date === "") {
      return toast.error("Please complete all the required fields.");
    }
    if (errorTime || errorDate) return toast.error("Please select a valid date and time.")

    setLoading(true);

    const values = {
      ...salaryLetter,
      branch: dataSchedule.branchId,
      appointmentDate: moment(dataSchedule.date).format(),
      appointmentTime: moment(dataSchedule.time).format(),
    };
    const responde = await dispatch(ScheduleRequestAppointments({ ...values })).then((res) => console.log(res));
    setShowScheduleThankyou(true);
  };

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `${SalaryAssignment}`;
    link.href = SalaryAssignment;
    link.click();
  };

  // UPLOAD SALARY ASSIGNMENT
  const File = async (e) => {
    const file = e.target.files[0];
    setupload(file.name);
    const base64 = await convertBase64(file);
    const salaryLetter = base64.split(",")[1];
    setSalaryLetter({
      salaryLetter,
    });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onButtonClick = () => {
    inputFile.current.click();
    formik.setFieldTouched("salary")
  };
  return (
    <Box>
      <Toaster />
      <Box>
        <ScheduleApproved />
      </Box>
      <FormikProvider value={formik}>
        <Box className={classes.ScheduleAppointmentContainer}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 1, sm: 2 }}
            mt={8}
          >
            { hasDownload && (
            <Button
              Title="Download the salary assignment template"
              variant="h7"
              className={classes.UploadFiles}
              endIcon={<FileDownloadIcon />}
              onClick={onDownload}
            />
            )}
            {!hasDownload && (
              <AlertInfo content={message}/>
            )}
              <Stack
                  width={{ xs: "100%", sm: "100%" }}
                  direction="column"
                >
            </Stack>
          </Stack>
          <Stack width={{ xs: "100%", sm: "49.5%" }} mt={6}>
            <Select
              Label="SELECT YOUR PREFERRED BRANCH"
              placeholder="Dangriga Branch"
              style={{ backgroundColor: "#ffff" }}
              ApiUrl={`${baseUrl}/branches`}
              name="branchId"
              onChange={(e, val) =>
                setDataSchedule({
                  ...dataSchedule,
                  branchId: val?.id,
                  branchName: val?.name,
                })
              }
              required
            />
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2 }}
            mt={6}
          >
            <DatePicker
              Label="SELECT APPOINTMENT DATE"
              openTo="year"
              views={["year", "month", "day"]}
              style={{ backgroundColor: "#ffff" }}
              disablePast={true}
              onChange={(e) => setDataSchedule({ ...dataSchedule, date: e })}
              onError={(error) => setErrorDate(error)}
              disableFuture={false}
              value={dataSchedule.date}
              minDate={minDate('14:00:00Z')}
              name="appointmentDate"
            />
            <TimePicker
              Label=" SELECT APPOINTMENT TIME"
              placeholder="8:00AM"
              style={{ backgroundColor: "#ffff" }}
              name="appointmentTime"
              appointmentDate={dataSchedule.time}
              // minTime={loadTime(moment().format())}
              minTime={getMinHours(dataSchedule.date, hoursIntervals)}
              onError={(error) => setErrorTime(error)}
              setValueTime={setValueTime}
              onChange={(e) => setDataSchedule({ ...dataSchedule, time: e })}
            />
          </Stack>
          <Box className={classes.SubmitBtn}>
            {(loading && !showScheduleThankyou) && (
              <Box sx={{ display: "flex" }}>
              <CircularProgress />
              </Box>
            )}

            {(!showScheduleThankyou && !loading) ? (
              <Button Title="SUBMIT" onClick={handleSubmit} />
            ) : null}
          </Box>
        </Box>
      </FormikProvider>
      <Box>{showScheduleThankyou ? <SheduleThankYou /> : null}</Box>
    </Box>
  )
}
export default MakeAppointment
