import React, { useEffect, useState } from 'react'
import { Grid, Typography, styled, } from '@mui/material'
import { useStyles } from '../styles'
import ModalItrem from '../../../../../../Components/AtomicDesign/molecules/ModalItem/ModalItrem'
import ExclamationIcon from '../../../../../../assets/Images/exclamation-circle.png'
import TooltipIcon from '../../../../../../assets/Images/icon-blue.png'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const SelectAccountComponent = ({
  accounTypes,
  handleCallback,
  selectedAccounts,
  handleAccountSelected,
  handleDeleted,
  loanTypeId
}) => {

  const LightTooltip2 = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: '#0F5CAC',
      fontSize: 14,
      maxWidth: 261,
      filter: 'drop-shadow(2px 2px 2px rgba(91, 91, 91, 0.349))',
      padding: '1rem'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: 'white',
      '&::before': {
        backgroundColor: 'white'
      }
    }
  }))

  const classes = useStyles()
  const [totalFunds, setTotalFunds] =
    useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  const isSelected = () => {
    if (loanTypeId === 2) {
      return accounTypes.map((acc) =>
        selectedAccounts.some((el) => el.accountNumber === acc.accountNumber)
          ? true
          : false
      )
    } else {
      return accounTypes.map((acc) =>
        selectedAccounts.some((el) => el.accountID === acc.accountID)
          ? true
          : false
      )
    }
  }

  const [checkedState, setCheckedState] = useState(isSelected())

  const handleOnChange = (position, acc, e) => {
    const updatedCheckedState = checkedState.map((item, idx) => {
      return idx === position ? !item : item
    })

    setCheckedState(updatedCheckedState)

    if (e.target.checked === true) {
      handleAccountSelected(acc)
      if (acc?.accountHolders?.length) {
        setIsModalOpen(true)
      }
    } else {
      handleDeleted(acc)
    }

    // set total funds
    const fundsTotal = updatedCheckedState.reduce((sum, currState, idx) => {
      if (currState === true) {
        if (loanTypeId === 2) {
          return sum + accounTypes[idx].availableBalance
        }

        if (loanTypeId === 3) {
          return sum + accounTypes[idx].preQualifiedLoanValue
        }
      }
      return sum
    }, 0)
    setTotalFunds(fundsTotal)
  }

  useEffect(() => {
    if (accounTypes.length !== checkedState.includes(true)) {
      setCheckedState(isSelected)
    } else {
      setCheckedState(isSelected)
    }

    handleCallback({ totalFunds })

    if (checkedState.includes(true)) {
      // set total funds
      const fundsTotal = checkedState.reduce((sum, currState, idx) => {
        if (currState === true) {
          if (loanTypeId === 2) {
            return sum + accounTypes[idx].availableBalance
          }

          if (loanTypeId === 3) {
            return sum + accounTypes[idx].preQualifiedLoanValue
          }
        }
        return sum
      }, 0)
      setTotalFunds(fundsTotal)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accounTypes,
    totalFunds,
    selectedAccounts,
    checkedState.includes(true)
  ])

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  return (
    <>
      {loanTypeId === 2 && (
        <div style={{ overflow: 'auto' }}>
          {/* titles */}
          <Grid
            item
            container
            spacing={2}
            className={classes.TitlesTable}
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              minWidth: '500px',
              gap: '0.5rem'
            }}
          >
            <p>Select Account</p>
            <p>Account Number</p>
            <p>Available Balance</p>
            <p>Owner(s) of the account</p>
          </Grid>

          {/* items */}
          <Grid item spacing={2} style={{ textAlign: 'center' }}>
            {accounTypes &&
              accounTypes.map((account, idx) => {
                return (
                  <label
                    key={idx}
                    className={classes.FlexTableItems}
                    htmlFor={`custom-checkbox-${idx}`}
                    style={{
                      background: `${account.availableBalance <= 0 ? '#C4C5CC' : '#F5F6FF'}`,
                      color: `${account.availableBalance <= 0 ? '#828282' : '#005FAA'}`,
                      display: 'grid',
                      gridTemplateColumns: 'repeat(4, 1fr)',
                      minWidth: '500px',
                      gap: '0.5rem',
                      padding: '1rem 0'
                    }}
                  >
                    <>
                      <input
                      disabled={account.availableBalance <= 0}
                        style={{ maxWidth: '15px', width: '100%' }}
                        type='checkbox'
                        checked={checkedState[idx]}
                        onChange={(e) => handleOnChange(idx, account, e)}
                        value={account.availableBalance}
                        name={account.availableBalance}
                        id={`custom-checkbox-${idx}`}
                      />
                    </>
                    <>
                      <span>{account.accountNumber}</span>
                    </>
                    <>
                      <span>{formatter.format(account.availableBalance)}</span>
                    </>
                    <>
                      <span>{account.accountName}</span>
                    </>
                  </label>
                )
              })}
          </Grid>
        </div>
      )}

      {loanTypeId === 3 && (
        <div style={{ overflow: 'auto' }}>
          {/* titles */}
          <Grid
            item
            container
            spacing={2}
            className={classes.TitlesTable}
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(7, 1fr)',
              minWidth: '650px',
              gap: '0.5rem'
            }}
          >
            <p>Select Loan</p>
            <p>Loan Principal</p>
            <p>Loan Balance</p>
            <p>% Paid</p>
            <p>Loan Type</p>
            <p>
              Pre-Qualified <br /> Loan Value
            </p>
            <p>
              Pre-Qualified <br /> New Funds
            </p>
          </Grid>

          {/* items */}
          <>
            {accounTypes &&
              accounTypes.map((account, idx) => {
                return (
                  <label
                    key={idx}
                    className={
                      account.preQualifiedLoanValue === 0
                        ? classes.FlexTableItems2
                        : classes.FlexTableItems
                    }
                    htmlFor={`custom-checkbox-${idx}`}
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(7, 1fr)',
                      minWidth: '650px',
                      gap: '0.5rem',
                      padding: '1rem 0',
                      width: '94%'
                    }}
                  >
                    <>
                      <input
                        disabled={account.preQualifiedLoanValue === 0}
                        style={{ maxWidth: '15px', width: '100%' }}
                        type='checkbox'
                        checked={checkedState[idx]}
                        onChange={(e) => handleOnChange(idx, account, e)}
                        value={account.maxNewFundsAllowed}
                        name={account.maxNewFundsAllowed}
                        id={`custom-checkbox-${idx}`}
                      />
                    </>
                    <>
                      <span>{formatter.format(account.totalFacility)}</span>
                    </>
                    <>
                      <span>{formatter.format(account.reducingPrincipal)}</span>
                    </>
                    <>
                      <span>{account.percentagePaid}%</span>
                    </>
                    <span>{account.loanType}</span>
                    <span>
                      {formatter.format(account.preQualifiedLoanValue)}
                    </span>
                    <span>{formatter.format(account.preQualifiedNewFund)}</span>
                  </label>
                )
              })}
          </>
        </div>
      )}

      {/* total funds */}
      <Grid item xs={12} sx={{ textAlign: 'center', margin: '1.5rem auto' }}>
        <Typography
          variant='subtitle2'
          gutterBottom
          sx={{ color: '#706F6F !important' }}
        >
          {loanTypeId === 3 ? 'TOTAL PRE-QUALIFIED LOAN VALUE' : 'TOTAL FUNDS'}
          {loanTypeId === 3 ? (
            <LightTooltip2
              open={showTooltip}
              title='This value was calculated based on the loans chosen to refinance. '
            >
              <img
                height={'13px'}
                style={{ marginLeft: '0.5%', marginTop: '0.5%' }}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                onClick={(e) => {
                  e.stopPropagation()
                  toggleTooltip()
                }}
                src={TooltipIcon}
                alt=''
              />
            </LightTooltip2>
          ) : null}
        </Typography>

        <Typography
          variant='h4'
          gutterBottom
          sx={{
            fontWeight: 'bold',
            backgroundColor: '#EAF2FB',
            padding: '10px 0',
            width: '200px',
            margin: '0 auto !important',
            color: '#706F6F !important'
          }}
        >
          {formatter.format(totalFunds)}
        </Typography>
      </Grid>

      <ModalItrem
        type={'big'}
        open={isModalOpen}
        message={
          '​Please note that pledge on joint accounts require that all holders sign the agreement.'
        }
        onConfirm={() => setIsModalOpen(false)}
        icon={ExclamationIcon}
      />
    </>
  )
}

export default SelectAccountComponent
