import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import Dialog from '@mui/material/Dialog'
import Calendar from '../../assets/Images/Grupo 1001.svg'
import { Box } from '@mui/system'
import React from 'react'
import { useStyles } from './ScheduleAppointmentStyle'
import MakeAppointment from './MakeAppointment'

const ScheduleAppointment = ({data, setShowModal, customAction = false }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  const handleClose = () => {
    setShowModal(false)
    setOpen(false)
    if (customAction) {
      console.log('executing custom action')
      customAction()
    }
  }

  return (
    <Dialog className={classes.AlertBoxContainer} fullWidth open={open} onClose={handleClose}>
      <Box className={classes.AlertMainSection}>
        <Stack
          justifyContent="center"
          alignItems="center"
          width="100%"
          spacing={1}
          mb={2}
        >
          <img className={classes.ApprovedImage} src={Calendar} alt="" />
          <Typography variant="subtitle2" className={classes.Text}>
            CHANGE YOUR APPOINTMENT
          </Typography>
        </Stack>
        <MakeAppointment data={data} setShowModal={handleClose} />
      </Box>
    </Dialog>
  )
}

export default ScheduleAppointment
