export const messageTooltip =  {
  unguaranteed: {
    title: 'Unsecured Loan',
    message: "An unsecured loan is a loan in which no assets are pledged as collateral for the loan. "
  },
  guaranteed: {
    title: 'Secured Loan',
    message: "A secured loan is a loan in which you pledge some asset as collateral for the loan. This can be in the form of cash, vehicle, or land"
  },
}

export const Modalmessage =  {
  secureError: {
    type: 'single',
    message: "Oops! It seems you already have an active secured loan application. Please proceed with an unsecured loan or complete your pending application and await a status update to submit a new request."
  },
  unsecureError: {
    type: 'single',
    message: "Oops! It seems you already have an active unsecured loan application. Please proceed with an secured loan or complete your pending application and await a status update to submit a new request.",
    messageEnvironment: "Oops! It seems you already have an active unsecured loan application. Please complete your pending application and await a status update to submit a new request."
  },
  personalLoan: {
    type: 'double',
    message: "Do you wish to cash secure your loan?",
    messageEnvironment: "Please continue with the process"
  },
  refinancingLoanError: {
    type: 'single',
    message: "Oops! It seems you already have an active debt refinancing application. Please complete your pending application and await a status update to submit a new request.",
    message2: 'Oops! It seems you have no active loans that fit the criteria for Debt Refinancing. Please select a different type of loan to move forward.'
  },
  secureUnsecureError:{
    type: 'single',
    message: "Oops! It seems you already have two active loan applications. Please complete your pending application(s) and/or await a status update to submit a new request."
  }
}