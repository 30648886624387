import React from "react"
import { Button, Typography } from "@mui/material"
import Success from "../../assets/Images/Success.png"
import { Box, Stack } from "@mui/system"
import { useStyles } from "./ReferencePopUpStyle.js"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import LocalStorageService from "../../Utils/LocalStoreServices"

const PopupSuccess = () => {
  const classes = useStyles()
  const { referenceLink } = useSelector((state) => state.ReferenceStatusReducer)
  const statusCode = LocalStorageService.getReferenceStatus()

  return (
    <Stack>
      <Box className={classes.SuccessDiscription}>
        <Typography className={classes.SuccessDiscriptionText}>
          <strong style={{ color: "#F07F3C" }}>
            The reference number is correct!{""}
          </strong>
          <br />
          {Number(statusCode) !== 1 &&
            Number(statusCode) !== 2 &&
            "You can continue with the process."}
        </Typography>
      </Box>
      <Box className={classes.PopupExpireTime}>
        <Link to={referenceLink} style={{ textDecoration: "none" }}>
          <Button
            style={{ backgroundColor: "green" }}
            variant="contained"
            className={classes.PopupButton2}
            sx={{ fontSize: "13px" }}
          >
            NEXT
          </Button>
        </Link>
      </Box>
    </Stack>
  )
}

export default PopupSuccess
