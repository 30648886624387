import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
// import Input from '../Inputs/Input'
import { Input } from '../../components/'
import { useStyles } from '../loan-details/LoanDetailsStyle'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { setLoanOptions } from '../../context'

const Index = ({
  optionsList,
  title,
  inputValue
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <>
      <Typography className={classes.InputTitle}>{title}</Typography>
      <Input
        className={classes.BlankInput}
        type='text'
        value={inputValue}
        placeholder='Select an amount'
        readOnly
        disabled={true}
      />

      {optionsList.map((option) => (
        <Stack
          key={option.id}
          direction='row'
          justifyContent='center'
          alignItems='center'
          className={[classes.InputBox, option.active && 'active']}
          onClick={() => {
            dispatch(setLoanOptions({ option: option.key, value: option.value }))
          }}
        >
          <Typography>{option.label}</Typography>
        </Stack>
      ))}
    </>
  )
}

export default Index
