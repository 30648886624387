import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useStyles } from './CongratulationsStyle'
import SmilingEmoji from '../../assets/Images/SmilingEmoji.png'
import Button from '../Button/Button'

import { useNavigate } from 'react-router-dom'

const Congratulations = () => {

  const navigate = useNavigate()
  const classes = useStyles()
  return (
    <Box className={classes.CongratulationsContainer}>
      <Box className={classes.CongratulationsBox}>
        <img
          className={classes.SmilingEmoji}
          src={SmilingEmoji}
          alert=''
          alt=''
        />
        <Typography className={classes.CongratulationsText}>
          Congratulations!
        </Typography>
        <Typography className={classes.ProcessText}>
          <strong>
            You have successfully completed your loan application process.
          </strong>
          <br />
          <br />
          We will contact you soon to update you on the progress of your loan application.
        </Typography>
        <Button
          className={classes.ThankYouBtn}
          Title='THANK YOU'
          onClick={() => {
           navigate(`/?q=${sessionStorage.getItem('code')}`)
          }}
        />
      </Box>
    </Box>
  )
}

export default Congratulations
