import { makeStyles } from "@material-ui/core";
import { Height } from "@mui/icons-material";

export const useStyles = makeStyles((theme) => ({
    card: {
        border: '1px solid #ccc',
        borderRadius: '3px',
        display: 'flex',
      },
      greenLine: {
        borderLeft: '9px solid #56AF31', // Color verde
        paddingLeft: '10px', // Espacio entre la línea y el contenido
      },
      content: {
        padding: '16px',
        width: '100%',
        margin: '28px 0'
      },
}));

