import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../auth/jwt/Jwt'

export const hasAcceptIssuedOffer = createAsyncThunk(
  'appAcceptIssuedOffer/issuedOffer',
  async (data) => {
    const response = await Jwt.acceptIssuedOffer(data)
    return response.data
  }
)

const initialState = {
    wasAccepted: false
}

export const appAcceptIssuedOfferSlice = createSlice({
  name: 'appAcceptIssuedOffer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hasAcceptIssuedOffer.fulfilled, (state, action) => {
      state.wasAccepted = action.payload.wasAccepted
    }).addCase(hasAcceptIssuedOffer.pending, (state, action) => {
      state.pending = false
    })
  }
})


export default appAcceptIssuedOfferSlice.reducer
