import React, { useEffect, useState } from 'react'
import { Interceptor } from '../../../Utils/Interceptor'
import { Box } from '@mui/material'
import ArrowButton from '../../ArrowButton/ArrowButton'
import LoanApproved from './LoanApproved'
import MakeAppointment from './MakeAppointment'
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import Congratulations from '../../Congratulations/Congratulations'
import Actions from '../../../Store/Types'

const ScheduleAppointment = () => {
  const AxiosInterceptor = Interceptor()
  const [status, setStatus] = useState(null)
  const [Files, setFiles] = useState([])
  const dispatch = useDispatch()
  const countPage = useRef(0)
  const navigate = useNavigate()

  return (
    <Box>
      <LoanApproved />
    </Box>
  )
}

export default ScheduleAppointment
