import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  containerStyles: {
    width: '100%'
  },
  grayBgStyles: {
    borderRadius: '2.6875rem',
    background: '#EBEBEB',
    height: '0.5625rem',
    width: '100%'
  },
  labelStyles: {
    color: '#706F6F',
    fontFamily: theme.fonts.family.secondary,
    fontSize: '2rem',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: 'normal'
  },
  Label: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    lineHeight: "17.76px !important",
    letterSpacing: "0.1em !important",
    color: "#706F6F !important",
    // fontFamily: theme.fonts.family.secondary,
},
}))
