import axios from 'axios'
// import { baseUrl } from "../baseUrl";
import { endpoints } from './endpoints'
import { Interceptor } from '../../Utils/Interceptor'
import jwtDecode from 'jwt-decode'

const AxiosInterceptor = Interceptor()
export default class JwtService {
  #jwtConfig = { ...endpoints }

  #isAlreadyFetchingAccessToken = false

  #subscribers = []
  constructor(jwtOverrideConfig = {}) {
    this.#jwtConfig = { ...this.#jwtConfig, ...jwtOverrideConfig }

    axios.interceptors.request.use(
      (config) => {
        const accessToken = this.#getToken()

        if (accessToken) {
          config.headers.Authorization = `${
            this.#jwtConfig.tokenType
          } ${accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 401) {
        if (response && response.status === 401) {
          console.info('Your session has expired! Please log in again.')
          if (!this.#isAlreadyFetchingAccessToken) {
            this.#isAlreadyFetchingAccessToken = true
            this.getRefreshToken().then((r) => {
              this.#isAlreadyFetchingAccessToken = false

              // ** Update accessToken in store
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          return new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${
                this.#jwtConfig.tokenType
              } ${accessToken}`
              resolve(this.axios(originalRequest))
            })
          })
        }
        return Promise.reject(error)
      }
    )
  }

  #getToken() {
    return sessionStorage.getItem(this.#jwtConfig.tokenKeyName)
  }

  #getReferenceNumber() {
    const token = this.#getToken()
    if (token) {
      const decoded = jwtDecode(token)
      return decoded.referenceNumber
    }
    return null
  }

  onAccessTokenFetched(accessToken) {
    this.#subscribers = this.#subscribers.filter((callback) =>
      callback(accessToken)
    )
  }

  addSubscriber(callback) {
    this.#subscribers.push(callback)
  }

  getRefreshToken() {
    return sessionStorage.getItem(this.#jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    sessionStorage.setItem(this.#jwtConfig.tokenKeyName, value)
  }

  setRefreshToken(value) {
    sessionStorage.setItem(this.#jwtConfig.storageRefreshTokenKeyName, value)
  }

  #simulationHTTP(payload) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: { ...payload } })
      }, 2000)
    })
  }

  getTypeOfLoan() {
    return AxiosInterceptor.post(
      `${this.#jwtConfig.customer}/bbank/has-active-loans`,
      {
        token: sessionStorage.getItem('code')
      }
    )
  }

  getCashLoanCollateral() {
    return AxiosInterceptor.get(`/loan-requests`, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('access_token')
      }
    })
    // return this.#simulationHTTP({ amount: 1_000, terms: 16 })
  }

  getLoanRequest() {
    return AxiosInterceptor.get(`/loan-requests`, {})
  }

  getvehicleBrand() {
    return AxiosInterceptor.get(`/vehicle-brands?filter=${JSON.stringify({order:["brandName ASC"]})}`)
  }

  postVehicleDetails(data) {
    return AxiosInterceptor.post(`/vehicle-details`, {
      status: data.status,
      vehicleBrandId: data.vehicleBrandId,
      vehicleModel: data.vehicleModel,
      vehicleValue: data.vehicleValue,
      productionYear: data.productionYear,
      mileage: data.mileage,
      name: data.name,
      address: data.address,
      districtId: data.districtId
    })
  }

  getIssuedOffer() {
    return AxiosInterceptor.get(`/issue-offer-customer`)
  }

  acceptIssuedOffer(data) {
    return AxiosInterceptor.put(`/accept-issue-offer`, {
      fullName: data.fullName,
      accept: data.accept
    })
  }

  declineIssuedOffer(data) {
    return AxiosInterceptor.put(`/decline-issue-offer`, {
      decline: data.decline,
      declinedReasonId: data.declinedReasonId,
      note: data.note
    })
  }

  sendVehiclePictures(data) {
    return AxiosInterceptor.post(`/vehicle-pictures`, {
      frontPictureName: data.frontPictureName,
      frontPicture: data.frontPicture,
      backPictureName: data.backPictureName,
      backPicture: data.backPicture,
      interiorPictureName: data.interiorPictureName,
      interiorPicture: data.interiorPicture,
      mileagePictureName: data.mileagePictureName,
      mileagePicture: data.mileagePicture,
      enginePictureName: data.enginePictureName,
      enginePicture: data.enginePicture,
      sideOnePictureName: data.sideOnePictureName,
      sideOnePicture: data.sideOnePicture,
      sideTwoPictureName: data.sideTwoPictureName,
      sideTwoPicture: data.sideTwoPicture,
      fullViewPictureName: data.fullViewPictureName,
      fullViewPicture: data.fullViewPicture
    })
  }

  updateVehiclePictures(id, data) {
    return AxiosInterceptor.patch(`/vehicle-pictures/${id}`, {
      frontPictureName: data.frontPictureName,
      frontPicture: data.frontPicture,
      backPictureName: data.backPictureName,
      backPicture: data.backPicture,
      interiorPictureName: data.interiorPictureName,
      interiorPicture: data.interiorPicture,
      mileagePictureName: data.mileagePictureName,
      mileagePicture: data.mileagePicture,
      enginePictureName: data.enginePictureName,
      enginePicture: data.enginePicture,
      sideOnePictureName: data.sideOnePictureName,
      sideOnePicture: data.sideOnePicture,
      sideTwoPictureName: data.sideTwoPictureName,
      sideTwoPicture: data.sideTwoPicture,
      fullViewPictureName: data.fullViewPictureName,
      fullViewPicture: data.fullViewPicture
    })
  }

  getVehiclePictures() {
    return AxiosInterceptor.get(`/vehicle-pictures`)
  }

  postVehicleDocs(data) {
    return AxiosInterceptor.post(`/vehicle-documents`, {
      mechanicCertificateFile: data.mechanicCertificateFile,
      mechanicCertificateFileName: data.mechanicCertificateFileName,
      purchaseAgreementFile: data.purchaseAgreementFile,
      purchaseAgreementFileName: data.purchaseAgreementFileName
    })
  }

  getVehicleDocs() {
    const referenceNumber = this.#getReferenceNumber()
    return AxiosInterceptor.get(`/vehicle-documents/${referenceNumber}`)
  }

  getDistrictData() {
    return AxiosInterceptor.get(`/districts`)
  }

  getUrbanAreas(id) {
    return AxiosInterceptor.get(`/districts/${id}/urban-areas`)
  }

  getRegistrationSection(districtId) {
    return AxiosInterceptor.get(`/registration-sections/?filter={"where":{ "or": [{"id_district":${districtId}},{"id": 114}]}}`)
  }

  postSecureLandDocs(data) {
    return AxiosInterceptor.post(`/customers/secure-documents`, {
      districtId: data.districtId,
      urbanAreaId: data.urbanAreaId,
      registrationSectionId: data.registrationSectionId,
      street: data.street,
      parcelOrLotNumber: data.parcelOrLotNumber,
      landAppraisal: data.landAppraisal,
      landTitle: data.landTitle,
      purchaseAgreement: data.purchaseAgreement,
      landTitleName: data.landTitleName,
      landAppraisalName: data.landAppraisalName,
      purchaseAgreementName: data.purchaseAgreementName
    })
  }

  postCreditCard() {
    return AxiosInterceptor.post('/customer/has-credit-card', {
      token: sessionStorage.getItem('code')
    })
  }

  deleteLoanRequest() {
    return AxiosInterceptor.patch('/cancel-loan-request')
  }

  getDeclineReasonsIssuedOffer() {
    return AxiosInterceptor.get('/offer-declined-reason-catalogs')
  }

  getCanRefinance(data) {
    return AxiosInterceptor.post('/can-refinancig-loans', data)
  }

  getLoansToBeRefinanced() {
    return AxiosInterceptor.get('/customer/loans-to-be-refinanced')
  }
  // ! Services non-secured (START)
  getAssets() {
    return axios.get(this.#jwtConfig.assets)
  }

  getAssetsByCustomer() {
    return axios.get(this.#jwtConfig.assetsByCustomer)
  }

  saveAssetByCustomer(data) {
    return axios.post(this.#jwtConfig.assetsByCustomer, data)
  }

  deleteAssetByCustomer(id) {
    return axios.delete(`${this.#jwtConfig.assetsByCustomer}/${id}`)
  }

  updateAssetByCustomer(data) {
    return axios.patch(this.#jwtConfig.assetsByCustomer, data)
  }
  // ! Services non-secured (END)
}
