// export const baseUrl = "https://belizebankapiqa.azurewebsites.net"
// export const baseUrl = "http://[::1]:3002"
// export const baseUrl = 'https://bblolatp.belizebank.com'
export const baseUrl = 'https://bblonlineloans.belizebank.com' // ! SECURE DEV ENV
// export const baseUrl = 'http://192.168.16.36:3000'
// export const baseUrl = 'http://190.110.54.245:8092'
export const Authorization = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjoiVTJGc2RHVmtYMStlVWQ1d3RPYm1PQlE3b1BUcEJPcjVCd1VUbFB0V1NWYkRycUJockVXNzl3Mmpzd3M1Mi9NSko4cU5SZzZBbzJhaitrMjJzdy9oK1VpdGEwTDRiekY0WlpFTnhTMXBRYzF2c3dQaFBOMUdYY3ZGbjJ1QnV2VlNYOWR6b1U0VEl2K0tPd2x6N3dCa292TjBpT3M4dHhNRFA1UUR4Y2U0TlQvTlhSNXBCakdvQUNzYjh0QnVHeExXaU5zZzM1UWcwQlZ5NzNnQ3BSdGFuWHpwZElxNjRRRTJNQmRSQVN3MDg4enRHczU2cFhzZ2FzeFl5ekhVTzJxQzltTkw1V3M4bis2aFozZytuYjhJS2x1MHFlSjFvb2xwbWwxMTBEK2d2YnE5Mk0yTm81VEpzWU5BbUd1SWRKMjJuU2IwVzNCa2xnVFhvQ2NlaHNKZXcwaFNIS09Oc2NJQ3d5L25jUXBpNkFyT1c0djBSeWdQRUNPNjYwTzErVHI3SWZGbE1WZTJJeUNRektpemVzWkVSNEtXR1J1a1FNL0poY1paY3BSWGtzMD0iLCJwYXJ0eUlkIjoiMTM3ODM2IiwiY2hhbm5lbCI6Ik9OTElORSIsImlhdCI6MTY2OTUxODEwOSwiZXhwIjoxNjcwNzI3NzA5fQ.U2OH6u4M8w_uS0vJ-uxntODWXhXibziLyT9gV0OUv4A'
// export const baseUrl = 'https://bblolatp2.belizebank.com' // ! DEV
// export const baseUrl = 'http://192.168.16.36:3004'    // ! DEV_LOCAL


/*
  root /usr/share/nginx/customer1.bblbze.com/html;
*/
