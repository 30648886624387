export const initGlobalState = (dispatch) => {
  dispatch({ type: 'INITIAL_GETSTATUSES' })
  dispatch({ type: 'CLEARSTATE' })
  dispatch({ type: 'CLEARLOANREQUESTSTATE' })
  dispatch({ type: 'CLEAR_STATE_REFERENCE_NUMBER' })
}

export const clearState = (dispatch) => {
  // dispatch({ type: 'INITIAL_GETSTATUSES' })
  dispatch({ type: 'CLEARSTATE' })
  dispatch({ type: 'CLEARLOANREQUESTSTATE' })
  dispatch({ type: 'CLEAR_STATE_REFERENCE_NUMBER' })
}