import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  InputLable: {
    '&.MuiFormLabel-root': {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '0.2em',
      color: '#868686',
      '@media (max-width: 600px)': {
        whiteSpace: 'pre-line',
      },
      '@media (max-width: 400px)': {
        width: '300px'
      },
    },
    fontFamily: theme.fonts.family.secondary,
    fontStyle: 'normal',
  },
  InputLableSpan: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.1em',
  },
  SelectLable: {
    fontFamily: theme.fonts.family.secondary,
    fontWeight: 500,
    color: theme.colors.primary.Grey,
    letterSpacing: '0.1em',
    marginBottom: -21,
  },
  TimeLabel: {
    fontFamily: theme.fonts.family.secondary,
    fontWeight: 500,
    color: theme.colors.primary.Grey,
    letterSpacing: '0.1em',
    marginBottom: -21,
  },
  DateLabel: {
    fontFamily: theme.fonts.family.secondary,
    fontWeight: 500,
    color: theme.colors.primary.Grey,
    letterSpacing: '0.1em',
    marginBottom: -21,
  },
  DateWrapper: {
    width: '100%',
  },
  DatePicker: {
    '& .MuiInputBase-root': {
      marginTop: 1,
      width: '100%',
      height: 41,
      '&.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
        {
          borderColor: '#DADADA',
        },
    },
  },
  TimePicker: {
    '& .MuiInputBase-root': {
      width: '100%',
      height: 45,
    },
  },
  Select: {
    '&.MuiAutocomplete-root': {
      marginTop: 24,
      '& .MuiAutocomplete-input': {
        fontFamily: theme.fonts.family.secondary,
        height: 9,
        fontSize: 13,
        letterSpacing: '0.1em',
        '& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
          {
            borderColor: '#DADADA',
          },
      },
    },
    '& .MuiInputBase-root': {
      '&.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
        {
          borderColor: '#DADADA',
        },
    },
  },
  paper: {
    '&.MuiAutocomplete-paper': {
      backgroundColor: '#616164',
    },
    '& .MuiAutocomplete-listbox': {
      fontFamily: theme.fonts.family.secondary,
      fontWeight: 400,
      fontSize: 13,
      margin: '0px 10px',
      color: 'white',
      '& .MuiAutocomplete-option': {
        '&:hover': {
          backgroundColor: '#679CE5',
          borderRadius: 8,
        },
      },
    },
    '& .MuiAutocomplete-noOptions': {
      color: 'white',
    },
  },
  TimePicker: {
    '& .MuiInputBase-root': {
      height: 42,
    },
  },
  errorMessage: {
    color: 'red',
    margin: '8px 0px 0px 0px',
    fontFamily: theme.fonts.family.tertiary,
    fontSize: 13,
    fontWeight: 400,
  },
  error: {
    color: 'red',
    margin: '8px 0px 0px 0px',
    fontFamily: theme.fonts.family.tertiary,
    fontSize: 13,
    fontWeight: 400,
    position: 'absolute',
    marginTop: 50,
  },
  MainContainer: {
    width: '100%',
    backgroundColor: '#EAF2FB',
    border: '1px solid #D3E4F6',
    height: 38.06,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  TotalLable: {
    '&.MuiInputLabel-root': {
      fontFamily: theme.fonts.family.secondary,
      fontWeight: 800,
      fontSize: 14,
      letterSpacing: '0.1em',
    },
  },
  value: {
    textAlign: 'center',
    '&.MuiTypography-root': {
      fontFamily: theme.fonts.family.secondary,
      fontSize: 14,
      fontWeight: 800,
      letterSpacing: '0.1em',
    },
  },
}))
