import Title from '../../atoms/Title/Title'
import Button from '../../../Button/Button'
import { Grid } from '@mui/material'
import Image from '../../atoms/Image/Image'
//styles
import { useStyles } from './CurrentLoanDetailsStyles'

const CurrentLoanDetails = ({Icon, Key, Value}) => {
  const classes = useStyles()
  return (
    <Grid container xs={12} md={6} justifyContent={'space-between'} alignItems={"center"} style={{marginTop: '24px'}}>
        <Grid item xs={1} md={1}>
            <Image src={Icon} width={'20px'} height={'20px'} />
        </Grid>
        <Grid item xs={5} md={5}>
            <Title Text={Key} color={"#0f5cac"} fontWeight={"700"} fontSize={"14px"} aling={"left"}/>   
        </Grid>
        <Grid item xs={5} md={5}>
            <Title Text={Value} color={"#5E5E5E"} fontWeight={"700"} fontSize={"14px"} aling={"left"}/>   
        </Grid>

    </Grid>
  )
}

export default CurrentLoanDetails
