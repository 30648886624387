import React from 'react'
import { Fragment } from 'react'
import { TypesGuarantees, TypesOfLoans } from './screen'
import { useSelector } from 'react-redux'

const Index = () => {
  const { screen } = useSelector(state => state.initialLoanProcess)

  return (
    <Fragment>
      {screen.types_of_loans && (<TypesOfLoans />)}
      {screen.types_guarantees && (<TypesGuarantees />)}
    </Fragment>
  )
}

export default Index