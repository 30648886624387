import { LoadingButton } from '@mui/lab'
import { Button, Grid, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Interceptor } from '../../Utils/Interceptor'
import {
  QuestionBullet,
  QuestionFace,
  QuestionNumber,
  QuestionOpen,
} from './components'
import { useStyles } from './components/Style'
import { query } from './query'
import SendIcon from '@mui/icons-material/Send'
import { useNavigate } from 'react-router-dom'
import { toast, Toaster } from 'react-hot-toast'

const FeedBack = () => {
  const [data, setData] = useState([])
  const AxiosInterceptor = Interceptor()
  const [sentFeedback, setFeedback] = useState({})
  const [btnSubmit, setBtnSubmit] = useState(true)
  // console.log(sentFeedback)
  const navigate = useNavigate()

  useEffect(() => {
    AxiosInterceptor.get(`/questions?filter=${JSON.stringify(query)}`).then(
      (response) => {
        const result = response.data.sort((a, b) => a.order - b.order)
        setData(result)
      }
    )
  }, [])

  useEffect(() => {
    if (Object.keys(sentFeedback).length) setBtnSubmit(false)
  }, [sentFeedback])

  const [statusSent, setStatusSent] = useState(false)
  const [loading, setLoading] = useState(false)
  //console.log(statusSent)
  const handleSubmit = async () => {
    setLoading(false)
    // setBtnSubmit(true)

    const status = []
    const questions_answer = Object.values(sentFeedback)
    // console.log(questions_answer);
    // return
    for (let i = 0; i < questions_answer.length; i++) {
      //console.log(questions_answer[i])
      if (typeof questions_answer[i].dropdownId === 'object') {
        for (let j = 0; j < Object.values(questions_answer[i].dropdownId).length; j++) {
          const sendMultiple = {...Object.values(questions_answer[i].dropdownId[j])[0]}
          await AxiosInterceptor.post('/survey-answers', sendMultiple).then(() => {
              status.push(true)
              setLoading(true)
            }
          )
        }
      } else {
        await AxiosInterceptor.post('/survey-answers', questions_answer[i]).then(
          () => {
            status.push(true)
            setLoading(true)
          }
        )
      }
      // console.log(typeof questions_answer[i].dropdownId, questions_answer[i].dropdownId)
    }
    status.includes(true) ? setStatusSent(true) : setStatusSent(false)
    status.includes(true) ? setLoading(false) : setLoading(false)
  }

  const handleRedirect = (url) => {
    navigate(String(url))
  }
  useEffect(() => {
    if (statusSent) {
      toast.success('Thank you for submitting your feedback.')
      setTimeout(() => {
        handleRedirect(`/?q=${sessionStorage.getItem('code')}`)
      }, 2000)
    }
  }, [statusSent])

  const classes = useStyles()
  return (
    <Stack className={classes.Contenedor}>
      <Toaster />
      <Box>
        <Typography variant="h6" className={classes.FeedBackTitle}>
          WE WOULD LOVE TO HEAR FROM YOU. YOUR FEEDBACK IS IMPORTANT TO US.
          KINDLY TAKE A FEW SECONDS TO ANSWER THESE SHORT QUESTIONS:
        </Typography>
      </Box>
      <Stack direction="column" spacing={2} marginLeft={2}>
        {data.length > 0 && (
          <>
            {data.map((question) => (
              <div key={question.id}>
                {question?.idQuestionType === 1 && (
                  <QuestionOpen
                    questionInf={question}
                    dataForm={sentFeedback}
                    setData={setFeedback}
                  />
                )}
                {question?.idQuestionType === 2 && (
                  <QuestionBullet
                    questionInf={question}
                    dataForm={sentFeedback}
                    setData={setFeedback}
                  />
                )}
                {question?.idQuestionType === 3 && (
                  <QuestionFace
                    questionInf={question}
                    dataForm={sentFeedback}
                    setData={setFeedback}
                  />
                )}
                {question?.idQuestionType === 4 && (
                  <QuestionNumber
                    questionInf={question}
                    dataForm={sentFeedback}
                    setData={setFeedback}
                  />
                )}
              </div>
            ))}
          </>
        )}
      </Stack>
      <Stack direction={'colum'}>
        <Typography className={classes.QuestionTitle}>
          Thank you for choosing The Belize Bank Ltd. for your banking needs.
        </Typography>
      </Stack>
      <Stack direction={'colum'} marginTop={'3rem'} marginBottom={'3rem'}>
        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" gap={2}>
              <LoadingButton
                className={classes.Btn}
                size="small"
                endIcon={<SendIcon />}
                loading={loading}
                onClick={handleSubmit}
                loadingPosition="end"
                disabled={btnSubmit}
                variant="contained"
                sx={{ fontSize: '13px' }}
              >
                SUBMIT
              </LoadingButton>
              {/* <Button className={classes.Btn} variant="contained" disabled={btnSubmit} onClick={handleSubmit}>
                SUBMIT
              </Button> */}
              <Button
                className={classes.Btn}
                onClick={() => handleRedirect(`/?q=${sessionStorage.getItem('code')}`)}
              >
                SKIP
              </Button>
            </Grid>
          </Grid>
          {statusSent && (
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Typography
                  align="center"
                  style={{
                    marginTop: '2rem',
                    fontFamily: 'Avenir',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '20px',
                    lineHeight: '27px',
                    color: '#005FAA',
                  }}
                >
                  <b>Thank you for submitting your feedback.</b>
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Stack>
    </Stack>
  )
}

export default FeedBack
