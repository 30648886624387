import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../auth/jwt/Jwt'

export const hasDeclinedIssuedOffer = createAsyncThunk(
  'appDeclinedIssuedOffer/issuedOffer',
  async (data) => {
    const response = await Jwt.declineIssuedOffer(data)
    return response.data
  }
)

const initialState = {
    wasDeclined: false
}

export const appDeclinedIssuedOfferSlice = createSlice({
  name: 'appDeclinedIssuedOffer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hasDeclinedIssuedOffer.fulfilled, (state, action) => {
      state.wasDeclined = action.payload.wasDeclined
    }).addCase(hasDeclinedIssuedOffer.pending, (state, action) => {
      state.pending = false
    })
  }
})


export default appDeclinedIssuedOfferSlice.reducer
