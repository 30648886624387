import React, { useState, useEffect } from 'react'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { Modalmessage } from './messageTooltip'
import { useStyles } from '../styles/'
import './LoanTypeSelector.css'
import { useDispatch, useSelector } from 'react-redux'
// import { ButtonTypeLoan } from '../../../components'
import { handleSelectOption } from '../../context'
// import { LOANS } from './types.enum'
import { Container } from '@mui/system'
import { Card } from '@mui/material'
import { Typography } from '@material-ui/core'
import CardContent from '@mui/material/CardContent'
import Button from '../../../../Components/Button/Button'
import LoanList from '../../../../Components/AtomicDesign/organisms/LoanList/LoanList'
import { LoanTypesInfo } from './auxiliaryData/LoanTypes'
import ModalItrem from '../../../../Components/AtomicDesign/molecules/ModalItem/ModalItrem'
import { hasCanRefinance } from '../../../../Store/context/getCanDebtRefinance'

import { useNavigate } from 'react-router-dom'

// Icons
import UnlockIcon from '../../../../assets/Icons/unlock.png'
import ErrorIcon from '../../../../assets/Icons/Error.png'
import { ENVIRONMENT } from '../../../../settings'
import { ScreenLoadingCards } from '../../../components/skeleton'
import { setCode } from '../../../../Store/context/white-list'

const TypesOfLoans = () => {
  const codeLocalStorage = sessionStorage.getItem('code')
  const [loanType, setLoanType] = useState(null)
  // const { GUARANTEED, UNGUARANTEED } = LOANS
  const [openModal, setOpenModal] = useState(false)
  const [activeItem, setactiveItem] = useState(null)
  const [selectedLoanItem, setSelectedLoanItem] = useState({
    title: '',
    warranty: '',
    loanType: '',
    message: '',
    environment: process.env.REACT_APP_ENVIRONMENT || 'TEST'
  })
  const [selectedwarranty, setSelectedwarranty] = useState(null)
  const [selecteLoanType, setSelectedLoanType] = useState(null)
  const [hasActiveSecureAndUnsecureLoan, setHasActiveSecureAndUnsecureLoan] =
    useState(false)
  const { customerLoans, selectOption } = useSelector(
    (state) => state.initialLoanProcess
  )
  const { canRefinancing } = useSelector((state) => state.canRefinance)
  const { user, whiteList } = useSelector(state => state.whiteList)

  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLoanTypeChange = (event, newLoanType) => {
    setLoanType(newLoanType)
  }

  useEffect(() => {
    if (!loanType) return
    const options = { selected: 'loanType', option: loanType }
    dispatch(handleSelectOption(options))
  }, [dispatch, loanType])

  useEffect(() => {
    setLoanType(selectOption.loanType)
  }, [selectOption])

  const [pending, setPending] = useState(true)
  useEffect(() => {
    setPending(true)
    dispatch(hasCanRefinance({ token: sessionStorage.getItem('code') })).finally(() => setPending(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const renderLoanTypeButton = (value, titleButton, tooltipMessage) => (
  //   <ButtonTypeLoan
  //     value={value}
  //     color={loanType === value ? 'primary' : 'secondary'}
  //     className={
  //       loanType === value ? classes.buttonActive : classes.buttonInactive
  //     }
  //     titleButton={titleButton}
  //     tooltipMessage={tooltipMessage}
  //     onClick={() => setLoanType(value)}
  //   />
  // )

  const handleRefinance = () => {
    setactiveItem(false)
    setOpenModal(false)

    if (!canRefinancing) {
      setSelectedLoanItem({
        ...selectedLoanItem,
        title: '',
        loanType: 'refinancing'
      })
      // setactiveItem(true)
      setOpenModal(true)
    } else if (canRefinancing && customerLoans.hasActiveDebtRefinancingLoan){
      setSelectedLoanItem({
        ...selectedLoanItem,
        title: '',
        loanType: 'refinancing'
      })
      // setactiveItem(true)
      setOpenModal(true)
    } else {
      sessionStorage.setItem('loan', 'refinancing')
      sessionStorage.setItem('warrantyType', null)
      sessionStorage.setItem('warrantyTypeID', null)
      navigate(
        `/Customer/PersonalInformationReview?loan=${'secure'}&collateral=${
          selectedLoanItem.warranty
        }`
      )
    }
  }

  const handleConfirm = () => {
    // Lógica a realizar en el caso de "Sí"
    setactiveItem(false)
    setOpenModal(false)

    if (customerLoans.hasActiveSecureLoan) {
      setSelectedLoanItem({
        ...selectedLoanItem,
        title: '',
        loanType: 'secure'
      })
      setOpenModal(true)
    } else {
      sessionStorage.setItem('loan', 'secure')
      sessionStorage.setItem('warrantyType', selectedLoanItem.warranty)
      sessionStorage.setItem('warrantyTypeID', 1)
      navigate(
        `/Customer/PersonalInformationReview?loan=${'secure'}&collateral=${
          selectedLoanItem.warranty
        }`
      )
    }
  }

  const handleCancel = () => {
    // Lógica a realizar en el caso de "No"
    setactiveItem(false)
    setOpenModal(false)

    if (customerLoans.hasActiveUnsecureLoan) {
      setSelectedLoanItem({
        ...selectedLoanItem,
        title: '',
        loanType: 'unsecure'
      })
      setOpenModal(true)
    } else {
      sessionStorage.setItem('loan', 'unsecure')
      navigate(`/Customer/PersonalInformationReview?loan=${'unsecure'}`)
    }
  }

  const handleBack = () => {
    console.log('back')
    setactiveItem(false)
    setOpenModal(false)
  }

  const handleActiveItem = async (index, loanItem, warrantyType, loanType) => {
    // Activar opcion de loan
    setSelectedLoanItem({
      ...selectedLoanItem,
      title: loanItem,
      loanType: loanType,
      warranty: warrantyType
    })
    setSelectedLoanType(loanType)
    setSelectedwarranty(warrantyType)
    //setSelectedLoanItem(loanItem)
    setactiveItem(index)
    if (loanType === 'refinancing') {
      if (
        customerLoans.hasActiveDebtRefinancingLoan
        || !canRefinancing
      ) {
        setOpenModal(true)
      } else {
        sessionStorage.setItem('loan', 'refinancing')
        navigate(`/Customer/PersonalInformationReview?loan=${'refinancing'}`)
      }
    }
    if (loanType === 'secure') {
      if (customerLoans.hasActiveSecureLoan) {
        setOpenModal(true)
      } else {
        if (warrantyType === 'land') {
          sessionStorage.setItem('loan', 'secure')
          sessionStorage.setItem('warrantyType', 'land')
          sessionStorage.setItem('warrantyTypeID', 3)
          navigate(`/Customer/PersonalInformationReview?loan=${'secure'}`)
        } else {
          sessionStorage.setItem('loan', 'secure')
          sessionStorage.setItem('warrantyType', 'vehicle')
          sessionStorage.setItem('warrantyTypeID', 2)
          navigate(`/Customer/PersonalInformationReview?loan=${'secure'}`)
        }
      }
    } else {
      setOpenModal(true)
    }

    if (
      customerLoans.hasActiveSecureLoan &&
      customerLoans.hasActiveUnsecureLoan
      // ["PRODUCTION", "DEVELOPMENT"].includes(process.env.REACT_APP_ENVIRONMENT)
    ) {
      setHasActiveSecureAndUnsecureLoan(true)
      setOpenModal(true)
    }
  }

  useEffect(() => {
    if (!codeLocalStorage) return
    dispatch(setCode(codeLocalStorage))
  }, [codeLocalStorage])

  return (
    <Container>
      <Card variant='outlined' className={classes.Card}>
        <CardContent>
          <Typography className={classes.Header}>
            The Funds You Need, When You Need Them
          </Typography>
          <Typography className={classes.Text}>
            With Belize Bank’s flexible loan repayment terms and competitive
            interest rates, you can cover life’s expenses and build your credit
            so that nothing is standing in the way of your goals.
          </Typography>
        </CardContent>
      </Card>
      {pending && (<ScreenLoadingCards />)}
      { !pending && (
        <>
          <Card variant='outlined' className={classes.Card}>
          <CardContent>
            <Button
              className={classes.SecondButton}
              Title='What type of loan are you looking for?'
              variant='subtitle1'
              BtnVariant='contained'
            />
            <ToggleButtonGroup
              value={loanType}
              exclusive
              onChange={handleLoanTypeChange}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '100px',
                marginTop: '20px'
              }}
            >
              {/*!customerLoans.hasActiveSecureLoan && renderLoanTypeButton(GUARANTEED, 'New Secured Loan', messageTooltip.guaranteed.message)*/}

              {/*!customerLoans.hasActiveUnsecureLoan && renderLoanTypeButton(UNGUARANTEED, 'New Unsecured Loan', messageTooltip.unguaranteed.message)*/}
            </ToggleButtonGroup>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                marginTop: '30px',
                marginBottom: '30px'
              }}
            >
              {LoanTypesInfo.map((loan, index) => (
                <>
                {[ENVIRONMENT.PRE_PRODUCTION].includes(loan.setting.environment) && loan.setting.evaluateInternal && (
                  <LoanList
                    key={index}
                    isActive={activeItem === index}
                    setting={loan.setting}
                    handleActive={() =>
                      handleActiveItem(
                        index,
                        loan.PrincipalTitle,
                        loan.warrantyType,
                        loan.loanType
                      )
                    }
                    LoanList={loan}
                  />
                )}
                {[ENVIRONMENT.PRE_PRODUCTION].includes(loan.setting.environment) && !loan.setting.evaluateInternal && whiteList.includes(user.partyId) && (
                  <LoanList
                    key={index}
                    isActive={activeItem === index}
                    setting={loan.setting}
                    handleActive={() =>
                      handleActiveItem(
                        index,
                        loan.PrincipalTitle,
                        loan.warrantyType,
                        loan.loanType
                      )
                    }
                    LoanList={loan}
                  />
                )}
                {[ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT].includes(loan.setting.environment) && (
                  <LoanList
                    key={index}
                    isActive={activeItem === index}
                    // setting={loan.setting}
                    handleActive={() =>
                      handleActiveItem(
                        index,
                        loan.PrincipalTitle,
                        loan.warrantyType,
                        loan.loanType
                      )
                    }
                    LoanList={loan}
                  />
                )}
                </>
              ))}
            </div>
          </CardContent>

          <div style={{ margin: '2rem auto', textAlign: 'center' }}>
            <Button
              style={{ width: '200px' }}
              Title={'<< Back'}
              variant='subtitle1'
              BtnVariant='contained'
              onClick={() => navigate(-1)}
              color='primary'
            />
          </div>
          </Card>
          <ModalItrem
            open={openModal}
            settings={selectedLoanItem}
            onClose={(isOpen) => {
              setOpenModal(isOpen)
              setactiveItem(false)
            }}
            type={
              selectedLoanItem.title === 'Personal Loan' &&
              !hasActiveSecureAndUnsecureLoan
                ? Modalmessage.personalLoan.type
                : Modalmessage.secureError.type
            }
            icon={
              selectedLoanItem.title === 'Personal Loan' &&
              !hasActiveSecureAndUnsecureLoan
                ? UnlockIcon
                : ErrorIcon
            }
            message={
              selectedLoanItem.title === 'Personal Loan' &&
              !hasActiveSecureAndUnsecureLoan
                ? ([ENVIRONMENT.PRE_PRODUCTION].includes(process.env.REACT_APP_ENVIRONMENT) &&
                    whiteList.includes(user.partyId)) ? Modalmessage.personalLoan.message
                        : [ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT].includes(process.env.REACT_APP_ENVIRONMENT) 
                          ? Modalmessage.personalLoan.message
                          : Modalmessage.personalLoan.messageEnvironment
                : selectedLoanItem.loanType === 'secure'
                ? Modalmessage.secureError.message
                : selectedLoanItem.loanType === 'refinancing' && canRefinancing
                ? Modalmessage.refinancingLoanError.message
                : selectedLoanItem.loanType === 'refinancing' && !canRefinancing
                ? Modalmessage.refinancingLoanError.message2
                : selectedLoanItem.title === 'Personal Loan' &&
                  hasActiveSecureAndUnsecureLoan
                ? Modalmessage.secureUnsecureError.message
                : ([ENVIRONMENT.PRE_PRODUCTION].includes(process.env.REACT_APP_ENVIRONMENT) &&
                    whiteList.includes(user.partyId)) ? Modalmessage.unsecureError.message
                    : [ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT].includes(process.env.REACT_APP_ENVIRONMENT)
                      ? Modalmessage.unsecureError.message
                      : Modalmessage.unsecureError.messageEnvironment
            }
            onConfirm={
              selectedLoanItem.title === 'Personal Loan' &&
              !hasActiveSecureAndUnsecureLoan
                ? handleConfirm
                : selectedLoanItem.title === 'Personal Loan' &&
                  hasActiveSecureAndUnsecureLoan
                ? handleBack
                : selectedLoanItem.title === 'Debt Refinancing' && canRefinancing
                ? handleRefinance
                : handleBack
            }
            onCancel={handleCancel}
          />
        </>
      ) }
    </Container>
  )
}

export default TypesOfLoans
