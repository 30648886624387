import YourLoanRequest from "../Components/Forms/YourLoanRequest/YourLoanRequest"
import TermsAndConditions from "../Components/TermsAndConditions/TermsAndConditions"
import MonthlyIncome from "../Components/Forms/MonthlyIncome/MonthlyIncome"
import MonthlyCreditObligations from "../Components/Forms/MonthlyCreditObligations/MonthlyCreditObligations"
import LoanPurpose from "../Components/Forms/LoanPurpose/LoanPurpose"
import ProofOfIncome from "../Components/Forms/ProofOfIncome/ProofOfIncome"
// import OtpPopUp from "../Components/OtpPopup/OtpPopUp"
import UploadDocuments from "../Components/Forms/UploadDocuments/UploadDocuments"
import ScheduleAppointment from "../Components/Forms/ScheduleAppointment/ScheduleAppointment"
import MakeAppointment from "../Components/Forms/SignDocuments/MakeAppointment"
import ScheduleApproved from "../Components/Forms/SignDocuments/ScheduleApproved"
import ScheduleThankYou from "../Components/Forms/SignDocuments/SheduleThankYou"
import SignDocuments from "../Components/Forms/SignDocuments/SignDocuments"
import InitialLoanProcess from "../Pages/initial-loan-process/index"
import PersonalInformationReview from "../Components/Forms/PersonalInformationReviewForm/PersonalInformationReview"
import LoanCollateral from '../Pages/initial-loan-process/screen/loan-collateral/index'
import MonthlyInstallments from '../Components/Forms/MonthlyInstallments/MonthlyInstallments'
import VehicleStatus from "../Pages/VehicleStatus"
import LoanCalculation from '../Pages/initial-loan-process/screen/loan-calculation/index'
import LoansToBeRefinanced from "../Pages/LoansToBeRefinanced/LoansToBeRefinanced"
import RefinanceRequest from "../Pages/RefinanceRequest/RefinanceRequest"
import LandDetails from "../Pages/LandDetails/LandDetails"
import ApprovedBelow7k from '../Components/Forms/SignDocuments/ApprovedBelow7k'
import AssetsDeclaration from "../Components/Forms/AssetsDeclaration/"


const PAGES = [
  {
    localeKey: "Your loan request",
    key: "/yourLoanRequest",
    Component: YourLoanRequest,
    Protected: true,
    yourLoanRequestpages: [
      {
        localeKey: "Terms and conditions",
        key: "/TermsAndConditions",
        Component: TermsAndConditions,
        Protected: true,
      },
    ],
  },
  {
    localeKey: "Monthly income",
    key: "/monthlyIncome",
    Component: MonthlyIncome,
    Protected: true,
  },
  {
    localeKey: "Monthly credit obligations",
    key: "/MonthlyCreditObligations",
    Component: MonthlyCreditObligations,
    Protected: true,
  },
  {
    localeKey: "Loan Purpose",
    key: "/LoanPurpose",
    Component: LoanPurpose,
    Protected: true,
  },
  {
    localeKey: "Proof Of Income",
    key: "/ProofOfIncome",
    Component: ProofOfIncome,
    Protected: true,
  },
  {
    localeKey: "Assets Declaration",
    key: "/AssetsDeclaration",
    Component: AssetsDeclaration,
    Protected: true,
  },
  {
    localeKey: "Schedule Appointment",
    key: "/ScheduleAppointment",
    Component: ScheduleAppointment,
    Protected: true,
  },
  {
    localeKey: "Make Appointment Form",
    key: "ScheduleAppointment/MakeAppointment", // ! cuando el cliente esta aprobado mayor a 7k y pendiente de cita
    Component: ScheduleAppointment,
    Protected: true,
  },
  {
    localeKey: "Schedule Appointment Approved",
    key: "ScheduleAppointment/ScheduleApproved",
    Component: ScheduleApproved,
    Protected: true,
  },
  {
    localeKey: "Schedule Appointment ThankYou",
    key: "ScheduleAppointment/ScheduleThankYou",
    Component: ScheduleThankYou,
    Protected: true,
  },
  // {
  //   localeKey: "Loan Status",
  //   key: "/LoanStatus",
  //   Component: LoanStatus,
  //   Protected: true,
  // },
  {
    localeKey: "Upload Documents",
    key: "/UplaodDocuments",
    Component: UploadDocuments,
    Protected: true,
  },
  {
    localeKey: "Sign Documents",
    key: "/SignDocuments",
    Component: SignDocuments,
    Protected: true,
  },
  {
    localeKey: "Initial Loan Process",
    key: "/initial-loan-process",
    Component: InitialLoanProcess,
    Protected: true,
  },
  {
    localeKey: "Personal Information Review",
    key: "/PersonalInformationReview",
    Component: PersonalInformationReview,
    Protected: true,
  },
  {
    localeKey: "Loan Collateral",
    key: "/loan-collateral",
    Component: LoanCollateral,
    Protected: true,
  },
  {
    localeKey: "Monthly Installments",
    key: "/MonthlyInstallments",
    Component: MonthlyInstallments,
    Protected: true,
  },
  {
    localeKey: "Monthly Installments",
    key: "/MonthlyInstallments/TermsAndConditions/:loanRequestId",
    Component: TermsAndConditions,
    Protected: true,
  },
  {
    localeKey: "Loan Calculation",
    key: "/loan-calculation",
    Component: LoanCalculation,
    Protected: true,
  },
  {
    localeKey: "Vehicle Status",
    key: "vehicle-status",
    Component: VehicleStatus,
    Protected: true,
  },
  {
    localeKey: "Loans To Be Refinanced",
    key: "loans-to-be-refinanced",
    Component: LoansToBeRefinanced,
    Protected: true,
  },
  {
    localeKey: "Refinance Request",
    key: "refinance-request",
    Component: RefinanceRequest,
    Protected: true,
  },
  {
    localeKey: "Land Details",
    key: "land-details",
    Component: LandDetails,
    Protected: true,
  },
  {
    localeKey: "Approved",
    key: "/approved-below-7k",
    Component: ApprovedBelow7k,
    Protected: true,
  },
]


export default PAGES