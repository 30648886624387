import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../auth/jwt/Jwt'

export const hasVehiclePictures = createAsyncThunk(
  'appVehiclePictures/vehiclePictures',
  async (data) => {
    const response = await Jwt.sendVehiclePictures(data)
    return response.data
  }
)

const initialState = {
    frontPictureName: '',
    frontPicture: null,
    backPictureName: '',
    backPicture: null,
    interiorPictureName: '',
    interiorPicture: null,
    mileagePictureName: '',
    mileagePicture: null,
    enginePictureName: '',
    enginePicture: null,
    sideOnePictureName: '',
    sideOnePicture: null,
    sideTwoPictureName: '',
    sideTwoPicture: null,
    fullViewPictureName: '',
    fullViewPicture: null,
    pending: true
}

export const appvehiclePictures = createSlice({
  name: 'appvehiclePictures',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hasVehiclePictures.fulfilled, (state, action) => {
        state.frontPictureName = action.payload.frontPictureName
        state.frontPicture = action.payload.frontPicture
        state.backPictureName = action.payload.backPictureName
        state.backPicture = action.payload.backPicture
        state.interiorPictureName = action.payload.interiorPictureName
        state.interiorPicture = action.payload.interiorPicture
        state.mileagePictureName = action.payload.mileagePictureName
        state.mileagePicture = action.payload.mileagePicture
        state.enginePictureName = action.payload.enginePictureName
        state.enginePicture = action.payload.enginePicture
        state.sideOnePictureName = action.payload.sideOnePictureName
        state.sideOnePicture = action.payload.sideOnePicture
        state.sideTwoPictureName = action.payload.sideTwoPictureName
        state.sideTwoPicture = action.payload.sideTwoPicture
        state.fullViewPictureName = action.payload.fullViewPictureName
        state.fullViewPicture = action.payload.fullViewPicture
    }).addCase(hasVehiclePictures.pending, (state, action) => {
      state.pending = false
    })
  }
})


export default appvehiclePictures.reducer
