const LocalStorageService = (function () {
  let _service
  function _getService() {
    if (!_service) {
      _service = this
      return _service
    }
    return _service
  }

  function _setToken(token) {
    sessionStorage.setItem("access_token", token)
    localStorage.setItem("access_token", token)
  }
  function _setChannel(channel) {
    sessionStorage.setItem('channel', channel)
  }
  function _getChannel() {
    return sessionStorage.getItem('chanel')
  }
  function _setExpirationDate(date) {
    sessionStorage.setItem("expirationDate", date)
  }
  function _setBBLToken(token){
    sessionStorage.setItem("access_token_bbl", token)
  }
  function _getExpirationDate() {
    return sessionStorage.getItem("expirationDate")
  }
  function _setReferenceStatus(status) {
    return sessionStorage.setItem("referenceStatus", status)
  }
  function _getReferenceStatus() {
    return sessionStorage.getItem("referenceStatus")
  }
  function _getAccessToken() {
    return sessionStorage.getItem("access_token")
  }
  function _getAccessTokenBBL(){
    return sessionStorage.getItem("access_token_bbl")
  }
  function _clearToken() {
    sessionStorage.removeItem("access_token")
    sessionStorage.removeItem("chanel")
    sessionStorage.removeItem("user_type")
    sessionStorage.removeItem("expirationDate")
    sessionStorage.removeItem("referenceStatus")
    sessionStorage.removeItem("customer_schedule")
    sessionStorage.removeItem("customer_data")
    sessionStorage.removeItem("access_token_bbl")
  }

  return {
    getService: _getService,
    setToken: _setToken,
    setChannel: _setChannel,
    getChannel: _getChannel,
    getExpirationDate: _getExpirationDate,
    setExpirationDate: _setExpirationDate,
    setReferenceStatus: _setReferenceStatus,
    getReferenceStatus: _getReferenceStatus,
    getAccessToken: _getAccessToken,
    clearToken: _clearToken,
    getAccessTokenBBL : _getAccessTokenBBL,
    setBBLToken : _setBBLToken
  }
})()

export default LocalStorageService
