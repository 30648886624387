import React, { Fragment, useRef } from 'react'

import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import Button from '../../Button/Button'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ClearIcon from '@mui/icons-material/Clear'
import { Field } from 'formik'
import { useStyles } from './ScheduleAppointmentStyle'
import { Interceptor } from '../../../Utils/Interceptor'
import { useState } from 'react'

const CustomFileInput = ({ id, bankName, formik, data, setSchema, schema }) => {
  const classes = useStyles()
  const AxiosInterceptor = Interceptor()

  const [errorMessage, setError] = useState('This is required')

  const convertBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
          resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
          reject(error)
        }
      })
    } else {
      return new Promise((resolve, reject) => {
        resolve('')
      })
    }
  }

  const customFile = useRef([])

  const onButtonClick = (index, e) => {
    customFile.current[index].click()
    // console.log(e?.currentTarget?.files)
  }

  const onSubmit = async (file = '', fileName = '') => {
    if (!file && !fileName) {
      // Si no se proporciona ningún archivo ni nombre de archivo, se limpia el campo
      formik.setFieldValue(`${bankName}-${id}`, null);
      // return;
    }
  
    AxiosInterceptor.patch(`/monthly-credit-obligations/${id}`, {
      ...data,
      // id,
      // customerId,
      statementFileName: fileName,
      statementFile: file,
    })
  }

  const handleSubmitFIle = async (e) => {
    const file = e?.currentTarget?.files[0]

    if (file) {
      const fileSizeMB = file.size / (1024 * 1024) // Convertir tamaño a MB
      if (fileSizeMB > 2) {
        setError('File size exceeds the limit of 2 MB')
        return
      } else {
        setError('')
      }
    }

    const fileName = file.name
    await convertBase64(file).then((base64) => {
      onSubmit(base64, fileName)
    })
  }

  return (
    <Fragment>
      <Stack mb={3}>
        <Field name={`${bankName}-${id}`}>
          {({ field, form, meta }) => (
            <>
              <label className={classes.UploadLabel}>
                {`BZD- ${data?.amount} ${bankName}`}*
              </label>
              <Stack
                className={classes.Mt16}
                direction="row"
                width={{ xs: '100%', sm: '100%' }}
                alignItems="center"
              >
                <Button
                  Title={`UPLOAD FILES`}
                  variant="h7"
                  className={classes.UploadFiles}
                  endIcon={<AttachFileIcon />}
                  onClick={(e) => {
                    onButtonClick(id, e)
                    formik.setFieldTouched(field.name)
                  }}
                  style={{ width: '100%' }}
                />

                <input
                  {...field}
                  required
                  type="file"
                  accept="application/pdf,image/*"
                  onChange={(event) => {
                    handleSubmitFIle(event)
                    if (event.currentTarget.files) {
                      console.log(field?.name)
                      form.setFieldValue(
                        field?.name,
                        event.currentTarget.files[0]
                      )
                    }
                    //formik.setFieldTouched(field.name);
                  }}
                  value=""
                  style={{ display: 'none', width: '90%' }}
                  ref={(el) => (customFile.current[id] = el)}
                />
              </Stack>
              <Typography className={classes.UploadFileText}>
                maximum of 2MB
              </Typography>
                <p
                  className={classes.UploadFileText}
                  style={{ color: 'red', marginTop: 5 }}
                >
                  {errorMessage}
                </p>
                {meta?.value?.name && !errorMessage.length && (
                  <>
                    {meta.value.type.includes('image/') ||
                    meta.value.type === 'application/pdf' ? (
                      <p className={classes.uploadFileName}>
                        {`${String(meta.value?.name).substring(0, 25)} ...`}
                        <span>
                          <ClearIcon
                            className={classes.deleteFileIcon}
                            onClick={() => onSubmit(null, null)}
                          />
                        </span>
                      </p>
                    ) : (
                      <p
                        className={classes.UploadFileText}
                        style={{ color: 'red', marginTop: 5 }}
                      >
                        Invalid file format. Please upload an image or PDF file.
                      </p>
                    )}
                  </>
                )
              }
            </>
          )}
        </Field>
      </Stack>
    </Fragment>
  )
}

export default CustomFileInput
