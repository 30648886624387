import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  AlertCashCollateral: {
    textAlign: 'center',
    marginBottom: '5%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    background: theme.colors.primary.lightPink,
    borderRadius: '0.3125rem',
    fontFamily: theme.fonts.family.primary,
    color: theme.colors.primary.Darkblue,
    padding: '1.5rem'
  },
  AlertSpanText:{
    maxWidth: '29rem',
    width: '100%'
  },
  AlertTotalFunds: {
    width: '100%',
    textAlign: 'center',
    padding: '1rem',
    backgroundColor: theme.colors.primary.purple,
    borderRadius: '1rem',
    margin: '2rem 0 !important',
    fontFamily: theme.fonts.family.primary,
    color: theme.colors.primary.Grey
  },
  TitlesTable:{
    color: theme.colors.primary.Darkblue,
    fontWeight: 'bold',
    fontFamily: theme.fonts.family.primary,
    textAlign: 'center',
    marginBottom: '1.5rem',
    width: '94% !important',
    margin: '0 auto !important'
  },
  FlexTableItems : {
    display: "flex",
    justifyContent: "space-between",
    width: "96%",
    textAlign: 'center',
    background: theme.colors.primary.lightPurple,
    padding: '1rem',
    margin: '.5rem auto',
    borderRadius: '0.3125rem',
    fontFamily: theme.fonts.family.primary,
    color: theme.colors.primary.Darkblue,
  },
  Link: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    color: "rgba(39, 93, 166, 0.87) !important",
    // fontFamily: theme.fonts.family.secondary,
  },
  Label: {
      fontSize: "13px !important",
      fontWeight: "500 !important",
      lineHeight: "17.76px !important",
      letterSpacing: "0.1em !important",
      color: "#706F6F !important",
      // fontFamily: theme.fonts.family.secondary,
  },
  FullButton: {
    width: '100%',
    backgroundColor: "#275da6 !important",
    height: "50px"
  },
  LoanContainer: {
    width: "850.4px",
    margin: "0 auto 70px auto",
    "@media (max-width: 1000px)": {
      width: "100%",
    },
  },
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  Button: {
    width: 176,
    height: 39,
    "&.MuiButtonBase-root": {
      marginBottom: 30,
      backgroundColor: theme.colors.primary.Darkblue,
      color: 'white',
      textDecoration: 'none',
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "& .MuiTypography-root": {
        fontSize: 13,
        // fontFamily: theme.fonts.family.primary,
        fontWeight: 600,
        letterSpacing: "0.2em",
      },
      "&.Mui-disabled": {
        backgroundColor: '#9E9E9E'
      }
    },
    ".makeStyles-Button-18.MuiButtonBase-root": {
      backgroundColor: '#9E9E9E'
    }
  },
}));
