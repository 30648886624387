import * as React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { Card } from '@material-ui/core'
import { Container } from '@mui/material'
import { useStyles } from './HomeStyle'

const ScreenLoading = () => {
  const classes = useStyles()
  return (
    <Container>
      <Card variant="outlined" className={classes.Card}>
        <Box sx={{ width: '100%' }}>
          <Skeleton />
          Loading ...
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
          <Skeleton variant="rectangular" width={'40%'} height={458} />
        </Box>
      </Card>
    </Container>
  )
}

export default ScreenLoading
