import React from "react"
import Box from "@mui/material/Box"
import Logo from "../../assets/HomePageImages/Logo.png"
import { useStyles } from "./HomeHeaderStyle"
import MenuIcon from "@mui/icons-material/Menu"
import { Link } from "react-router-dom"

const HomeHeader = () => {
  const classes = useStyles()
  return (
    // classes.MainContainer
    <Box className={classes.MainContainer}>

    </Box>
  )
}

export default HomeHeader
