import React, { useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import VerificationInput from '../Inputs/VerificationInput'
import Image from '../../assets/Images/Group31.png'
import clock from '../../assets/Images/clock.png'
import { useStyles } from './OtpPopUpStyle.js'
import Button from '@mui/material/Button'
// import { otpValidation } from '../../Store/Actions/OtpValidation'
import { useDispatch, useSelector } from 'react-redux'
import PopupAlert from './PopupAlert'
// import PopupSuccess from './PopupSuccess'
// import ArrowButton from '../ArrowButton/ArrowButton'
import Dialog from '@mui/material/Dialog'
import { useEffect } from 'react'
import { Interceptor } from '../../Utils/Interceptor'
import Actions from '../../Store/Types'
import LocalStoreServices from '../../Utils/LocalStoreServices'
import toast, { Toaster } from 'react-hot-toast'
import AlertInfo from '../Alert'

export const ConfirmOTP = ({
  newPhoneNumber,
  data,
  handleContinue,
  isVerified,
  setShowOTP,
  refreshData
}) => {
  const localStorageServices = LocalStoreServices.getService()

  // const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [pin, setPin] = useState('')
  const AxiosInterceptor = Interceptor()
  const [open, setOpen] = useState(true)
  const [pending, setPending] = useState(false)
  const { loanSelected } = useSelector(state => state.initialLoanProcess)
  const [error, setError] = useState({
    statusCode: '',
    name: '',
    message: '',
  })

  useEffect(() => {
    setPending(true)
    const dataSend = {
      email: data.email,
      phone: newPhoneNumber,
      hasEmailAddress: true,
      hasSsn: data.hasSsb,
      proofOfSsn: data.ssnFile,
      code: sessionStorage.getItem('code'),
      isPhoneVerify: true,
      loanType: loanSelected.loanType,
      collateral: loanSelected.collateral,
      loanTypeId: sessionStorage.getItem('loanType')
    }
    console.log('dataSend: ', dataSend)
    AxiosInterceptor.post('/customer/bbank/update', { ...dataSend })
      .then((response) => {
        // console.log('Aquiii: ', response)
        // if (response.error) alert(response.message)
      })
      .catch((e) => {
        console.log('EN EL CATCH', e)
        setError(e?.response?.data?.error)
        setTimeout(() => {
          setShowOTP(false)
        }, 2500);
      })
      .finally(() => setPending(false))
  }, [])

  const showAlert = () => {
    toast.success('Successfully verified')
    refreshData()
    isVerified(true)
    handleContinue(true)
    setTimeout(() => {
      setOpen(false)
    }, 2000)
    return <Toaster />
  }

  const getOtp = async () => {
    const endpoint = `/customers/confirmation/phone`
    const sentData = {
      phone: newPhoneNumber,
      code: pin,
      token: sessionStorage.getItem('code'),
    }

    dispatch({ type: Actions.LOADING, payload: true })
    AxiosInterceptor.post(endpoint, { ...sentData })
      .then((response) => {
        dispatch({ type: Actions.OTPVALIDATION, payload: response.data })
        dispatch({ type: Actions.OTPSUCCESS })
        dispatch({ type: Actions.REFERENCESTATUS, payload: true })
        localStorageServices.setReferenceStatus(true)
        if (Number(response.data.status) === 0) showAlert()
      })
      .catch((e) => {
        if (
          e.response.data.error.message === 'Invalid code, please try again.' ||
          e.response.data.error.message === 'Your code has expired.' ||
          e.response.data.error.message === 'Resend an activation code.'
        ) {
          isVerified(false)
          dispatch({ type: Actions.OTPERROR, payload: true })
        }
      })
      .finally(() => {
        dispatch({ type: Actions.LOADING, payload: false })
      })
  }
  return (
    <Dialog
      className={classes.AlertBoxContainer}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="draggable-dialog-title"
    >
      {!pending && !(error.message.length > 0) && (
        <Box>
          <PopupAlert newPhone={newPhoneNumber} previousNumber={data.phone} />
          <Box className={classes.PopupContainer}>
            <Box className={classes.PopupHeader}></Box>

            <Box className={classes.PopupMainSection}>
              <Box className={classes.PopupHeaderLogo}>
                <img className={classes.Logo} src={Image} alt="Logo" />
              </Box>
              <Typography className={classes.PopupText}>
                To communicate with you effectively, we will need to validate
                your mobile number. An SMS has been sent to the number provided
                for this application. Please enter the activation code here:
              </Typography>
              
              <VerificationInput
                length={5}
                onChange={(e) => setPin(e)}
                sx={{
                  borderRadius: '8px',
                  fontSize: '16px',
                }}
              />
              <Box className={classes.PopupExpireTime}>
                <img className={classes.ClockImage} src={clock} alt="clock" />
                <Typography className={classes.ExpireTimeText}>
                  The code will expire in 15 minutes
                </Typography>
              </Box>
              <Box className={classes.PopupBtn}>
                <Button
                  className={classes.PopupButton}
                  onClick={getOtp}
                  sx={{ fontSize: '13px' }}
                >
                  VALIDATE CODE
                </Button>
              </Box>

              <Box style={{margin: '0'}} className={classes.PopupExpireTime} onClick={() => setOpen(false)}>
          <Typography className={classes.ExpireTimeText} style={{textDecoration: 'underline', fontWeight: '700', cursor: 'pointer'}}>
            Entered the wrong information? Try again.
          </Typography>
        </Box>
            </Box>
          </Box>
        </Box>
      )}
      {error.message.length > 0 && (
              <AlertInfo content={error.message} style={{marginBottom: 0}}/>
        // <Box>
        //   <Box className={classes.PopupContainer}>
        //     <Box className={classes.PopupMainSection}>
        //     </Box>
        //   </Box>
        // </Box>
      )}
    </Dialog>
  )
}

const ForgetPassword = ({
  phoneNumber: lockPhoneNumber,
  onSuccessfulLogin,
  goBackUrl,
}) => {
  const theme = useTheme()
  const [sentCode, setSentCode] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')

  const changePassword = async (pin) => {}

  const sendCode = async (resend, password) => {}

  return (
    <>
      <ConfirmOTP
        sendCode={sendCode}
        onSuccessfulLogin={onSuccessfulLogin}
        cancel={() => setSentCode(false)}
        phoneNumber={phoneNumber}
        submit={changePassword}
      />
    </>
  )
}

export default ForgetPassword
