import { createSlice } from '@reduxjs/toolkit'

export const appSteep = createSlice({
  name: 'appAcceptIssuedOffer',
  initialState: {
    steepLinks: {}
  },
  reducers: {
    setSteepLinks: (state, action) => {
      state.steepLinks = action.payload
    }
  }
})

export const { setSteepLinks } = appSteep.actions

export default appSteep.reducer
