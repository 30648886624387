import { Alert, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import LoadingButton from '@mui/lab/LoadingButton'
import SendIcon from '@mui/icons-material/Send'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Cross from '../../../assets/Images/Cross.png' //../../assets/Images/Cross.png
import Image from '../../../assets/Images/MessageLogo.png'
import Shield from '../../../assets/Images/shield.png'
import clock from '../../../assets/Images/clock.png'
import { Box } from '@mui/system'
import React, { useEffect, useRef } from 'react'
import { useStyles } from './PoppupStyle.js'
import VerificationInput from '../../Inputs/VerificationInput'
import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'
import { Interceptor } from '../../../Utils/Interceptor'
import Actions from '../../../Store/Types'
import { useNavigate } from 'react-router-dom'
import LocalStorageServices from '../../../Utils/LocalStoreServices.js'

const PoppUpEmailConfirmation = ({ setconfirmEmail, newEmail, phone, isVerifiedEmail }) => {
  const AxiosInterceptor = Interceptor()
  const countPage = useRef(0)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showLoading, setShowLoading] = useState(false)
  const classes = useStyles()
  const [pin, setPin] = useState('')
  const [codeStatus, setCodeStatus] = useState()
  const [countOtp, setCountOtp] = useState(1)
  const token = sessionStorage.getItem('code') || ''
  // const [emailVerified, setEmailVerified] = useState(false)

  const Error = useSelector((state) => state.OtpEmailReducer)

  const localStorageServices = LocalStorageServices.getService()

  const sentData = {
    email: newEmail,
    hasEmailAddress: true,
    phone: phone
  }

  // useEffect(() => {
  //   isVerifiedEmail(emailVerified)
  // }, [emailVerified, isVerifiedEmail])

  const getOtp = async () => {
    setShowLoading(true)
    const url = '/customers/confirmation/email'
    await AxiosInterceptor.post(url, { code: pin, token, email: newEmail })
      .then(({ data }) => {
        setCodeStatus(data.status)
        dispatch({
          type: Actions.EMAILRESPONSE,
          payload: data.status
        })
        if (data.status === 0) {
          dispatch({
            type: Actions.LOADING,
            payload: false
          })
          setconfirmEmail(true)
          AxiosInterceptor.post('/customer/bbank/update', { ...sentData }).then(
            (response) => {
              const token = response.data.token
              const expirationDate = response.data.expirationDate
              localStorageServices.setToken(token)
              localStorageServices.setExpirationDate(expirationDate)
            }
          )
          if (sessionStorage.getItem('loan') === 'unsecure') {
            setconfirmEmail(true)
            isVerifiedEmail(true)
          } else {
            setconfirmEmail(true)
            isVerifiedEmail(true)
          }
        }
        if (data.status >= 1 && data.status <= 3) {
          setCountOtp(countOtp + 1)
          if (countOtp >= 3) {
            dispatch({
              type: Actions.EMAILSHOWMODALRETRY,
              payload: {
                showModal: true,
                statusCode: 4
              }
            })

            isVerifiedEmail(true)

          } else {
            dispatch({
              type: Actions.EMAILSHOWMODALERROR,
              payload: true
            })
          }
          setShowLoading(false)
        }
      })
      .catch((e) => {})
  }

  return (
    <Dialog
      className={classes.AlertBoxContainer}
      open={true}
      aria-labelledby='draggable-dialog-title'
    >
      <Box className={classes.PopupHeader}></Box>
      <Box className={classes.PopupMainSection}>
        <Box className={classes.PopupHeaderLogo}>
          <img className={classes.Logo} src={Shield} alt='Logo' />
        </Box>
        <Typography className={classes.PopupText}>
          We need to validate your email address as it will be required on the
          other steps of your application. Email confirmation was sent to the
          email address provided at the beginning of your online loan
          application.
        </Typography>
        <Box className={classes.PopupExpireTime}>
          <img className={classes.ClockImage} src={clock} alt='clock' />
          <Typography className={classes.ExpireTimeText}>
            The code will expire in 15 minutes
          </Typography>
        </Box>
        <Box className={classes.PopupExpireTime}>
          <Typography className={classes.ExpireTimeText}>
            Enter the confirmation code now.
          </Typography>
        </Box>
        <VerificationInput
          length={5}
          onChange={(e) => setPin(e)}
          sx={{
            borderRadius: '8px',
            fontSize: '16px',
            marginBottom: '2.5rem'
          }}
        />

        <Box className={classes.PopupBtn}>
          <LoadingButton
            className={classes.PopupButton}
            size='small'
            style={{ color: 'white' }}
            onClick={getOtp}
            endIcon={<SendIcon />}
            loading={showLoading}
            loadingPosition='end'
            // variant="contained"
            sx={{ fontSize: '13px' }}
          >
            VALIDATE CODE
          </LoadingButton>
        </Box>
        <Box
          className={classes.PopupExpireTime}
          onClick={() => setconfirmEmail(true)}
        >
          <Typography
            className={classes.ExpireTimeText}
            style={{
              textDecoration: 'underline',
              fontWeight: '700',
              cursor: 'pointer'
            }}
          >
            Entered the wrong information? Try again.
          </Typography>
        </Box>
      </Box>
      {/* <Box className={classes.AlertMainSection}>
        <Box className={classes.HeaderLogoContainer}>
          <Box className={classes.HeaderLogoImage}>
            <img className={classes.CrossImage} src={Cross} alt="Cross" />
          </Box>
        </Box>
        <Typography className={classes.AlertText}>
          Invalid One Time Password
        </Typography>
       
      </Box> */}
    </Dialog>
  )
}

export default PoppUpEmailConfirmation
