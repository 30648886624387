import { Interceptor } from "../../Utils/Interceptor"
import Actions from "../Types"

export const GetLoandRequest = () => {
  const AxiosInterceptor = Interceptor()
  return async (dispatch, getState) => {
    const url = "/loan-requests"
    await AxiosInterceptor.get(url, {
      params: {
        token: encodeURIComponent(sessionStorage.getItem('code'))
      }
    })
      .then(({ data }) => {
              dispatch({
          type: Actions.SETLOADNREQUEST,
          payload: { ...data },
        })
        dispatch({
          type: Actions.SETAMOUNTDETAILS,
          payload: data.requestedAmount,
        })
        dispatch({
          type: Actions.SETTERMSTDETAILS,
          payload: data.requestedTerms,
        })
        
       dispatch({ type: Actions.LOADING, payload: false })
      })
      .catch((e) => {
        dispatch({
         type: Actions.CLEARLOANREQUESTSTATE
        })
        
       dispatch({ type: Actions.LOADING, payload: false })
      })

  }
}


