import * as React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    flex: 1,
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  paragraph: {
    marginBottom: theme.spacing(4),
  },
}))

const ScreenLoadingCards = () => {
  const classes = useStyles()

  return (
    <Container>
      <Typography variant="h6" className={classes.paragraph}>
        Loading
      </Typography>
      <Box className={classes.cardContainer}>
        {[...Array(3)].map((_, index) => (
          <Card variant="outlined" className={classes.card} key={index}>
            <Box sx={{ width: '100%' }}>
              <Skeleton />
              Loading ...
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton variant="rectangular" width={'100%'} height={150} />
            </Box>
          </Card>
        ))}
      </Box>
    </Container>
  )
}

export default ScreenLoadingCards
