import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box } from "@mui/system";
import { useStyles } from "./Style";
import InputLabel from "@mui/material/InputLabel";
import { Stack } from "@mui/system";
import moment from "moment";

export default function ResponsiveDatePickers({
  onChange,
  value,
  minDate,
  onBlur,
  Title,
  error,
  Label,
  placeholder,
  disableFuture,
  disablePast,
  onError,
  ...props
}) {
  const classes = useStyles();

  return (
    <Stack sx={{ width: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box className={classes.DateWrapper}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            className={classes.DateLable}
          >
            {Label}
          </InputLabel>
          <DatePicker
            className={classes.DatePicker}
            label="DATE OF APPOINTMENT"
            openTo="year"
            onBlur={onBlur}
            onError={onError}
            views={["year", "month", "day"]}
            inputFormat="DD/MM/yyyy"
            onChange={onChange}
            value={value}
            minDate={minDate || ``}
            disableFuture={disableFuture}
            disablePast={disablePast}
            shouldDisableDate={(date) => moment(date).day() === 0 || moment(date).day() === 6} // Deshabilitar sábados y domingos
            renderInput={(params) => (
              <TextField
                {...props}
                fullWidth
                {...params}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  "& .MuiInputLabel-formControl": {
                    display: "none",
                  },
                }}
              />
            )}
          />
        </Box>
      </LocalizationProvider>
      {error && <p className={classes.errorMessage}>{Title}</p>}
    </Stack>
  );
}
