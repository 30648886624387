const status = {
  0: { label: 'CREATED', button: 'Continue', link: '/Customer/PersonalInformationReview', condition: false },
  1: { label: 'SUBMITTED', button: false }, //Application not Started
  2: { label: 'IN PROGRESS', button: false },
  3: { label: 'APPROVED', button: 'See More', link: '/Customer/SignDocuments', condition: false },
  4: { label: 'DECLINED', button: false },
  5: { label: 'PENDING SIGNATURE', button: 'See More', link: '/Customer/SignDocuments', condition: true }, // si es mayor a 7k oculatar boton y mostrar cambio de fecha
  6: { label: 'SIGNED', button: 'See More', link: '/Customer/SignDocuments', condition: false },
  7: { label: 'ACCOUNT OPENED', button: false, condition: false },
  8: { label: 'DISBURSED', button: false, condition: false },
  9: { label: 'CANCELED', button: 'Re-Start', link: '/PersonalInformationReview', condition: false },
  10: { label: 'FINALIZE', button: false, condition: false },
  12: { label: 'SUBMITTED', button: false },
  13: {label: 'ISSUED OFFER', button: 'Continue', link: '/issued-offer', condition: false},
  14: {label: 'DECLINED BY THE USER', button: false}
}

export const STATUS_ENUM = {
  CREATED: 0,
  SUBMITTED: 1,
  IN_PROGRESS: 2,
  APPROVED: 3,
  DECLINED: 4,
  PENDING_SIGNATURE: 5,
  SIGNED: 6,
  ACCOUNT_OPENED: 7,
  DISBURSED: 8,
  CANCELED: 9,
  FINALIZE: 10,
  ISSUED_OFFER: 13,
  DECLINED_BY_THE_USER: 14
}

export const warrantyType = {
  1: {name: 'PERSONAL LOAN'},
  2: {name: 'VEHICLE LOAN'},
  3: {name: 'LAND LOAN'}
}

export const LoanType = {
  1: {name: 'UNSECURED'},
  2: {name: "SECURED"},
  3: {name: 'DEBT REFINANCING'}
}

export const warrantyTypeNames = ['cash', 'vehicle', 'land']

export const loanTypeNames = ['unsecure', 'secure', 'refinancing']

export default status
