import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
  InputTitle: {
    "&.MuiTypography-body1": {
      fontSize: "13px",
      fontWeight: "500",
      lineHeight: "17.76px",
      letterSpacing: "0.1em",
      color: "#706F6F",
      fontFamily: theme.fonts.family.secondary,
    },
  },
  Input: {
    width: "50%",
    ["@media (max-width: 600px)"]: {
      width: "100%",
    },
  },
  InputBox: {
    fontSize: "16px",
    fontFamily: theme.fonts.family.tertiary,
    color: "#7F7F80",
    width: "100%",
    height: "38.06px",
    textAlign: "center",
    backgroundColor: "#EAF2FB",
    marginBottom: "16.8px !important",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.colors.secondary.blue,
      color: "white",
    },
    "&.active": {
      fontSize: "16px",
      fontFamily: theme.fonts.family.tertiary,
      width: "100%",
      height: "38.06px",
      textAlign: "center",
      marginBottom: "16.8px !important",
      backgroundColor: theme.colors.secondary.blue ,
      color: "white",
    },
  },
  InputBoxSelect: {
    fontSize: "16px",
    fontFamily: theme.fonts.family.tertiary,
    width: "100%",
    height: "38.06px",
    textAlign: "center",
    marginBottom: "16.8px !important",
    cursor: "pointer",
    backgroundColor: "#56AF31 !important",
    color: "white",
  },
  BlankInput: {
    "& .MuiInputBase-input": {
      marginBottom: "16.8px",
      border: "1px solid #DADADA",
      color: "black",
      outLine: "none",
      fontSize: "18px",
      fontFamily: theme.fonts.family.tertiary,
      color: "#7F7F80",
      fontWeight: "600",
      cursor: "default"
    }
  },
}))
