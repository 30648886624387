import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../auth/jwt/Jwt'

export const hasSecureLandDocs = createAsyncThunk(
  'appSecureLandDocs/SecureLandDocs',
  async (data) => {
    const response = await Jwt.postSecureLandDocs(data)
    return response.data
  }
)

const initialState = {
  id: 0,
  street: '',
  parcelOrLotNumber: '',
  customerId: '',
  districtId: 0,
  urbanAreaId: 0,
  registrationSectionId: 0,
  landAppraisal: '',
  landAppraisalName: null,
  landTitle: '',
  landTitleName: null,
  purchaseAgreement: '',
  purchaseAgreementName: null
}

export const appsecureLandSlice = createSlice({
  name: 'appSecureLandDocs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hasSecureLandDocs.fulfilled, (state, action) => {
      state.id = action.payload.id
      state.street = action.payload.street
      state.parcelOrLotNumber = action.payload.parcelOrLotNumber
      state.customerId = action.payload.customerId
      state.districtId = action.payload.districtId
      state.urbanAreaId = action.payload.urbanAreaId
      state.registrationSectionId = action.payload.registrationSectionId
      state.landAppraisal = action.payload.landAppraisal
      state.landAppraisalName = action.payload.landAppraisalName
      state.landTitle = action.payload.landTitle
      state.landTitleName = action.payload.landTitleName
      state.purchaseAgreement = action.payload.purchaseAgreement
      state.purchaseAgreementName = action.payload.purchaseAgreementName
    }).addCase(hasSecureLandDocs.pending, (state, action) => {
      state.pending = false
    })
  }
})


export default appsecureLandSlice.reducer
