import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    Wrapper: {
        display: "flex",
        justifyContent: "center",
        alighItems: "center",
        flexDirection: "column",
    },

    buttonAlert: {
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        // border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
      },
      loadingAlert: {
        "&.MuiPaper-root": {
            "& .MuiAlert-message": {

                overflowX: 'hidden !important'
            }
        }
      },
    CardContainer: {
        width: "992.8px",
        paddingTop: "48px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "@media (max-width: 1200px)": {
            width: "auto",
        },
        "@media (max-width: 1100px)": {
            width: "auto",
        },
        "@media (max-width: 1000px)": {
            width: "auto",
        },
        "@media (max-width: 500px)": {
            backgroundColor: "green"
        },
    },

    ButtonWrapper: {
        width: "100%",
        marginTop: "28.96px",
        marginBottom: "45.12px",
    },

    CardDivider: {
        "&.MuiDivider-root": {
            border: "2px solid #C6C6C6",
            background: "#C6C6C6",
        },
    },

    SecondButtonWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20px",
        marginBottom: "45.12px",
        gap: "20.6px",

        "@media (max-width: 1100px)": {
            flexWrap: "wrap",
            gap: "9.12px",
        },
        "@media (max-width: 1000px)": {
            width: "100%",
        },
        "@media (max-width: 500px)": {
            display: "block",
        },
    },

    Button: {
        "&.MuiButtonBase-root": {
            height: "52.22px",
            width: "100%",
            backgroundColor: theme.colors.primary.Darkblue,
            "&:hover": {
                backgroundColor: theme.colors.secondary.Green,
            },
            "& .MuiTypography-root": {
                fontSize: 13,
                fontFamily: theme.fonts.family.primary,
                fontWeight: "600",
                letterSpacing: "0.2em",
            },
            "@media (max-width: 1000px)": {
                width: "100%",
            },
            "@media (max-width: 500px)": {
                width: "100%",
            },
        },
    },
    Links: {
        textDecoration: "none",
        width: '100%'
    },
    Button2: {
        "&.MuiButtonBase-root": {
            height: "52.22px",
            width: "100%",
            backgroundColor: theme.colors.secondary.Green,
            "&:hover": {
                backgroundColor: theme.colors.secondary.Green,
            },
            "& .MuiTypography-root": {
                fontSize: 13,
                fontFamily: theme.fonts.family.primary,
                fontWeight: "600",
                letterSpacing: "0.2em",
            },
            "@media (max-width: 1000px)": {
                width: "100%",
            },
            "@media (max-width: 500px)": {
                width: "100%",
            },
        },
    },
    SecondButton: {
        "&.MuiButtonBase-root": {
            height: "52.22px",
            width: '100%',

            backgroundColor: theme.colors.primary.Darkblue,
            "&:hover": {
                backgroundColor: '#F4EBFE !important',
                color: theme.colors.primary.Darkblue
            },
            "& .MuiTypography-root": {
                fontSize: "12.96px",
                fontFamily: theme.fonts.family.primary,
                fontWeight: 600,
                letterSpacing: "0.2em",
            },
            "@media (max-width: 1100px)": {
                margin: "0",
            },
            "@media (max-width: 500px)": {
                width: "100%",
                marginBottom: 20,
            },
        },
    },

    ThirdButton: {
        "&.MuiButtonBase-root": {
            height: "52.22px",
            width: "469.76px",

            backgroundColor: theme.colors.primary.Darkblue,
            "&:hover": {
                backgroundColor: theme.colors.secondary.Green,
            },
            "& .MuiTypography-root": {
                fontSize: "12.96px",
                fontFamily: theme.fonts.family.primary,
                fontWeight: "600",
                letterSpacing: "0.2em",
            },
            "@media (max-width: 1100px)": {
                margin: "0",
            },
            "@media (max-width: 500px)": {
                width: "100%",
                marginBottom: 20,
            },
        },
    },
    Refer: {
        textDecoration: "none",
    },
    Text: {
        fontFamily: theme.fonts.family.primary,
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '15px',
        lineHeight: '20px',
        color: '#3C3C3B',
        marginTop: '25px'
    },
    LearnMore: {
        fontFamily: theme.fonts.family.primary,
        fontStyle: 'normal',
        fontWeight: '800',
        fontSize: '13px',
        lineHeight: '18px',
        color: '#F07F3C',
        marginTop: 10
    },
    Header: {
        fontFamily: theme.fonts.family.primary,
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '21.335px',
        lineHeight: '25px',
        color: '#275DA6'
    },
    Card: {
        marginTop: "2.5rem",
        padding: 20,
        filter: 'drop-shadow(2px 2px 2px rgba(157, 157, 156, 0.251))',
        '@media (max-width: 500px)': {
            padding: 0,
          }
    },
    CardDetails: {
        paddingBottom: 100
    },
    CardDivider: {
        width: '139.87px',
        marginTop: '10px !important',
        background: theme.colors.primary.Darkblue,
        border: `2px solid ${theme.colors.primary.Darkblue}`
    },
    Accordian: {
        marginBottom: "15px",
        "& .MuiAutocomplete-input": {
            textAlign: "center",
            fontSize: "13px",
            lineHeight: "17.76px",
            letterSpacing: "0.1em",
            fontWeight: "500",
            fontFamily: theme.fonts.family.tertiary,
        },
        "& .MuiAccordionSummary-root ": {
            background: "rgba(198, 198, 197, 0.21)",
            border: 'solid 1px rgba(0, 0, 0, 0.15)',
            color: '#005FAA',
            borderRadius: 6,
            width: "100%"
        },
        "& .MuiTypography-body1": {

            fontStyle: 'normal',
            fontWeight: '300',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.1em',
            marginBottom: '20px',
            fontFamily: theme.fonts.family.secondary,
        },
        "& .MuiAccordionSummary-content": {
            placeContent: "center",
        },
        "& .MuiAccordionDetails-root": {
            color: "unset",
            // padding: "35px",
            padding: '8px 16px 8px',
            "@media (max-width: 500px)": {
                padding: "35px 4px 35px 4px !important"
            }
        },
        "& .MuiSvgIcon-root": {
            fill: '#005FAA',
        },
        "& .Mui-expanded": {
            backgroundColor: '#005FAA',
            color: 'white',

            "& .MuiSvgIcon-root": {
                fill: 'white',
            },
        }
    },
    CurrentLoanAccordianHeader: {
        "& .MuiAutocomplete-input": {
            textAlign: "center",
            fontSize: "13px",
            lineHeight: "17.76px",
            letterSpacing: "0.1em",
            fontWeight: "500",
            fontFamily: theme.fonts.family.tertiary,
        },
        "& .MuiAccordionSummary-root ": {
            backgroundColor: theme.colors.secondary.Green,
            color: 'white',
            borderRadius: 6,
            width: "100%"
        },
        "& .MuiTypography-body1": {
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '13px',
            lineHeight: '18px',
            letterSpacing: '0.1em',
            color: 'white',
            marginBottom: 0,
            fontFamily: theme.fonts.family.secondary,
        },
        "& .MuiTypography-subtitle1": {
            fontFamily: theme.fonts.family.primary,
            marginTop: '20px',
            color: theme.colors.secondary.blue,
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '14px',
        },
        "& .MuiTypography-subtitle2": {
            fontFamily: theme.fonts.family.secondary,
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '18px',
            lineHeight: '25px',
            color: '#706F6F',
            textAlign: 'center'
        },
        "& .MuiAccordionSummary-content": {
            placeContent: "center",
        },
        "& .MuiAccordionDetails-root": {
            color: "white",
            padding: "35px",
        },
        "& .MuiSvgIcon-root": {
            fill: theme.colors.secondary.green,
        },
        "& .Mui-expanded": {
            backgroundColor: theme.colors.secondary.Green,
            color: 'white',

            "& .MuiSvgIcon-root": {
                fill: theme.colors.secondary.blue,
            },
        }
    },
    AccordianInner: {
        "& .MuiAutocomplete-input": {
            textAlign: "center",
            fontSize: "13px",
            lineHeight: "17.76px",
            letterSpacing: "0.1em",
            fontWeight: "500",
            fontFamily: theme.fonts.family.tertiary,
        },
        "& .MuiAccordionSummary-root ": {
            backgroundColor: '#F2F2F2',
            color: 'black',
            borderRadius: 6,
            width: "100%"
        },
        "& .MuiTypography-body1": {
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '13px',
            lineHeight: '18px',
            letterSpacing: '0.1em',
            color: '#6E6E6E',
            marginBottom: 0,
            fontFamily: theme.fonts.family.secondary,
        },
        "& .MuiAccordionSummary-content": {
            placeContent: "center",
        },
        "& .MuiAccordionDetails-root": {
            color: "white",
            // padding: "35px",
            "@media (max-width: 500px)": {
                padding: "35px 8px 35px 8px !important"
            }
        },
        "& .MuiSvgIcon-root": {
            fill: theme.colors.secondary.blue,
        },
        "& .Mui-expanded": {
            backgroundColor: '#F2F2F2',
            color: 'black',

            "& .MuiSvgIcon-root": {
                fill: theme.colors.secondary.blue,
            },
        }


    },
    LoanCalculatorHeader: {
        height: "36.52px",
        backgroundColor: theme.colors.secondary.Green,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2rem",
        borderRadius: 6
    },
    Declined: {
        fontFamily: theme.fonts.family.secondary,
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '11px',
        lineHeight: '15px',
        letterSpacing: '0.1em',
        color: '#F07F3C'
    },
    Disbursed: {
        fontFamily: theme.fonts.family.secondary,
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '11px',
        lineHeight: '15px',
        letterSpacing: '0.1em',
        color: '#56AF31'
    },
    ApprovedImage: {
        marginTop: '10px',
        width: '80px',
        filter: 'drop-shadow(2.835px 2.835px 2.834px rgba(91, 91, 91, 0.349))',
        height: '80px'
    },
    NextButton: {
        // width: "20%",
        
        height: 39.01,
        "&.MuiButtonBase-root": {
            backgroundColor: theme.colors.primary.Darkblue,
            "&:hover": {
                backgroundColor: theme.colors.secondary.Green,
            },
            "& .MuiTypography-root": {
                fontSize: 13,
                fontFamily: theme.fonts.family.primary,
                color: "White",
                fontWeight: 600,
                letterSpacing: "0.2em",
            },
        },
    },
    ChangeButton: {
        width: "20%",
        height: 39.01,
        "&.MuiButtonBase-root": {
            backgroundColor: theme.colors.primary.Darkblue,
            "&:hover": {
                backgroundColor: theme.colors.secondary.Green,
            },
            "& .MuiTypography-root": {
                fontSize: 13,
                fontFamily: theme.fonts.family.primary,
                color: "White",
                fontWeight: 600,
                letterSpacing: "0.2em",
            },
        },
    },
    Numbers: {
        border: 'solid 0.5px #9C9B9B',
        padding: '15px',
        marginBottom: '8px',
        fontSize: '20px'
    },
    Validity: {
        fontFamily: '\'Gill Sans\'',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '10.668px',
        lineHeight: '13px',
        color: '#9C9B9B'
    },
    numberText:{
        color: '#9C9B9B',
        fontSize: '11px !important',
        fontStyle: 'normal !important',
        fontWeight: '600 !important',
        lineHeight: '13px !important'
    },
    MonthlyCreditInput: {
        "& .MuiInputLabel-root": {
          fontSize: "16px",
          '@media (min-width: 600px)' : {
            fontSize: "19px",
          },
        //   fontWeight: "500",
          lineHeight: "17.76px",
          letterSpacing: "0.1em",
          color: "#706F6F",
          fontWeight: 800,
          fontFamily: theme.fonts.family.secondary,
        },
      },
      UploadFiles: {
        "&.MuiButtonBase-root": {
          justifyContent: "space-between",
          height: "38.06px",
          border: " 1px solid #DADADA",
          color: "#B2B2B2",
          fontSize: "13px",
          fontWeight: "500",
          letterSpacing: "0.1em",
          lineHeight: "17.76px",
          marginTop: 22,
          fontFamily: theme.fonts.family.tertiary,
        },
      },
      fileText: {
        width: "460px",
        fontSize: "13px",
        fontWeight: "500",
        lineHeight: "17.75px",
        fontFamily: theme.fonts.family.secondary,
        color: "#706F6F",
        "@media (max-width: 1200px)": {
          width: "100%",
        },
      },
      uploadFileName: {
        fontFamily: theme.fonts.family.secondary,
        fontSize: 15,
        fontWeight: 400,
        color: theme.colors.secondary.Green,
        width:500,
        marginTop:0,
        marginBottom:15
      },
      SubmitBtn: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "100px",
        "& .MuiButtonBase-root": {
          width: "176px",
          height: "39px",
          backgroundColor: "#005FAA",
          color: "#ffff",
          "&:hover": {
            backgroundColor: theme.colors.secondary.Green,
          },
        },
        "& .MuiTypography-root": {
          fontSize: "13px",
          fontWeight: "600",
          lineHeight: "15.51px",
          letterSpacing: "0.2em",
          fontFamily: theme.fonts.family.primary,
        },
      },
}));
