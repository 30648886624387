import React, { useState } from 'react'
import { Alert, CircularProgress } from '@mui/material'
import { Download } from '@mui/icons-material'
import { useStyles } from './LoanHistoryStyles'
import { Interceptor } from '../../Utils/Interceptor'
import { Toaster, toast } from 'react-hot-toast'

const AlertDownload = ({bblDoc}) => {
  const AxiosInterceptor = Interceptor()

  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(false)

  const handleDownload = (doc) => {
    const url = doc
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', String(bblDoc.fileName))
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleClickDownload = (documentId) => {
    const token = sessionStorage.getItem('code')
    if (!token) return
    const sendData = {
      token,
      documentId
    }
    setIsLoading(true)
    AxiosInterceptor.post("/customer/bbank/download-file", sendData).then((resp) => {
      // console.log(resp)
      setIsLoading(false)
      if (!resp.data?.result) return toast.error("Not found")
      handleDownload(resp?.data?.result)
    })
  }
  return (
    <>
    <Toaster />
    <Alert
      className={`${classes.buttonAlert} ${isLoading ? classes.loadingAlert : ''}`} // Agregar una clase de estilo cuando isLoading es true
      icon={<Download fontSize="inherit" />}
      severity="info"
      onClick={() => handleClickDownload(bblDoc.id)}
      style={{marginBottom: 22}}
    >
      {isLoading ? <CircularProgress size={24} /> : String(bblDoc.fileName || "").toLocaleUpperCase()}
    </Alert></>

  )
}

export default AlertDownload
