import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TypesOfLoans from './TypesOfLoans'
import { handleScreen, hasActiveLoan } from '../../context'
import BackNext from '../../../components/back-previous-button/'
import { useNavigate } from 'react-router-dom'
import { LOANS } from './types.enum'
import { Interceptor } from '../../../../Utils/Interceptor'

const Index = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { selectOption } = useSelector((state) => state.initialLoanProcess)

  const AxiosInterceptor = Interceptor()

  const isSecureLoan = selectOption.loanType === LOANS.GUARANTEED

  const handleLoanTypeSelect = () => {
    if (isSecureLoan) changeScreen()
    else {
      sessionStorage.setItem('loan', `${selectOption.loanType}`)
      navigate(
        `/Customer/PersonalInformationReview?loan=${selectOption.loanType}`
      )
    }
  }

  const changeScreen = () => {
    dispatch(handleScreen({ screen: 'types_of_loans', active: false }))
    dispatch(handleScreen({ screen: 'types_guarantees', active: true }))
  }

  const [pending, setPending] = React.useState(true)
  useEffect(() => {
    setPending(true)
    dispatch(hasActiveLoan()).finally(() => setPending(false))
  }, [])

  return (
    <>
    {!pending && ( <TypesOfLoans /> )}
      {/*selectOption.loanType && <BackNext onNext={handleLoanTypeSelect} />*/}
    </>
  )
}

export default Index
