import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../auth/jwt/Jwt'

export const hasVehicleDocs = createAsyncThunk(
  'appVehicleDetailsDocs/vehicleDocs',
  async (data) => {
    const response = await Jwt.postVehicleDocs(data)
    // .finally(() => dispatch(getVehicleDocs()))
    return response.data
  }
)

export const getVehicleDocs = createAsyncThunk(
  'appVehicleDocs/vehicleDocs',
  async () => {
    const response = await Jwt.getVehicleDocs()
    console.log("Esto es lo que trae el response de vehicle docs", response.data)
    return response?.data?.vehicleDocuments
  }
)


const initialState = {
    mechanicCertificateFile: null,
    mechanicCertificateFileName: '',
    purchaseAgreementFile: null,
    purchaseAgreementFileName: ''
}

export const appvehicleDocsSlice = createSlice({
  name: 'appVehicleDocs',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
    .addCase(hasVehicleDocs.fulfilled, (state, action) => {
      state.mechanicCertificateFile = action.payload.mechanicCertificateFile
      state.mechanicCertificateFileName = action.payload.mechanicCertificateFileName
      state.purchaseAgreementFile = action.payload.purchaseAgreementFile
      state.purchaseAgreementFileName = action.payload.purchaseAgreementFileName
  })
  .addCase(hasVehicleDocs.pending, (state, action) => {
    state.pending = false
  })
  .addCase(getVehicleDocs.fulfilled, (state, action) => {
    state.mechanicCertificateFile = action.payload.mechanicCertificateFile
    state.mechanicCertificateFileName = action.payload.mechanicCertificateFileName
    state.purchaseAgreementFile = action.payload.purchaseAgreementFile
    state.purchaseAgreementFileName = action.payload.purchaseAgreementFileName
  })
  
})


export default appvehicleDocsSlice.reducer
