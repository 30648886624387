import Actions from "../Types"

const initialState = {
  branch: "",
  date: "",
  time: "",
  data: {},
}

const MakeScheduleReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.MAKESCHEDULE:
      return {
        ...state,
      }
      case Actions.SCHEDULE:
        return {
          ...state,
          data: { ...payload },
        }
    default:
      return state
  }
}

export default MakeScheduleReducer
