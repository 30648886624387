import React, { useEffect, useRef, useState } from 'react'
import { Box, Container } from '@mui/system'
import { useStyles } from './PersonalInformationReviewStyle'
import { Divider, Stack, Typography } from '@mui/material'
import { Field, FormikProvider, useFormik } from 'formik'
import Input from './Input'
import OtpPopUp from '../../../Components/OtpPopup/LoanPurposeOtp'
import Button from '../../../Components/Button/Button'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ClearIcon from '@mui/icons-material/Clear'
import { Interceptor } from '../../../Utils/Interceptor'
import axios from 'axios'
import { validation } from './validation'
import InputMask from 'react-input-mask'
import { useNavigate } from 'react-router-dom'
import CustomizedRadios from '../../Inputs/Radio'
import { ConfirmOTP } from '../../OtpPopup/OtpPopUp'
import LocalStoreServices from '../../../Utils/LocalStoreServices'
import { baseUrl } from '../../../baseUrl'
import toast, { Toaster } from 'react-hot-toast'
import Actions from '../../../Store/Types'
import { useDispatch, useSelector } from 'react-redux'
import PoppUpEmailConfirmation from '../LoanPurpose/PoppUpEmailConfirmation'
import PopupAlertError from '../LoanPurpose/PopupAlertError'

const PersonalInformationReview = () => {
  const AxiosInterceptor = Interceptor()
  const { loanSelected } = useSelector((state) => state.initialLoanProcess)
  const navigate = useNavigate()
  const InitialValues = {
    email: '',
    phone: '',
    hasSsb: true,
    isCurrentEmail: 'true',
    isCurrentContact: 'true',
    new_email: '',
    confirm_new_email: '',
    ssn: '',
    newPhoneNumber: '',
    ssnFile: null,
    loanType: loanSelected?.loanType,
    collateral: loanSelected?.collateral,
    currentStep: 0
  }

  const ssnFileRef = useRef([])
  const [verify, setVerify] = useState(false)

  const [initialFormValues, setInitialFormValues] = useState({})
  const [fileProofSSN, setFileProofSSN] = useState('')
  const [showOTP, setShowOTP] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [haveSocialSecurity, setHaveSocialSecurity] = useState(false)
  const dispatch = useDispatch()
  const localStorageServices = LocalStoreServices.getService()
  const ssnFileRefClick = () => {
    ssnFileRef.current.click()
  }
  // const [warrantyType, setWarrantyType] = useState(1)
  const [referenceNumber, setReferenceNumber] = useState('')
  const [confirmEmail, setconfirmEmail] = useState(true)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    validationSchema: validation,
    onSubmit: (_) => {}
  })

  // console.log(formik)

  // UPLOAD SALARY ASSIGNMENT
  const File = async (e) => {
    const file = e.target.files[0]
    const proofOfSsn = await convertBase64(file)
    setFileProofSSN(proofOfSsn)
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const options = [
    { value: 'null', label: 'YES', status: 'true' },
    { value: 'false', label: 'NO', status: 'false' }
  ]
  const [progress, _setProgress] = useState({
    field_phone: false
  })
  const classes = useStyles()

  const fetchData = async () => {
    const config = {
      token: sessionStorage.getItem('code')
    }
    await axios
      .post(`${baseUrl}/customer/bbank`, {
        ...config,
        loanTypeId:
          sessionStorage.getItem('loan') === 'secure'
            ? 2
            : sessionStorage.getItem('loan') === 'refinancing'
            ? 3
            : 1,
        warrantyTypeId:
          sessionStorage.getItem('loan') === 'secure'
            ? warrantyType.indexOf(sessionStorage.getItem('warrantyType')) + 1
            : null
      })
      .then((response) => {
        const data = response.data
        let isPhone = 'true'
        let isEmail = 'true'
        if (isEmail === '') isEmail = 'false'
        if (isPhone === '') isPhone = 'false'
        const values = {
          ...InitialValues,
          hasSsb: data.hasSsb,
          email: data.email,
          phone: data.phone,
          isCurrentEmail: isEmail,
          isCurrentContact: isPhone,
          token: data.token
        }
        if (sessionStorage.getItem('loan') === 'unsecure')
          sessionStorage.removeItem('warrantyType')
        setReferenceNumber(data.referenceNumber)
        sessionStorage.setItem('access_token', data.token)
        setInitialFormValues(values)
        // localStorageServices.setToken(data.token)
        localStorageServices.setExpirationDate(data.expirationDate)
      })

    AxiosInterceptor.post('/customers/step', {
      currentStep: 1
    })
      .then((response) => {
        console.log(response)
      })
      .catch((e) => console.log(e))
      .catch((error) => {
        toast.error('Error loading data, please try again later.')
        console.log(error)
      })
  }

  const refreshPhoneNumber = async () => {
    const config = {
      token: sessionStorage.getItem('code')
    }
    await axios
      .post(`${baseUrl}/customer/bbank`, { ...config })
      .then((response) => {
        if (response.error) return
        const data = response.data
        setInitialFormValues({
          ...formik.values,
          phone: data.phone,
          isCurrentContact: 'null'
        })
        // localStorageServices.setToken(data.token)
        localStorageServices.setExpirationDate(data.expirationDate)
      })
      .catch((error) => {
        toast.error(
          'An error occurred while updating the phone number, please try again later.'
        )
        console.log(error)
      })
  }

  useEffect(() => {
    fetchData()

    // switch (localStorage.getItem('warrantyType')) {
    //   case 'cash':
    //     setWarrantyType(1)
    //     break
    //   case 'vehicle':
    //     setWarrantyType(2)
    //     break
    //   case 'land':
    //     setWarrantyType(3)
    //     break
    //   default:
    //     setWarrantyType(null)
    //     break
    // }
  }, [])

  const warrantyType = ['cash', 'vehicle', 'land']

  const handleVerify = () => {
    setShowOTP(!showOTP)
  }

  const [newEmail, setNewEmail] = useState(false)
  const [requiredSSN, setErrorSSN] = useState(false)

  const handleVerifyEmail = (verifyMail) => {
    if (formik.errors.new_email || formik.errors.confirm_new_email) {
      return toast.error('Please complete the fields before proceeding')
    }

    if (verifyMail !== 'null') {
      isEmailSent()
      setNewEmail(false)
    }
  }

  const handleSubmit = (verifyMail, verifyPhone = false) => {
    // console.log(typeof verifyMail, verifyMail, typeof verifyPhone, verifyPhone)

    if (formik.errors.new_email || formik.errors.confirm_new_email) {
      return toast.error('Please complete the fields before proceeding')
    }
    if (
      haveSocialSecurity === false &&
      formik.values.ssn.trim() === '' &&
      !formik.values?.hasSsb
    ) {
      setErrorSSN(true)
      return toast.error('SOCIAL SECURITY NUMBER is required!')
    }
    if (verifyMail === 'true')
      return toast.error('Is this your current email address, YES or NO?')
    if (verifyMail === 'false' && !formik.values.new_email) {
      setNewEmail(false)
      return
    }
    if (verifyPhone === 'true')
      return toast.error(
        'Is this your current primary contact number, YES or NO?'
      )
    if (verifyPhone === 'false' && !isVerified)
      return toast.error('Verification of the new phone is mandatory.')

    dispatch({ type: Actions.LOADING, payload: true })
    const email =
      verifyMail === 'null' ? formik.values.email : formik.values.new_email
    const phone = isVerified
      ? formik.values.newPhoneNumber
      : formik.values.phone
    const hasEmailAddress = true
    const proofOfSsn = fileProofSSN
    const code = sessionStorage.getItem('code')
    const ssn = formik.values.ssn.trim().replaceAll(' ', '') || ''
    const hasSsn = !!ssn.length
    const typeLoan = loanSelected?.typeLoan
    const collateral = loanSelected?.collateral
    const loanTypeId =
      sessionStorage.getItem('loan') === 'secure'
        ? 2
        : sessionStorage.getItem('loan') === 'refinancing'
        ? 3
        : 1

    // alert(formik.values.new_email)

    const sentData = {
      email,
      phone,
      hasEmailAddress,
      hasSsn,
      proofOfSsn,
      code,
      ssn,
      isPhoneVerify: false,
      typeLoan,
      collateral,
      loanTypeId
    }

    if (!email) sentData.hasEmailAddress = false
    if (proofOfSsn) sentData.hasSsn = true

    AxiosInterceptor.post('/customer/bbank/update', { ...sentData })
      .then((response) => {
        const token = response.data.token
        const expirationDate = response.data.expirationDate
        localStorageServices.setToken(token)
        localStorageServices.setExpirationDate(expirationDate)
      })
      // .then(() => {
      // console.log(email)
      // if (verifyMail !== 'null') {
      //   isEmailSent()
      // }
      // })
      .then(() => {
        if (verifyMail === 'null' || verifyMail === 'false') {
          if (sessionStorage.getItem('loan') === 'unsecure') {
            navigate('/Customer/yourLoanRequest')
          } else if (sessionStorage.getItem('loan') === 'refinancing') {
            navigate('/Customer/loans-to-be-refinanced')
          } else {
            if (sessionStorage.getItem('warrantyType') === 'cash')
              navigate('/Customer/loan-collateral')
            if (
              sessionStorage.getItem('warrantyType') === 'vehicle' ||
              sessionStorage.getItem('warrantyType') === 'land'
            )
              navigate('/Customer/loan-calculation')
          }
        }
      })
      .then(() => {
        AxiosInterceptor.post('/customers/step', { currentStep: 2 })
          .then((response) => {
            // console.log(response)
          })
          .catch((e) => console.log(e))
      })
      .catch((e) =>
        toast.error('This email is already registered.', {
          duration: 4000,
          className: 'toast'
        })
      )
      .finally(() => dispatch({ type: Actions.LOADING, payload: false }))
  }

  useEffect(() => {
    if (formik.values.isCurrentContact !== 'false') setIsVerified(false)
  }, [formik.values.isCurrentContact])

  const [errorEmailInUse, setErrorEmailInUse] = useState('')

  const isEmailSent = () => {
    try {
      AxiosInterceptor.post('/customers/send-email-code', {
        email: `${formik.values.new_email}`
      }).then((response) => {
        console.log(response)
        if (response.data.emailUse) {
          setErrorEmailInUse(
            'This email is already in use. Please try another one.'
          )
        } else {
          setErrorEmailInUse('')
          setconfirmEmail(false)
        }
      })
    } catch (e) {
      if (e.response.status === 400) {
        console.log('error')
      }
      setconfirmEmail(true)
    }

    return
  }

  const [verifiedEmail, setVerifiedEmail] = useState(false)

  return (
    <Container>
      <PopupAlertError setconfirmEmail={setconfirmEmail} />
      {!confirmEmail && (
        <PoppUpEmailConfirmation
          newEmail={formik.values.new_email}
          phone={formik.values.phone}
          setconfirmEmail={setconfirmEmail}
          isVerifiedEmail={setVerifiedEmail}
        />
      )}
      <Toaster />
      {showOTP && (
        <ConfirmOTP
          newPhoneNumber={formik.values.newPhoneNumber}
          data={formik.values}
          handleContinue={setVerify}
          isVerified={setIsVerified}
          setShowOTP={setShowOTP}
          refreshData={refreshPhoneNumber}
        />
      )}
      <FormikProvider value={formik} onSubmit={formik.handleSubmit}>
        <Box className={classes.container}>
          <Typography className={classes.Header}>
            PERSONAL INFORMATION REVIEW
          </Typography>
          {newEmail && (
            <Typography variant='subtitle1' className={classes.HeaderSubtitle}>
              Please fill out the information
            </Typography>
          )}
        </Box>
        <Box className={classes.FormContainer}>
          <Field name='email'>
            {({ meta }) => (
              <Input
                Lable='EMAIL ADDRESS*'
                error={meta.touched && meta.error}
                LabelTextOther='(Is this your current email address?)'
                value={meta.value}
                readOnly
              />
            )}
          </Field>

          <Field name='isCurrentEmail'>
            {({ field, meta }) => (
              <>
                <CustomizedRadios
                  options={options}
                  value={meta.value}
                  onChange={(e, value) => {
                    formik.setFieldValue(field.name, value)
                    // console.log(e.target.value)
                    // if(e.target.value === 'null'){
                    //   console.log(e.target)
                    //   sendEmail()
                    //   setconfirmEmail(false)
                    // }
                  }}
                />
                {meta.value === 'null' && (
                  <Typography
                    variant='subtitle1'
                    className={classes.TextConfirmation}
                  >
                    Thank you for confirming!
                  </Typography>
                )}
              </>
            )}
          </Field>

          {formik.values.isCurrentEmail === 'false' ? (
            <>
              <Stack
                className={classes.wrapFlex}
                direction='row'
                spacing={{ xs: 1, sm: 2, md: 2 }}
                mb={0}
              >
                <Field name='new_email'>
                  {({ field, meta }) => (
                    <Input
                    disabled={verifiedEmail}
                      LabelTextOther='Please provide us with your new email address'
                      error={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
                <Field name='confirm_new_email'>
                  {({ field, meta }) => {
                    return (
                      <Input
                      disabled={verifiedEmail}
                        LabelTextOther='Please reconfirm your email address'
                        error={meta.touched && meta.error}
                        {...field}
                      />
                    )
                  }}
                </Field>
              </Stack>
              {formik.values.new_email !== formik.values.confirm_new_email &&
                formik.values.new_email.length && (
                  <Typography
                    variant='subtitle1'
                    className={classes.TextConfirmationError}
                    mb={4}
                  >
                    Please complete the fields before proceeding.
                  </Typography>
                )}

              {formik.values.new_email === formik.values.confirm_new_email &&
                formik.values.new_email.length && errorEmailInUse.length ? (
                  <Typography
                    variant='subtitle1'
                    className={classes.TextConfirmationError}
                    mb={4}
                  >
                      {errorEmailInUse}
                  </Typography>
                ) : null}

              {formik.values.new_email === formik.values.confirm_new_email &&
              formik.values.new_email.length &&
              !verifiedEmail ? (
                <Stack
                  justifyContent='center'
                  alignItems='center'
                  className={classes.widthVerifyBtn}
                  style={{ marginTop: '24px' }}
                >
                  <Button
                    Title='Verify'
                    variant='h7'
                    style={{ width: '100%' }}
                    className={classes.NextButton}
                    BtnVariant='contained'
                    onClick={() => handleVerifyEmail()}
                  />
                </Stack>
              ) : (
                <>
                  {verifiedEmail && (
                    <Typography color='green'>
                      Verification successful, you can continue
                    </Typography>
                  )}
                </>
              )}
            </>
          ) : (
            <></>
          )}
          <Stack
            direction='column'
            spacing={{ xs: 1, sm: 2, md: 2 }}
            mb={4}
            mt={5}
          >
            <Field name='phone'>
              {({ meta }) => (
                <Input
                  Lable='PHONE NUMBER*'
                  error={meta.touched && meta.error}
                  LabelTextOther='(Is this your current primary contact number?)'
                  value={meta.value}
                  readOnly
                />
              )}
            </Field>

            <Field name='isCurrentContact'>
              {({ field, meta }) => (
                <>
                  <CustomizedRadios
                    options={options}
                    {...field}
                    onChange={(e, value) =>
                      formik.setFieldValue(field.name, value)
                    }
                  />

                  {meta.value === 'null' && (
                    <Typography
                      variant='subtitle1'
                      className={classes.TextConfirmation}
                    >
                      Thank you for confirming!
                    </Typography>
                  )}
                  {/* {meta.value === 'false' && (
                  <Typography variant="subtitle1" className={classes.TextConfirmation}>
                  Completar ...
                  </Typography>
                  )} */}
                </>
              )}
            </Field>

            <OtpPopUp />
          </Stack>
          {formik.values.isCurrentContact === 'false' ? (
            <>
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={2}
              >
                <Field name='newPhoneNumber'>
                  {({ field, form, meta }) => (
                    <InputMask
                      {...field}
                      mask='501-699-9999'
                      alwaysShowMask={false}
                      disabled={progress.field_phone}
                      value={meta.phone}
                      onChange={(e) =>
                        form.setFieldValue('newPhoneNumber', e.target.value)
                      }
                    >
                      {(inputProps) => (
                        <Input
                          LabelTextOther='Please provide us with your new cell phone number'
                          error={meta.touched && meta.error}
                          {...inputProps}
                          type='tel'
                        />
                      )}
                    </InputMask>
                  )}
                </Field>
                <Stack
                  justifyContent='center'
                  alignItems='center'
                  width='20%'
                  style={{ marginTop: '24px' }}
                >
                  <Button
                    Title='Verify'
                    variant='h7'
                    style={{ width: '100%' }}
                    className={classes.NextButton}
                    BtnVariant='contained'
                    onClick={() => handleVerify()}
                  />
                </Stack>
              </Stack>
              {!isVerified && (
                <Typography
                  variant='subtitle1'
                  className={classes.TextConfirmationError}
                >
                  Please verify your new cell number before proceeding by
                  clicking on the 'verify' button
                </Typography>
              )}
              {isVerified && (
                <Typography color='green'>
                  Verification successful, you can continue
                </Typography>
              )}
            </>
          ) : (
            <></>
          )}
          <Divider className={classes.Divider} />
          {!formik.values?.hasSsb && (
            <>
              <Typography
                variant='subtitle1'
                flexDirection={'column'}
                className={classes.Text}
              >
                It seems that we don’t have your social security number. Please
                provide your valid Social Security number below.
              </Typography>
              <Stack
                direction='row'
                spacing={{ xs: 1, sm: 2, md: 2 }}
                mb={4}
                alignItems='center'
                className={classes.socialScurity}
              >
                {haveSocialSecurity === false && (
                  <>
                    <Field name='ssn'>
                      {({ field }) => (
                        <InputMask
                          mask='999 999 999'
                          alwaysShowMask={false}
                          name='ssn'
                          {...field}
                        >
                          {(inputProps) => (
                            <Input
                              Lable='SOCIAL SECURITY NUMBER*'
                              {...inputProps}
                              type='text'
                            />
                          )}
                        </InputMask>
                      )}
                    </Field>
                  </>
                )}
                <Button
                  Title={
                    haveSocialSecurity
                      ? 'I have a Social Security Card'
                      : `I Don't have a Social Security Card`
                  }
                  variant='h7'
                  className={
                    haveSocialSecurity === false
                      ? classes.noSSNBtn
                      : classes.noSSNBtnNo
                  }
                  BtnVariant='contained'
                  onClick={() => {
                    formik.setFieldValue('ssn', '')
                    formik.setFieldValue('ssnFile', '')
                    setFileProofSSN('')
                    setHaveSocialSecurity(!haveSocialSecurity)
                  }}
                />
              </Stack>
              {haveSocialSecurity === false && (
                <>
                  {formik.errors.ssn && formik.touched.ssn && (
                    <div style={{ color: 'red', marginBottom: 20 }}>
                      {formik.errors.ssn}
                    </div>
                  )}
                </>
              )}
              <Stack display='block' mt={0} mb={4}>
                {requiredSSN && !haveSocialSecurity && (
                  <Typography
                    variant='subtitle1'
                    display='block'
                    className={classes.TextConfirmationError}
                  >
                    SOCIAL SECURITY NUMBER is required!
                  </Typography>
                )}
              </Stack>

              {haveSocialSecurity === false && (
                <Stack
                  direction='column'
                  width={{ xs: '100%', sm: '49%' }}
                  mt={2}
                >
                  <Button
                    Title='Upload Files'
                    variant='h7'
                    className={classes.UploadFiles}
                    endIcon={<AttachFileIcon />}
                    onClick={(_) => ssnFileRefClick()}
                  />
                  <Field name='ssnFile'>
                    {({ field, meta }) => (
                      <>
                        <input
                          {...field}
                          type='file'
                          accept='application/pdf,image/*'
                          onChange={(event) => {
                            formik.setFieldValue(
                              field.name,
                              event.currentTarget.files[0]
                            )
                            File(event)
                          }}
                          className={classes.MonthlyCreditInput}
                          value=''
                          style={{ display: 'none' }}
                          ref={ssnFileRef}
                        />
                        <p className={classes.fileText}>
                          Only PDF and PNG files are permitted with a maximum of
                          2MB
                        </p>
                        {meta.error ? (
                          <p
                            className={classes.fileText}
                            style={{ color: 'red', marginTop: 0 }}
                          >
                            {meta.error}
                          </p>
                        ) : (
                          meta?.value?.name && (
                            <p className={classes.uploadFileName}>
                              {`${String(meta.value.name).substring(
                                0,
                                15
                              )} ...`}
                              <span>
                                <ClearIcon
                                  className={classes.deleteFileIcon}
                                  onClick={() => {
                                    formik.setFieldValue(field.name, null)
                                  }}
                                />
                              </span>
                            </p>
                          )
                        )}
                      </>
                    )}
                  </Field>
                </Stack>
              )}
            </>
          )}
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 2 }}
            justifyContent='center'
            alignItems='center'
            mt={5}
            mb={2}
          >
            {(verify === true ||
              formik.values.isCurrentContact !== 'false') && (
              <Button
                Title='CONTINUE'
                variant='h7'
                // type='submit'
                className={classes.NextButton}
                BtnVariant='contained'
                onClick={() => {
                  ;(!formik.errors.ssn || haveSocialSecurity) &&
                    handleSubmit(
                      formik.values.isCurrentEmail,
                      formik.values.isCurrentContact
                    )
                }}
                // onClick={() => navigate('/Customer/yourLoanRequest')}
              />
            )}
          </Stack>
        </Box>
      </FormikProvider>
    </Container>
  )
}

export default PersonalInformationReview
