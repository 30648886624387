import React, { useState, useEffect } from 'react'
import { useStyles } from './MonthlyInstallmentsStyle'
import { Box, Stack } from '@mui/system'
import LoanDetails from '../../LoanDetails/LoanDetails'
import PersonalOnlineLoan from '../../PersonalOnlineLoan/PersonalOnlineLoan'
import LoanCalculator from '../../LoanCalculator/LoanCalculator'
import ArrowButton from '../../ArrowButton/ArrowButton'
import { Outlet, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GetLoandRequest } from '../../../Store/Actions/GetLoandRequest'
import { useRef } from 'react'
import LocalStorageService from '../../../Utils/LocalStoreServices'
import Actions from '../../../Store/Types'
import Button from '../../Button/Button'
import { loanRequest } from '../../../Store/Actions/LoanRequest'
import axios from 'axios'
import { baseUrl } from '../../../baseUrl'
import { Interceptor } from '../../../Utils/Interceptor'
import { warrantyTypeNames } from '../../status/index'

const MonthlyInstallments = () => {
  const navigate = useNavigate()
  const AxiosInterceptor = Interceptor()
  const {
    requestedAmount,
    requestedTerms,
    amount: monto,
    terms: terminos,
    typeOfCollateral,
    debtCancellationSchemeRequested,
    loanTypeId
  } = useSelector((state) => state.LoanRequestReducer)
  const { dataSchedule } = useSelector((state) => state.ScheduleRequestReducer)
  const { steepLinks } = useSelector(state => state.steeps)


  const Location = useLocation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const Navigate = useNavigate()
  const [amount, setAmount] = useState()
  const [terms, setTerms] = useState()
  const [error, setError] = useState('')
  const [errorTerms, setErrorTerms] = useState('')
  const [nextLink, setNextLink] = useState('#')
  const [showButtonLink, setShowButtonLink] = useState(true)
  const [disabledContinue, setdisabledContinue] = useState(true)
  const [showPersonal, setShowPersonal] = useState({
    show: false,
    message: ''
  })
  const [cancellationSchemeRequested, setCancellationSchemeRequested] = useState(debtCancellationSchemeRequested)

    const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const formData = {
      requestedAmount: Number(amount),
      requestedTerms: Number(terms),
      // promoCode: promoCodeValue,
      interestRate: 1,
      loanTypeId: sessionStorage.getItem('loan') === 'secure' ? 2 : 1,
      // debtCancellationSchemeRequested: false,
      warrantyTypeId: sessionStorage.getItem('loan') === 'secure' ? warrantyTypeNames.indexOf(sessionStorage.getItem('warrantyType')) + 1 : null,
      token: sessionStorage.getItem('code')
    }
    // if (amount > 30000 && terms >= 12) {
    //   dispatch(loanRequest(formData))
    // }

    // if (amount >= 1000 && terms > 60) {
    //   dispatch(loanRequest(formData))
    // }

    if (
      typeOfCollateral === 'partially' &&
      amount >= 1000 &&
      terms <= 60 &&
      terms >= 12
    ) {
      dispatch(loanRequest(formData))
    }

    if (
      typeOfCollateral === 'fully' &&
      amount >= 1000 &&
      terms >= 12 &&
      terms <= 120
    ) {
      dispatch(loanRequest(formData))
    }
    if (typeOfCollateral === 'partially' && (terms > 60 || terms < 12)) {
      setdisabledContinue(true)
      setErrorMessage('The term for partially secured loans must be between 12 and 60 months. Please select a valid option.')
    }
    if (typeOfCollateral === 'fully' && (terms > 120 || terms < 12)) {
      setdisabledContinue(true)
      setErrorMessage('The term for fully secured loans must be between 12 and 120 months. Please select a valid option.')
    }
    if (typeOfCollateral === 'partially' && terms <= 60 && terms >= 12) {
      setdisabledContinue(false)
      setErrorMessage('')
    }
    if (typeOfCollateral === 'fully' && terms <= 120 && terms >= 12) {
      setdisabledContinue(false)
      setErrorMessage('')
    }
  }, [terms, amount])

  const countPage = useRef(0)

  useEffect(() => {
    window.onpopstate = (e) => {
      window.history.pushState(null, '')
    }
    window.history.pushState(null, '')
  }, [])

  useEffect(() => {
    if (dataSchedule.emailSent ?? false) {
      setShowButtonLink(false)
      LocalStorageService.clearToken()
    }
  }, [dataSchedule])

  useEffect(() => {
    if (countPage.current === 0 && requestedAmount > 0 && requestedTerms > 60) {
      if (sessionStorage.getItem('warrantyType') === 'cash') {
        setNextLink('/Customer/LoanPurpose')
      } else {
        setNextLink('/Customer/MonthlyCreditObligations')
      }
      return
    }
    if (amount > 0 && terms > 0) {
      if (sessionStorage.getItem('warrantyType') === 'cash') {
        setNextLink('/Customer/LoanPurpose')
      } else {
        setNextLink('/Customer/MonthlyCreditObligations')
      }
    } else setNextLink('#')
  }, [amount, terms])

  useEffect(() => {
    dispatch({ type: Actions.LOADING, payload: true })
    dispatch({ type: Actions.CLEARLOANREQUESTSTATE })
    dispatch(GetLoandRequest())
  }, [])
  useEffect(() => {
    setAmount(Number(monto))
    //setAmount(5000)
  }, [monto])
  useEffect(() => {
    setTerms(Number(terminos))
    //setTerms(500)
  }, [terminos])
  const handleSubmitData = () => {
    //postCashLoanCollateral(data)
    AxiosInterceptor.post('/customers/step', { currentStep: 4 })
      .then((response) => {
        console.log(response)
      })
      .catch((e) => console.log(e))
    // navigate('/Customer/LoanPurpose')
  }

  const handleBackBtn = () => {
    AxiosInterceptor.post('/customers/step', { currentStep: 2 })
      .then((response) => {
        console.log(response)
      })
      .catch((e) => console.log(e))
  }

  // console.log(terms)
  console.log(disabledContinue)

  return (
    <Box>
      {Location.pathname === '/Customer/yourLoanRequest/TermsAndConditions' ? (
        <Outlet />
      ) : (
        <Box>
          <Box className={classes.LoanContainer}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 2, sm: 2, md: 10, lg: 20 }}
              width='100%'
            >
              <LoanDetails
                setAmount={setAmount}
                amount={Number(amount)}
                terms={Number(terms)} //terms
                setTerms={setTerms}
                error={error}
                errorTerms={errorTerms}
                typeLoan={'SECURE'}
                typeOfCollateral={typeOfCollateral} // partially or  typeOfCollateral collateralTest
              />
            </Stack>
            {amount || terms ? (
              <>
                <LoanCalculator
                  amount={Number(amount)}
                  terms={Number(terms)}
                  setError={setError}
                  setErrorTerms={setErrorTerms}
                  loanType={'secure'}
                  typeOfCollateral={typeOfCollateral}
                  cancellationSchemeRequested={setCancellationSchemeRequested}
                />
              </>
            ) : (
              <LoanCalculator
                amount={Number(amount)}
                terms={Number(terms)}
                setError={setError}
                setErrorTerms={setErrorTerms}
                loanType={'secure'}
                typeOfCollateral={typeOfCollateral}
                cancellationSchemeRequested={setCancellationSchemeRequested}
              />
            )}
          </Box>

          {showButtonLink && (
            <>
              {/* <ArrowButton link="/Customer/LoanPurpose"/> */}
              <ArrowButton
                onClickBackBtn={() => handleBackBtn()}
                onClick={() => handleSubmitData()}
                link={'/Customer/monthlyIncome'}
                back={'/Customer/loan-collateral'}
                // msg={typeOfCollateral === 'partially' && terms > 60 ?'The term for partially secured loans must not exceed 60 months. Please select your preferred option.' : 'The term for fully secured loans must not exceed 120 months. Please select your preferred option.'}
                msg={errorMessage}
                disabled={
                  disabledContinue
                    ? true
                    : false
                }
              />
            </>
          )}
        </Box>
      )}
    </Box>
  )
}

export default MonthlyInstallments
