export const query = {
  where: {
    status: true
  },
  include: [
    {
      relation: 'selectOptions',
      scope: {
        where: {
          status: true
        },
        include: [
          {
            relation: 'dropdownOptions',
            scope: {
              where: { status: true }
            }
          }
        ]
      }
    }
  ]
}
