import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  currency: {
    "& .MuiInputBase-root.MuiInput-root.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedStart.MuiInputBase-marginDense.MuiInput-marginDense": {
      marginTop: '10px',
  }
  },
  widthRadioBtn: {
    width: '100%',
    '@media (max-width: 900px)': {
      width: '80%',
    },
  },
  headerSize: {
    width: '70%',
    '&.MuiTypography-root': {
      // margi: '0 auto',
    '@media (max-width: 600px)': {
      fontSize: '0.7rem',
    },
  },
  '@media (max-width: 600px)': {
    width: '100%'
  },
},
tooltip: {
  position: "relative", // Agrega posición relativa para el seudoelemento
  backgroundColor: "#fff !important",
  color: "#275DA6 !important",
  maxWidth: '220px !important',
  right: "0%",
  textTransform: "none !important",
  fontSize: "14px !important",
  boxShadow: theme.shadows[1],
  border: "1px solid #275DA6 !important",
  borderRadius: "10px",
  padding: "10px 20px !important",
  textAlign: "left",
  "& .MuiTooltip-arrow": {
    display: "none", // Oculta la flecha por defecto
  },

  "@media (max-width: 600px)": {
    left: "15%",
  },

  // Agrega un triángulo pseudo-elemento
  "&::before": {
    content: '""',
    position: "absolute",
    top: "27%", // Ajusta la posición vertical
    right: "-4%", // Alinea el triángulo en el centro
    transform: "translateX(50%)", // Centra horizontalmente
    width: 0,
    height: 0,
    borderLeft: "10px solid #275DA6",
    borderRight: "10px solid transparent",
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent", // Ajusta el color y tamaño del triángulo
  },
},
  TypographySize: {
    '&.MuiTypography-root': {
      // margi: '0 auto',
    '@media (max-width: 600px)': {
      fontSize: '0.7rem',
    },
  },
  },
  ModalVehicle: {
    padding: '1.5rem 3rem',
    textAlign: 'center',
    '@media (max-width: 600px)': {
      padding: '1.5rem 1rem'
    },
  },
  PrimaryButton: {
    "&.MuiButtonBase-root":{
      width: '45%',
      padding: '0.5rem',
      backgroundColor: '#275DA6',
      '@media (max-width: 600px)': {
        width: '100%',
      },
    },
    "& .MuiTypography-root": {
      fontSize: '14px !important',
    }
  },
  SecondaryButton: {
    "&.MuiButtonBase-root":{
      width: '45%',
      padding: '0.5rem',
      backgroundColor: '#fff',
      border: '1px solid #275DA6',
      color: '#275DA6',
      '@media (max-width: 600px)': {
        width: '100%',
      },
      "&:hover": {
        color: '#fff'
      }
    },
    "& .MuiTypography-root": {
      fontSize: '14px !important'
    }
  },
  checkboxLabel: {
    fontSize: '0.9rem !important',
    fontFamily: theme.fonts.family.secondary
  },
  InputTitle: {
    '&.MuiTypography-body1': {
      fontSize: '13px',
      fontWeight: '500',
      lineHeight: '17.76px',
      letterSpacing: '0.1em',
      color: '#706F6F',
      fontFamily: theme.fonts.family.secondary
    }
  },
  Input: {
    width: '50%',
    ['@media (max-width: 600px)']: {
      width: '100%'
    }
  },
  InputBox: {
    fontSize: '16px',
    fontFamily: theme.fonts.family.tertiary,
    color: '#7F7F80',
    width: '100%',
    height: '38.06px',
    textAlign: 'center',
    backgroundColor: '#EAF2FB',
    marginBottom: '16.8px !important',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.secondary.blue,
      color: 'white'
    },
    '&.active': {
      fontSize: '16px',
      fontFamily: theme.fonts.family.tertiary,
      width: '100%',
      height: '38.06px',
      textAlign: 'center',
      marginBottom: '16.8px !important',
      backgroundColor: theme.colors.secondary.blue,
      color: 'white'
    }
  },
  InputBoxSelect: {
    fontSize: '16px',
    fontFamily: theme.fonts.family.tertiary,
    width: '100%',
    height: '38.06px',
    textAlign: 'center',
    marginBottom: '16.8px !important',
    cursor: 'pointer',
    backgroundColor: '#56AF31 !important',
    color: 'white'
  },
  BlankInput: {
    '& .MuiInput-input': {
      // marginBottom: '16.8px',
      // border: '1px solid #DADADA',
      // color: 'black',
      outLine: 'none',
      fontSize: '13px',
      fontFamily: theme.fonts.family.tertiary,
      color: '#535353',
      // fontWeight: '600',
      cursor: 'default'
    }
  }
}))
