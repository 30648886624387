import React, { useEffect, useRef, useState } from 'react'
import { useFormik, Field, FormikProvider, ErrorMessage } from 'formik'
import { Stack } from '@mui/system'
import Select from '../../Inputs/Select'
import Button from '../../Button/Button'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { useStyles } from './ProofOfIncomeStyle'
import { Typography, Box } from '@mui/material'
import ArrowButton from '../../ArrowButton/ArrowButton'
import { convertToUpload, validationSchema } from './validation'
import Input from './Input'
import Alert from '@mui/material/Alert'
import { baseUrl } from '../../../baseUrl'
import ClearIcon from '@mui/icons-material/Clear'
import { Interceptor } from '../../../Utils/Interceptor'
import { toast, Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import FILEJOBLETTER from '../../../assets/pdf/Employment Verification Letter.pdf'
import { Download } from '@mui/icons-material'
import { useMovil } from '../../../hooks/useMovil'
import AlertInfo from '../../Alert'
import { useSelector } from 'react-redux'

const AxiosInterceptor = Interceptor()
const SalaraySelect = async (salaryId) => {
  if (salaryId) {
    const response = await AxiosInterceptor.get(
      `income-proof-salaries/${salaryId}`
    )
    return response.data
  } else {
    return ''
  }
}
const JobLetterSelect = async (jobLetterId) => {
  if (jobLetterId) {
    const response = await AxiosInterceptor.get(`job-letters/${jobLetterId}`)
    return response.data
  } else {
    return ''
  }
}
const getFinancialInstitution = async (bankId) => {
  if (bankId) {
    const response = await AxiosInterceptor.get(
      `/banks/${bankId}?filter={ "order": "position"}`
    )
    return response.data
  } else {
    return ''
  }
}

const ProofOfIncome = () => {
  // const [isCompleted, setCompleted] = useState(false)
  // console.log(validationSchema)
  const { message, hasDownload } = useMovil('employementverification')
  const navigate = useNavigate()
  const [showNext, setShowNext] = useState(true)
  const { steepLinks } = useSelector((state) => state.steeps)

  const [FormId, setFormId] = useState({
    id: 0,
    customerId: ''
  })
  const convertToUpload = (theBlob, filename) => {
    if (theBlob != null) {
      var arr = theBlob.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    } else {
      return ''
    }
  }
  const mapData = async () => {
    const response = await AxiosInterceptor.get('/income-proof-salary-users')
    if (response.data) {
      let file = null
      let file2 = null
      const jobsLetters = [1, 3, 4]
      if (
        jobsLetters.includes(response.data.idJobLetter) &&
        response.data.jobLetterFile
      ) {
        file = await convertToUpload(response.data.jobLetterFile, 'job-letter')
      }
      // if (response.data.idJobLetter == 1 && response.data.jobLetterFile) {
      //   file = convertToUpload(response.data.jobLetterFile, 'job-letter')
      // }
      // if (response.data.idJobLetter == 3 && response.data.jobLetterFile) {
      //   file2 = convertToUpload(response.data.jobLetterFile, 'job-letter')
      // }
      let salary = await SalaraySelect(response.data.idIncomeProofSalary)
      let JobLetter = await JobLetterSelect(response.data.idJobLetter)
      let bank = await getFinancialInstitution(response.data.idBank)
      const formValues = {
        salary: salary,
        jobLetter: JobLetter,
        File: response.data.idBan,
        email: response.data.employerEmail ? response.data.employerEmail : '',
        confirm_email: response.data.employerEmail
          ? response.data.employerEmail
          : '',
        File: file,
        bank: bank,
        File2: file2
      }
      setFormId({
        id: response.data.id ? response.data.id : 0,
        customerId: response.data.customerId ? response.data.customerId : ''
      })
      return formValues
    }
  }
  const getData = async () => {
    return Promise.all([mapData()])
  }

  const classes = useStyles()
  const inputFile = useRef(null)
  const inputFile2 = useRef(null)
  const countPage = useRef(0)
  const formik = useFormik({
    initialValues: {
      salary: '',
      jobLetter: '',
      File: null,
      email: '',
      confirm_email: '',
      bank: '',
      File2: null
    },
    enableReintialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log('LOS VALORES', values)
      handleSubmit(values)
    }
  })
  const onButtonClick = () => {
    inputFile.current.click()
  }
  const onButton2Clicked = () => {
    inputFile2.current.click()
  }
  const handleSubmit = async (values) => {
    // console.log(values)
    let file
    const jobsLetter = [1, 3, 4]
    if (jobsLetter.includes(values?.jobLetter?.id))
      file = await convertBase64(values.File)
    // if (values.jobLetter.id == 1 || values.jobLetter.id == 3) file = await convertBase64(values.File)
    // if (values.jobLetter.id == 3) file = await convertBase64(values.File2)

    const AxiosInterceptor = Interceptor()
    if (FormId.id == 0) {
      const formData = {
        idIncomeProofSalary: values.salary.id,
        idJobLetter: values.jobLetter.id,
        idBank: values.bank?.id,
        jobLetterFile: file,
        employerEmail: values.jobLetter.id === 2 ? values.email : ''
      }
      toast.loading('Please wait, we are validating your information.', {
        duration: 4000
      })
      setShowNext(false)
      await AxiosInterceptor.post('/income-proof-salary-users', formData)
        .then((response) => {
          if (response.error)
            return toast.error('Unexpected error occurred, try again later.')
          navigate('/Customer/AssetsDeclaration')
        })
        .catch(() => {
          toast.error('Unexpected error occurred, try again later.')
        })
        .finally(() => setShowNext(true))
    } else {
      toast.loading('Please wait, we are validating your information.', {
        duration: 4000
      })
      const formData = {
        idIncomeProofSalary: values.salary.id,
        idJobLetter: values.jobLetter.id,
        idBank: values.bank?.id,
        jobLetterFile: file,
        employerEmail: values.jobLetter.id === 2 ? values.email : '',
        ...FormId
      }
      setShowNext(false)
      await AxiosInterceptor.put(
        `/income-proof-salary-users/${FormId.id}`,
        formData
      )
        .then((response) => {
          if (response.error)
            return toast.error('Unexpected error occurred, try again later.')
          navigate('/Customer/AssetsDeclaration')
        })
        .catch(() => {
          toast.error('Unexpected error occurred, try again later.')
        })
        .finally(() => setShowNext(true))
    }
  }
  const convertBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
          resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
          reject(error)
        }
      })
    } else {
      return new Promise((resolve, reject) => {
        resolve('')
      })
    }
  }
  useEffect(() => {
    getData().then((values) => {
      formik.setValues(values[0])
    })
    if (countPage.current === 0) {
      countPage.current = 1
      window.scroll(0, 0)
      return
    }
  }, [])
  useEffect(() => {
    window.onpopstate = (e) => {
      window.history.pushState(null, '')
    }
    window.history.pushState(null, '')
  }, [])

  const downloadJobLetter = () => {
    const link = document.createElement('a')
    link.download = `${'Employment Verification Letter' || ''}`
    link.href = FILEJOBLETTER
    // link.target = '_blank'
    link.click()
  }

  const handleChangeOptions = ({ id }) => {
    if (id === 4) setShowNext(true)
  }

  return (
    <Box>
      <Toaster />
      <FormikProvider value={formik}>
        <Box className={classes.ProofOfIncomeContainer}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
            alignItems='end'
            mb={13}
          >
            <Field name='salary'>
              {({ field, form, meta }) => (
                <>
                  <Select
                    {...field}
                    Label='PLEASE SELECT A PROOF OF INCOME'
                    placeholder='Please select a proof of income.'
                    ApiUrl={`${baseUrl}/income-proof-salaries?filter={"order":"position"}`}
                    error={formik.touched.salary && formik.errors.salary}
                    onBlur={() => {
                      formik.setFieldTouched(field.name)
                    }}
                    value={meta.value}
                    onChange={(e, value) => {
                      handleChangeOptions(value)
                      value && form.setFieldValue('salary', value)
                      // console.log("El valor es: ", value.id)
                      const id = value.id
                      if (Number(id) === 4) {
                        form.setFieldValue('bank', '')
                        form.setFieldValue('jobLetter', '')
                        form.setFieldValue('email', '')
                        form.setFieldValue('confirm_email', '')
                      }
                    }}
                  />
                </>
              )}
            </Field>

            <Field name='bank'>
              {({ field, form, meta }) => (
                <>
                  {formik.values?.salary?.id == 2 && (
                    <Select
                      {...field}
                      Label='PLEASE SELECT ONE OPTION'
                      placeholder='Select one option'
                      ApiUrl={`${baseUrl}/banks?filter={ "order": "position"}`}
                      error={formik.touched.bank && formik.errors.bank}
                      value={meta.value}
                      onBlur={() => {
                        formik.setFieldTouched(field.name)
                      }}
                      onChange={(e, value) => {
                        value && form.setFieldValue('bank', value)
                      }}
                    />
                  )}
                </>
              )}
            </Field>
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
          >
            {formik.values?.salary?.id !== 4 && (
              <>
                <Stack width={{ xs: '100%', sm: '50%' }}>
                  {/* <Select Label="JOB LETTER" selectOptions={jobLetter} /> */}
                  <Field name='jobLetter'>
                    {({ field, form, meta }) => (
                      <>
                        <Select
                          {...field}
                          Label='JOB LETTER'
                          placeholder='Select one option'
                          ApiUrl={`${baseUrl}/job-letters?filter={ "order": "position"}`}
                          error={
                            formik.touched.jobLetter && formik.errors.jobLetter
                          }
                          value={meta.value}
                          onBlur={() => {
                            formik.setFieldTouched(field.name)
                          }}
                          onChange={(e, value) => {
                            value && form.setFieldValue('jobLetter', value)
                          }}
                        />
                      </>
                    )}
                  </Field>
                </Stack>

                {formik.values?.jobLetter?.id === 3 && (
                  // <a href='#' download="Employment Verification" className={classes.UploadFiles}>Employment Verification</a>
                  <Stack width={{ xs: '100%', sm: '50%' }}>
                    {hasDownload && (
                      <Button
                        Title='Download Job Letter'
                        className={classes.UploadFiles}
                        endIcon={<Download />}
                        onClick={downloadJobLetter}
                      />
                    )}
                    {!hasDownload && <AlertInfo content={message} />}
                  </Stack>
                )}
              </>
            )}
            {(formik.values?.jobLetter?.id == 1 ||
              formik.values?.jobLetter?.id == 3 ||
              formik.values?.jobLetter?.id === 4) &&
              formik.values?.salary?.id !== 4 && (
                <Stack width={{ xs: '100%', sm: '50%' }}>
                  <Button
                    Title='Please upload your job letter'
                    variant='h7'
                    className={classes.UploadFiles}
                    endIcon={<AttachFileIcon />}
                    onClick={onButtonClick}
                  />
                  <Field name='File'>
                    {({ field, form, meta }) => (
                      <>
                        <input
                          {...field}
                          type='File'
                          accept='application/pdf,image/*'
                          onChange={(event) => {
                            form.setFieldValue(
                              'File',
                              event.currentTarget.files[0]
                            )
                          }}
                          value=''
                          style={{ display: 'none' }}
                          ref={inputFile}
                          error={meta.touched && meta.error}
                        />
                        <Typography className={classes.UploadFileText}>
                          Only pdf documents are permitted with a maximum of 2MB
                        </Typography>
                        {meta.error ? (
                          <p
                            className={classes.fileText}
                            style={{ color: 'red' }}
                          >
                            {meta.error}
                          </p>
                        ) : (
                          // (
                          //   meta?.value?.name && (
                          //     <p className={classes.uploadFileName}>
                          //       {`${String(meta.value.name).substring(0,20)}...`}
                          //       <span>
                          //         <ClearIcon
                          //           className={classes.deleteFileIcon}
                          //           onClick={() => {
                          //             formik.setFieldValue(field.name, null)
                          //           }}
                          //         />
                          //       </span>
                          //     </p>
                          //   ) :
                          meta?.value?.name && (
                            <p className={classes.uploadFileName}>
                              {`${String(meta.value.name).substring(0, 20)}...`}
                              <span>
                                <ClearIcon
                                  className={classes.deleteFileIcon}
                                  onClick={() => {
                                    formik.setFieldValue(field.name, null)
                                  }}
                                />
                              </span>
                            </p>
                          )
                        )}
                      </>
                    )}
                  </Field>
                </Stack>
              )}
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
            mt={3}
            alignItems='end'
            mb={3}
            gap={3}
          >
            {formik.values?.jobLetter?.id == 2 &&
              formik.values?.salary?.id !== 4 && (
                <>
                  <Input
                    Lable='Enter your employer’s email address'
                    name='email'
                    value={formik.values.email}
                    error={formik.touched.email && formik.errors.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='mt-2'
                  />

                  <Input
                    Lable='Confirm your employer’s email address'
                    name='confirm_email'
                    error={
                      formik.touched.confirm_email &&
                      formik.errors.confirm_email
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirm_email}
                    className='mt-2'
                  />
                </>
              )}
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
            mt={3}
            alignItems='end'
            mb={3}
          >
            {/* {formik.values?.jobLetter?.id == 3 &&
              formik.values?.salary?.id !== 4 && (
                <Stack width={{ xs: '100%', sm: '50%' }}>
                  <Button
                    Title="Please upload your job letter"
                    variant="h7"
                    className={classes.UploadFiles}
                    endIcon={<AttachFileIcon />}
                    onClick={onButton2Clicked}
                  />
                  <Field name="File2">
                    {({ field, form, meta }) => (
                      <>
                        <input
                          {...field}
                          type="File"
                          accept="application/pdf,image/*"
                          onChange={(event) => {
                            form.setFieldValue(
                              'File2',
                              event.currentTarget.files[0]
                            )
                          }}
                          value=""
                          style={{ display: 'none' }}
                          ref={inputFile2}
                          error={meta.touched && meta.error}
                        />
                        <Typography className={classes.UploadFileText}>
                          Only pdf documents are permitted with a maximum of 2MB
                        </Typography>
                        {meta.error ? (
                          <p
                            className={classes.fileText}
                            style={{ color: 'red' }}
                          >
                            {meta.error}
                          </p>
                        ) : (
                          meta?.value?.name && (
                            <p className={classes.uploadFileName}>
                              {`${String(meta.value.name).substring(0, 20)}...`}
                              <span>
                                <ClearIcon
                                  className={classes.deleteFileIcon}
                                  onClick={() => {
                                    formik.setFieldValue(field.name, null)
                                  }}
                                />
                              </span>
                            </p>
                          )
                        )}
                      </>
                    )}
                  </Field>
                </Stack>
              )} */}
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
            mt={3}
            alignItems='end'
            mb={3}
          >
            {formik.values?.jobLetter?.id &&
              formik.values?.jobLetter?.id != 1 &&
              formik.values?.salary?.id !== 4 && (
                <Alert
                  icon={false}
                  sx={{ backgroundColor: '#F4EBFE', padding: '20px' }}
                >
                  Dear {sessionStorage.getItem('name')}, <br />
                  Please note that your loan cannot be approved without a valid
                  employment letter (issued within the last 6 months). Kindly
                  request your Employer to issue the letter promptly;
                  thereafter, please submit this document via Belize Bank’s
                  online loan application.
                </Alert>
              )}
          </Stack>
        </Box>
      </FormikProvider>
      <ArrowButton
        showBtnNext={showNext}
        back={
          sessionStorage.getItem('loan') === 'secure' &&
          sessionStorage.getItem('warrantyTypeID') !== '1'
            ? '/Customer/MonthlyCreditObligations'
            : '/Customer/LoanPurpose'
        }
        onClick={() => {
          AxiosInterceptor.post('/customers/step', {
            currentStep:
              sessionStorage.getItem('loan') === 'secure' &&
              (sessionStorage.getItem('warrantyTypeID') === '1' ||
                sessionStorage.getItem('warrantyTypeID') === '2' ||
                sessionStorage.getItem('warrantyTypeID') === '3')
                ? 6
                : 7
          })
            .then((response) => {
              console.log(response)
            })
            .catch((e) => console.log(e))
          formik.submitForm()
        }}
        msg='nose'
      />
    </Box>
  )
}

export default ProofOfIncome
