import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../auth/jwt/Jwt'

export const hasDeleteLoanRequest = createAsyncThunk(
  'appDeleteLoanRequest/DeleteLoanRequest',
  async () => {
    const response = await Jwt.deleteLoanRequest()
    return response.data
  }
)

const initialState = {
  message: ''
}

export const appDeleteLoanRequestSlice = createSlice({
  name: 'appDeleteLoanRequest',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hasDeleteLoanRequest.fulfilled, (state, action) => {
      state.message = action.payload.message
    })
  }
})

// export const { handleGetBrands } = appDeleteLoanRequestSlice.actions

export default appDeleteLoanRequestSlice.reducer
