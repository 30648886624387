import Actions from '../Types'

const initialState = {
  branch: '',
  date: '',
  time: '',
  dataSchedule: {},
}

const ScheduleRequestReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.ScheduleRequest:
      return {
        ...state,
      }

    case Actions.SCHEDULELOANDREQUEST:
      return {
        ...state,
        dataSchedule: { ...payload },
      }

    default:
      return state
  }
}

export default ScheduleRequestReducer
