import { useEffect, useRef } from 'react'
import GetStatuses from '../../Store/Actions/GetStatuses'
import { useDispatch, useSelector } from 'react-redux'
import AcordionDisbursed from './AcordionDisbursed'
import AcordionDeclined from './AcordionDeclined'

const CompletedLoan = () => {
  // const dispatch = useDispatch()

  const { secure, unsecure, debtrefinancing } = useSelector((state) => state.StatusesReducer);


  return (
    <>
      {
        (secure.completed || unsecure.completed || debtrefinancing.completed) && (
          <>
          {secure.completed.map((loan, i) => (
            // <pre key={i}>{}</pre>
            <div key={i} style={{marginBottom: 22}}>
              {loan.status === 8 && <AcordionDisbursed Title={'Disbursed'} data={loan} />}
              {loan.status === 4 && <AcordionDeclined Title={'Declined'} data={loan} />}
              {loan.status === 9 && <AcordionDisbursed Title={'Cancel'} data={loan} />}
              {loan.status === 14 && <AcordionDeclined Title={'Declined by Customer'} data={loan} />}
            </div>
          ))}
          {unsecure.completed.map((loan, i) => (
            // <pre key={i}>{}</pre>
            <div key={i} style={{marginBottom: 22}}>
              {loan.status === 8 && <AcordionDisbursed Title={'Disbursed'} data={loan} />}
              {loan.status === 4 && <AcordionDeclined Title={'Declined'} data={loan} />}
              {loan.status === 9 && <AcordionDisbursed Title={'Cancel'} data={loan} />}
            </div>
          ))}

          {debtrefinancing.completed.map((loan, i) => (
            // <pre key={i}>{}</pre>
            <div key={i} style={{marginBottom: 22}}>
              {loan.status === 8 && <AcordionDisbursed Title={'Disbursed'} data={loan} />}
              {loan.status === 4 && <AcordionDeclined Title={'Declined'} data={loan} />}
              {loan.status === 9 && <AcordionDisbursed Title={'Cancel'} data={loan} />}
            </div>
          ))}
        </>
        )
      }
    </>
  )
}

export default CompletedLoan
