import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  Modal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '500px',
    padding: '1rem',
    height: '100%',
    gap: '2rem',
    textAlign: 'center',
    '@media (max-width: 600px)': {
      width: '300px'
    }
  },
  TextFieldStyles: {
    '& .MuiInputBase-input': {
      fontSize: '0.9rem !important',
      padding: '0.7rem 1rem !important',
      marginTop: 0
    }
  },
  ScheduleAppointmentContainer: {
    width: '100%'
  },
  Heading: {
    '&.MuiTypography-root': {
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '18px',
      letterSpacing: '0.1em',
      fontFamily: theme.fonts.family.secondary,
      marginBottom: '31px',
      color: theme.colors.primary.Grey
    }
  },
  UploadFiles: {
    '&.MuiButtonBase-root': {
      width: '100%',
      justifyContent: 'space-between',
      height: '43px',
      border: ' 1px solid #DADADA',
      color: '#B2B2B2',
      fontSize: '13px',
      paddingLeft: '30px',
      fontWeight: '500',
      letterSpacing: '0.1em',
      lineHeight: '17.76px',
      fontFamily: theme.fonts.family.tertiary
    },
    '@media (max-width: 600px)': {
      fontSize: '11px !important',
      height: 'auto !important'
    }
  },
  SubmitBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '100px',
    '& .MuiButtonBase-root': {
      width: '176px',
      height: '39px',
      backgroundColor: '#005FAA',
      color: '#ffff',
      '&:hover': {
        backgroundColor: theme.colors.secondary.Green
      }
    },
    '& .MuiTypography-root': {
      fontSize: '13px',
      fontWeight: '600',
      lineHeight: '15.51px',
      letterSpacing: '0.2em',
      fontFamily: theme.fonts.family.primary
    }
  },
  UploadFileText: {
    '&.MuiTypography-root': {
      marginTop: '5px',
      color: '#706F6F',
      fontSize: '13px',
      fontWeight: '400',
      lineHeight: '18px',
      fontFamily: theme.fonts.family.tertiary
    }
  },
  uploadFileName: {
    fontFamily: theme.fonts.family.secondary,
    fontSize: 15,
    fontWeight: 400,
    color: theme.colors.secondary.Green,
    // width:500,
    marginTop: 10,
    marginBottom: 15
  },
  deleteFileIcon: {
    '&.MuiSvgIcon-root': {
      color: ' red',
      marginTop: 0,
      fontSize: 'large',
      marginLeft: 10,
      position: 'absolute'
    }
  },
  FileInput: {
    '& .MuiInputLabel-root': {
      fontSize: '13px',
      fontWeight: '500',
      lineHeight: '17.76px',
      letterSpacing: '0.1em',
      color: '#706F6F'
    }
  },

  PlusIcon: {
    '&.MuiSvgIcon-root': {
      color: '#F07F3C',
      width: '21px',
      height: '21px'
    }
  },
  RemoveIcon: {
    '&.MuiSvgIcon-root': {
      width: '21px',
      height: '21px',
      color: '#F07F3C'
    }
  },
  UploadLabel: {
    color: '#706F6F',
    fontSize: '14px',
    fontFamily: 'avenirLight !important',
    // fontWeight: '500',
    fontWeight: '700',
    lineHeight: '20px',
    textTransform: 'uppercase'
  },
  Mt16: {
    marginTop: '16px'
  },
  AlertVehicleUpload: {
    marginBottom: '5%',
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    background: theme.colors.primary.lightPink,
    borderRadius: '0.3125rem',
    fontFamily: theme.fonts.family.primary,
    color: theme.colors.primary.Grey,
    padding: '1.5rem'
  },
  FullButton: {
    width: '100%',
    backgroundColor: '#275da6 !important',
    height: '50px',
    color: 'white !important',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: theme.fonts.family.primary,
    fontSize: '1.5rem'
  },
  UploadLabelVehicle: {
    color: '#706F6F',
    fontSize: '14px',
    fontFamily: 'avenirLight !important',
    fontWeight: '700',
    lineHeight: '20px',
    textTransform: 'uppercase',
    marginBottom: '.5rem !important',
    display: 'block'
  },
  RowFields: {
    marginTop: '.5rem !important',
    marginBottom: '1rem !important'
  },
  tooltip: {
    position: "relative", // Agrega posición relativa para el seudoelemento
    backgroundColor: "#fff !important",
    color: "#275DA6 !important",
    maxWidth: 261,
    left: "100%",
    top: '10%',
    textTransform: "none !important",
    fontSize: "14px !important",
    boxShadow: theme.shadows[1],
    border: "1px solid #275DA6 !important",
    borderRadius: "10px",
    padding: "10px",
    textAlign: "center",
    "& .MuiTooltip-arrow": {
      display: "none", // Oculta la flecha por defecto
    },

    "@media (max-width: 600px)": {
      left: "15%",
    },

    // Agrega un triángulo pseudo-elemento
    "&::before": {
      content: '""',
      position: "absolute",
      top: "25%", // Ajusta la posición vertical
      left: "-3%", // Alinea el triángulo en el centro
      transform: "translateX(-50%)", // Centra horizontalmente
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid #275DA6",
      borderBottom: "10px solid transparent",
      borderTop: "10px solid transparent",
    },
  },
}))
