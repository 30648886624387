import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { useStyles } from './LoanApprovedStyle'
import Approved from '../../../assets/Images/Grupo 999.svg'
import { Typography, InputLabel } from '@material-ui/core'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Radio from '@mui/material/Radio'
import Button from '../../../Components/Button/Button'
import img from '../../../assets/Images/pdf.svg'
import Input from '../../Inputs/Input'
import Alert from '@mui/material/Alert'
import { Interceptor } from '../../../Utils/Interceptor'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Actions from '../../../Store/Types'
import toast, { Toaster } from 'react-hot-toast'
import ModalRefFriend from './ModalRefFriend'
import { useMovil } from '../../../hooks/useMovil'
import AlertInfo from '../../Alert'

const ScheduleApproved = () => {
  const AxiosInterceptor = Interceptor()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { hasDownload, message } = useMovil('signdocs')
  const [signAction, setSignAction] = useState(false)
  const [customerName, setCustomerName] = useState(false)
  const [updateInfo, setUpdateInfo] = useState(false)
  const [noDocument, setNoDocument] = useState(false)
  const [openModal, setShowReferFriend] = useState(false)
  const [customerResponse, setCustomerResponse] = useState({})

  const [readDocuments, setReadDocuments] = useState({
    loanApplicationDeclaration: false,
    debtCancellationScheme: false,
    promissoryNote: false,
    disclosureSummary: false,
    cashCollateral: false
  })
  const [clickReadDocuments, setClickReadDocuments] = useState({
    loanApplicationDeclaration: false,
    debtCancellationScheme: false,
    promissoryNote: false,
    disclosureSummary: false,
    cashCollateral: false
  })
  const [docs, setDocs] = useState({
    loanApplicationDeclaration: '',
    hasLoanApplicationDeclaration: false,
    hasLoan: false,
    debtCancellationScheme: '',
    hasDebtCancellationScheme: false,
    hasDebtCancellation: false,
    promissoryNote: '',
    hasPromissoryNote: false,
    hasPromissory: false,
    disclosureSummary: '',
    hasDisclosureSummary: false,
    hasDisclosure: false,
    customerName: '',
    customerId: '',
    cashCollateralAgreement: '',
    hasCashCollateralAgreement: false
  })
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const amountUrl = +params.get('amount')
  const endpoint = '/customer-banking-docs/customer'

  const redirect = (url) => navigate(url)

  // const isStatusValid = (arr, status) => {
  //   return arr.includes(status)
  // }

  useEffect(() => {
    dispatch({ type: Actions.LOADING, payload: true })
    AxiosInterceptor.get(endpoint)
      .then((response) => {
        const status = response?.data?.status
        const amount = response?.data?.loanAmount
        if(sessionStorage.getItem('loan') === 'secure' && sessionStorage.getItem('warrantyType') === 'cash'){
          if (
            !response.data?.loanApplicationDeclaration ||
            !response.data?.debtCancellationScheme ||
            !response.data?.promissoryNote ||
            !response?.data?.disclosureSummary ||
            !response?.data?.cashCollateralAgreement
          ) {
            setNoDocument(true)
            return
          }
        }
        if (
          !response.data?.loanApplicationDeclaration ||
          !response.data?.debtCancellationScheme ||
          !response.data?.promissoryNote ||
          !response?.data?.disclosureSummary
        ) {
          setNoDocument(true)
          return
        }
        if (status === 3 && amount > 7000) {
          redirect('/Customer/ScheduleAppointment')
          return
        }
        if (status === 5 && amount > 7000) {
          redirect('/Customer/ScheduleAppointment')
          return
        }
        if (!isStatusValid([3, 5, 6], status)) {
          redirect('/LoanHistory')
          return
        }
        setDocs({ ...docs, ...response.data })
        // setBelow7k(true)
        // setShowReferFriend(true)
      })
      .catch(() => {
        setNoDocument(true)
        // setShowReferFriend(true)
      })
      .finally(() => dispatch({ type: Actions.LOADING, payload: false }))
  }, [updateInfo])

  const isBelow7k = (amount) => {
    return amount <= 7000
  }

  const isStatusValid = (arr, status) => {
    return arr.includes(status)
  }

  const isAgreements = () => {
    let agree = false
    if (
      sessionStorage.getItem('loan') === 'secure' && sessionStorage.getItem('warrantyType') === 'cash' &&
      docs?.hasLoan &&
      docs?.hasDisclosure &&
      docs?.hasPromissory &&
      docs?.hasDebtCancellation &&
      docs?.hasCashCollateralAgreement
    ) {
      agree = true
    } else if (
      docs?.hasLoan &&
      docs?.hasDisclosure &&
      docs?.hasPromissory &&
      docs?.hasDebtCancellation
    ) {
      agree = true
    }

    return agree
  }

  const handleClick = () => {
    if (isAgreements()) setSignAction(true)
    else
      toast.error(
        'Please accept that you read and understood all the documents'
      )
  }

  const [pendingSign, setPendingSign] = useState(false)

  const submitData = () => {
    if (!customerName) return toast.error('Full Name is required')
    const sentData = {
      hasLoan: docs.hasLoan,
      hasDebtCancellation: docs.hasDebtCancellation,
      hasPromissory: docs.hasPromissory,
      hasDisclosure: docs.hasDisclosure,
      hasCashCollateral: docs.hasCashCollateralAgreement,
      customerName
    }
    setPendingSign(true)
    dispatch({ type: Actions.LOADING, payload: true })
    AxiosInterceptor.post('/customer-banking-docs/customer', {
      ...sentData
    }).finally(() => {
      dispatch({ type: Actions.LOADING, payload: false })
      setUpdateInfo(true)
      setPendingSign(false)
    })
  }

  const handleOpenModal = () => {
    // console.log('hola')
    setShowReferFriend(true)
  }

  const classes = useStyles()

  console.log(amountUrl)

  React.useEffect(() => {
    if (amountUrl <= 7000 && noDocument === true) redirect('/customer/approved-below-7k')
  }, [amountUrl, noDocument])
  return (
    <Stack className={classes.container}>
      <Toaster />
      <ModalRefFriend
        open={openModal}
        setOpen={setShowReferFriend}
        link={`/?q=${sessionStorage.getItem('code')}`}
      />
      {/* // ! BELOW 7,000 */}
      {/* {amountUrl <= 7000 && noDocument === true && (
        <Box className={classes.ScheduleAppointmentContainer}>
          <Box className={classes.ApprovedPoppup}>
            <Box className={classes.ApprovedPoppupImage}>
              <img className={classes.ApprovedImage} src={Approved} alt='' />
            </Box>
            <Typography className={classes.ApprovedText}>
              <strong> Your approved facility is below BZ$7,000.</strong>
              <br />
              You can sign all loan documents digitally. To sign the loan
              agreements, please wait for us to upload the documents, we will
              contact you very soon.
            </Typography>
          </Box>
        </Box>
      )} */}

      {/* // *ANULAR EN CASO NO SEA SOLO PARA MENORES DE 7K */}
      {isBelow7k(docs?.loanAmount) && (
        <>
          {isBelow7k(docs?.loanAmount) &&
          isStatusValid([3, 5], docs?.status) ? (
            <Box className={classes.ScheduleAppointmentContainer}>
              <Box className={classes.ApprovedPoppup}>
                <Box className={classes.ApprovedPoppupImage}>
                  <img
                    className={classes.ApprovedImage}
                    src={Approved}
                    alt=''
                  />
                </Box>
                <Typography className={classes.ApprovedText}>
                  <strong> Your approved facility is below BZ$7,000</strong>
                  <br />
                  You can sign all loan documents digitally. To sign the loan
                  agreements, please read and accept the terms below. There is{' '}
                  <strong>NO</strong> need for you to visit a branch.
                </Typography>
              </Box>
            </Box>
          ) : null}
          {/* // ! LOAN AGREEMENTS */}
          {isStatusValid([3, 5, 6], docs?.status) && (
            <Stack
              className={classes.LoanCalculatorHeader}
              style={{ width: '75%' }}
            >
              <Typography className={classes.CalculatorHeaderText}>
                LOAN AGREEMENTS
              </Typography>
            </Stack>
          )}
          <Stack className={classes.mobielView} mt={4}>
            {/* // ! LOAN APPLICATION DECLARATION */}
            {docs?.loanApplicationDeclaration &&
            isStatusValid([3, 5, 6], docs?.status) ? (
              <Accordion elevation={0} className={classes.Accordian}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant='body2'
                    className={classes.AccordianHeader}
                  >
                    LOAN APPLICATION DECLARATION
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ backgroundColor: '#F4EBFE', padding: 10 }}
                  mt={3}
                >
                  <Typography mb={2}>
                    I warrant and confirm that all information given by me to
                    The Belize Bank Limited (the “Bank”) in relation to my loan
                    application including, but not limited to the information
                    contained in the Personal Lending Disclosure Summary, is
                    true and correct. In the event that I make any future loan
                    application(s), I undertake to update all previously
                    provided information or to provide the Bank with true and
                    accurate original information to facilitate the completion
                    of the application. I understand that all information given
                    in support of this loan application and any subsequent loan
                    application(s) will be used by the Bank to assess my credit
                    worthiness and to determine whether to grant any credit
                    facilities to me. I therefore confirm that I have not
                    provided any false or misleading information and that I have
                    not withheld and will not withhold any information that
                    might negatively affect the Bank’s decision to grant any
                    credit facility to me.
                  </Typography>
                  <Typography>
                    I agree that if at any time I provide or have provided the
                    Bank with false or misleading information or withhold or
                    have withheld any information, the Bank will have the right
                    to reject any application or terminate my credit facility.
                  </Typography>
                  <Typography>
                    I authorize and grant permission to the Bank to request, to
                    obtain, to receive, to collect and to store any information
                    it may require on me for the purpose of granting any credit
                    facility to me or for complying with the laws of Belize. I
                    further authorize the Bank to disclose any such information,
                    which it may have in its possession. I acknowledge and agree
                    that the authority to disclose my information will include,
                    but not be limited to disclosure to any of the Bank’s
                    employees, agents, third parties, assignees, other financial
                    institutions, my current and former employers, and any other
                    persons, natural or legal, with whom I have financial
                    dealings from time to time or as required by law.
                  </Typography>
                  <Typography>
                    I agree that I will not, without reasonable excuse or undue
                    delay, refuse to provide the Bank with updated information
                    required but the Bank from time to time.
                  </Typography>
                  <Typography>
                    I understand that the execution of this Declaration does not
                    guarantee that my loan application will be approved by the
                    Bank or that the Bank will grant any credit facility to me
                    and that any credit extended or funds advanced to me, now or
                    in the future, will be subject to the terms and conditions
                    of the relevant loan agreement with the Bank.
                  </Typography>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    {hasDownload && (
                      <>
                        <img src={img} className={classes.pdf} alt='' />
                        <Typography
                          variant='body2'
                          className={classes.fileText}
                        >
                          <a
                            href={docs?.loanApplicationDeclaration}
                            download='LOAN APPLICATION DECLARATION'
                            onClick={() =>
                              setReadDocuments((odd) => {
                                return {
                                  ...odd,
                                  loanApplicationDeclaration: true
                                }
                              })
                            }
                          >
                            Document.pdf
                          </a>
                        </Typography>
                      </>
                    )}
                    {!hasDownload && <AlertInfo content={message} />}
                  </Stack>
                  <Stack
                    className={classes.RadioBtn}
                    direction='row'
                    alignItems='center'
                    mt={4}
                    onClick={() => {
                      setClickReadDocuments((odd) => {
                        return {
                          ...odd,
                          loanApplicationDeclaration: true
                        }
                      })
                    }}
                  >
                    <Radio
                      id='hasLoan'
                      name='hasLoan'
                      disabled={
                        docs?.hasLoanApplicationDeclaration
                          ? docs?.hasLoanApplicationDeclaration
                          : !readDocuments.loanApplicationDeclaration
                      }
                      onChange={() => setDocs({ ...docs, hasLoan: true })}
                      checked={
                        docs?.hasLoanApplicationDeclaration
                          ? docs?.hasLoanApplicationDeclaration
                          : docs?.hasLoan
                      }
                      // onClick={() => setAgreements(!agreements?.hasLoan)}
                    />
                    <InputLabel className={classes.RadioLabel} for='hasLoan'>
                      ​I acknowledge that I have read and understand the
                      document
                    </InputLabel>
                  </Stack>
                  <br />
                  {readDocuments.loanApplicationDeclaration !== true &&
                    clickReadDocuments.loanApplicationDeclaration === true &&
                    !docs?.hasLoanApplicationDeclaration && (
                      <span className={classes.errorMessage}>
                        Please download and read the document first
                      </span>
                    )}
                </AccordionDetails>
              </Accordion>
            ) : null}
            {/* // ! DEBT CANCELLATION SCHEME */}
            {docs?.debtCancellationScheme && (
              <Accordion elevation={0} className={classes.Accordian}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant='body2'
                    className={classes.AccordianHeader}
                  >
                    DEBT CANCELLATION SCHEME
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ backgroundColor: '#F4EBFE', padding: 10 }}
                  mt={3}
                >
                  <Typography mb={2}>
                    I hereby consent to you establishing a policy under the
                    Belize Bank Personal Demand Loan Debt Cancellation Scheme,
                    with loss payable, if any, to The Belize Bank Limited (the
                    “Bank”). The Debt Cancellation Scheme coverage is to be
                    effective as from the date on which the loan is advanced and
                    on such terms and conditions as may be agreed between I and
                    the Bank, but coverage is not to exceed the principal amount
                    of the Personal Demand Loan.
                  </Typography>
                  <Typography>
                    I certify that I am not undergoing any medical treatment and
                    within the past twelve (12) months, I have not been
                    hospitalized. I further confirm that I am not suffering from
                    any serious ailment; or
                  </Typography>
                  <Typography>
                    I certify that in the past twelve (12) months I have sought
                    medical treatment/obtained medical treatment
                  </Typography>
                  <Typography>
                    I acknowledge, by signing this consent and declaration that
                    the Bank is not liable to payout my debt upon my death if I
                    knowingly misinformed the Bank. I further acknowledge that
                    the Bank is not liable to pay off my debt of my loan is in
                    arrears for ninety (90) days or more.
                  </Typography>
                  <Typography>Dated this day</Typography>
                  <Typography>
                    I acknowledge receipt of a copy of the Loan Application
                    Declaration and Debt Cancellation Scheme Application (the
                    “Forms”) prior to consummation of the credit transaction.
                  </Typography>
                  <Typography>
                    I hereby confirm that I have read and reviewed the Forms and
                    in consideration of the Bank accepting my Forms and
                    considering my credit application, I consent to any and all
                    matters requiring my consent or approval therein and I
                    attach my signature hereto to evidence said approval.
                  </Typography>

                  <Stack direction='row' spacing={2} alignItems='center'>
                    {hasDownload && (
                      <>
                        <img src={img} className={classes.pdf} alt='' />
                        <Typography
                          variant='body2'
                          className={classes.fileText}
                        >
                          <a
                            href={docs?.debtCancellationScheme}
                            download='DEBT CANCELLATION SCHEME'
                            onClick={() =>
                              setReadDocuments((odd) => {
                                return {
                                  ...odd,
                                  debtCancellationScheme: true
                                }
                              })
                            }
                          >
                            Document.pdf
                          </a>
                        </Typography>
                      </>
                    )}
                    {!hasDownload && <AlertInfo content={message} />}
                  </Stack>
                  <Stack
                    className={classes.RadioBtn}
                    direction='row'
                    alignItems='center'
                    mt={4}
                    onClick={() => {
                      setClickReadDocuments((odd) => {
                        return {
                          ...odd,
                          debtCancellationScheme: true
                        }
                      })
                    }}
                  >
                    <Radio
                      id='hasDebtCancellation'
                      name='hasDebtCancellation'
                      disabled={
                        docs?.hasDebtCancellationScheme
                          ? docs?.hasDebtCancellationScheme
                          : !readDocuments.debtCancellationScheme
                      }
                      checked={
                        docs?.hasDebtCancellationScheme
                          ? docs?.hasDebtCancellationScheme
                          : docs?.hasDebtCancellation
                      }
                      onChange={() =>
                        setDocs({ ...docs, hasDebtCancellation: true })
                      }
                      // onClick={() => setAgreements(!agreements.hasDebtCancellation)}
                    />

                    <InputLabel
                      className={classes.RadioLabel}
                      for='hasDebtCancellation'
                    >
                      ​I acknowledge that I have read and understand the
                      document
                    </InputLabel>
                  </Stack>
                  <br />
                  {readDocuments.debtCancellationScheme !== true &&
                    clickReadDocuments.debtCancellationScheme === true &&
                    !docs?.hasDebtCancellationScheme && (
                      <span className={classes.errorMessage}>
                        Please download and read the document first
                      </span>
                    )}
                </AccordionDetails>
              </Accordion>
            )}
            {/* // ! PROMISSORY NOTE */}
            {docs?.promissoryNote && (
              <Accordion elevation={0} className={classes.Accordian}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant='body2'
                    className={classes.AccordianHeader}
                  >
                    PROMISSORY NOTE
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ backgroundColor: '#F4EBFE', padding: 10 }}
                  mt={3}
                >
                  <Stack direction='row' spacing={2} alignItems='center'>
                    {hasDownload && (
                      <>
                        <img src={img} className={classes.pdf} alt='' />
                        <Typography
                          variant='body2'
                          className={classes.fileText}
                        >
                          <a
                            href={docs?.promissoryNote}
                            download='PROMISSORY NOTE'
                            onClick={() =>
                              setReadDocuments((odd) => {
                                return {
                                  ...odd,
                                  promissoryNote: true
                                }
                              })
                            }
                          >
                            Document.pdf
                          </a>
                        </Typography>
                      </>
                    )}
                    {!hasDownload && <AlertInfo content={message} />}
                  </Stack>
                  <Stack
                    className={classes.RadioBtn}
                    direction='row'
                    alignItems='center'
                    mt={4}
                    onClick={() => {
                      setClickReadDocuments((odd) => {
                        return {
                          ...odd,
                          promissoryNote: true
                        }
                      })
                    }}
                  >
                    <Radio
                      id='hasPromissory'
                      name='hasPromissory'
                      disabled={
                        docs?.hasPromissoryNote
                          ? docs?.hasPromissoryNote
                          : !readDocuments.promissoryNote
                      }
                      checked={
                        docs?.hasPromissoryNote
                          ? docs?.hasPromissoryNote
                          : docs?.hasPromissory
                      }
                      onChange={() => setDocs({ ...docs, hasPromissory: true })}
                    />

                    <InputLabel
                      className={classes.RadioLabel}
                      for='hasPromissory'
                    >
                      ​I acknowledge that I have read and understand the
                      document
                    </InputLabel>
                  </Stack>
                  <br />
                  {readDocuments.promissoryNote !== true &&
                    clickReadDocuments.promissoryNote === true &&
                    !docs?.hasPromissoryNote && (
                      <span className={classes.errorMessage}>
                        Please download and read the document first
                      </span>
                    )}
                </AccordionDetails>
              </Accordion>
            )}
            {/* // ! CASH COLLATERAL */}
            {docs?.cashCollateralAgreement && (
              <Accordion elevation={0} className={classes.Accordian}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant='body2'
                    className={classes.AccordianHeader}
                  >
                    CASH COLLATERAL AGREEMENT
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ backgroundColor: '#F4EBFE', padding: 10 }}
                  mt={3}
                >
                  <Stack direction='row' spacing={2} alignItems='center'>
                    {hasDownload && (
                      <>
                        <img src={img} className={classes.pdf} alt='' />
                        <Typography
                          variant='body2'
                          className={classes.fileText}
                        >
                          <a
                            href={docs?.cashCollateralAgreement}
                            download='CASH COLLATERAL'
                            onClick={() =>
                              setReadDocuments((odd) => {
                                return {
                                  ...odd,
                                  cashCollateral: true
                                }
                              })
                            }
                          >
                            Document.pdf
                          </a>
                        </Typography>
                      </>
                    )}
                    {!hasDownload && <AlertInfo content={message} />}
                  </Stack>
                  <Stack
                    className={classes.RadioBtn}
                    direction='row'
                    alignItems='center'
                    mt={4}
                    onClick={() => {
                      setClickReadDocuments((odd) => {
                        return {
                          ...odd,
                          cashCollateral: true
                        }
                      })
                    }}
                  >
                    <Radio
                      id='hasCollateralAgreement'
                      name='hasCollateralAgreement'
                      disabled={
                        docs?.hasCashCollateralAgreement
                          ? docs?.hasCashCollateralAgreement
                          : !readDocuments.cashCollateral
                      }
                      checked={
                        docs?.hasCashCollateralAgreement
                          ? docs?.hasCashCollateralAgreement
                          : docs?.hasCashCollateralAgreement
                      }
                      onChange={() =>
                        setDocs({ ...docs, hasCashCollateralAgreement: true })
                      }
                    />

                    <InputLabel
                      className={classes.RadioLabel}
                      for='hasCollateralAgreement'
                    >
                      ​I acknowledge that I have read and understand the
                      document
                    </InputLabel>
                  </Stack>
                  <br />
                  {readDocuments.cashCollateral !== true &&
                    clickReadDocuments.cashCollateral === true &&
                    !docs?.hasCashCollateralAgreement && (
                      <span className={classes.errorMessage}>
                        Please download and read the document first
                      </span>
                    )}
                </AccordionDetails>
              </Accordion>
            )}
            {/* // ! DISCLOSURE SUMMARY */}
            {docs?.disclosureSummary && (
              <Accordion elevation={0} className={classes.Accordian}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant='body2'
                    className={classes.AccordianHeader}
                  >
                    DISCLOSURE SUMMARY
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ backgroundColor: '#F4EBFE', padding: 10 }}
                  mt={3}
                >
                  <Stack direction='row' spacing={2} alignItems='center'>
                    {hasDownload && (
                      <>
                        <img src={img} className={classes.pdf} alt='' />
                        <Typography
                          variant='body2'
                          className={classes.fileText}
                        >
                          <a
                            href={docs?.disclosureSummary}
                            download='DISCLOSURE SUMMARY'
                            onClick={() =>
                              setReadDocuments((odd) => {
                                return {
                                  ...odd,
                                  disclosureSummary: true
                                }
                              })
                            }
                          >
                            Document.pdf
                          </a>
                        </Typography>
                      </>
                    )}
                    {!hasDownload && <AlertInfo content={message} />}
                  </Stack>
                  <Stack
                    className={classes.RadioBtn}
                    direction='row'
                    alignItems='center'
                    mt={4}
                    onClick={() => {
                      setClickReadDocuments((odd) => {
                        return {
                          ...odd,
                          disclosureSummary: true
                        }
                      })
                    }}
                  >
                    <Radio
                      id='hasDisclosure'
                      name='hasDisclosure'
                      disabled={
                        docs?.hasDisclosureSummary
                          ? docs?.hasDisclosureSummary
                          : !readDocuments.disclosureSummary
                      }
                      checked={
                        docs?.hasDisclosureSummary
                          ? docs?.hasDisclosureSummary
                          : docs?.hasDisclosure
                      }
                      onChange={() => setDocs({ ...docs, hasDisclosure: true })}
                      // onClick={() => setAgreements(!docs.hasDisclosure)}
                    />

                    <InputLabel
                      className={classes.RadioLabel}
                      for='hasDisclosure'
                    >
                      ​I acknowledge that I have read and understand the
                      document
                    </InputLabel>
                  </Stack>
                  <br />
                  {readDocuments.disclosureSummary !== true &&
                    clickReadDocuments.disclosureSummary === true &&
                    !docs?.hasDisclosureSummary && (
                      <span className={classes.errorMessage}>
                        Please download and read the document first
                      </span>
                    )}
                </AccordionDetails>
              </Accordion>
            )}

            {/* // * BTN(SIGN) active to [aproved, pending signature]  */}
            {isStatusValid([3, 5], docs?.status) && !signAction ? (
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
                justifyContent='center'
                alignItems='center'
                mt={3}
                mb={4}
              >
                <Button
                  Title='SIGN'
                  variant='h7'
                  // disabled={!isAgreements()}
                  className={classes.NextButton}
                  BtnVariant='contained'
                  onClick={handleClick}
                />
              </Stack>
            ) : null}
            {/* // * FIELD (FullName) */}
            {isStatusValid([3, 5], docs?.status) && signAction ? (
              <Stack direction='column' mt={3} mb={4}>
                <Input
                  Lable='ENTER YOUR FULL NAME.'
                  name='customerName'
                  placeholder='Full Name'
                  required
                  onChange={(val) => setCustomerName(val.target.value)}
                />
              </Stack>
            ) : null}
            {/* // * BTN CONTINUE */}
            {isStatusValid([3, 5], docs?.status) && signAction ? (
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
                justifyContent='center'
                alignItems='center'
                mt={3}
                mb={4}
              >
                <Button
                  Title='CONTINUE'
                  variant='h7'
                  className={classes.NextButton}
                  BtnVariant='contained'
                  disabled={pendingSign}
                  onClick={submitData}
                />
              </Stack>
            ) : null}

            {/* //! */}
            {/* // * active to [Sign]  THANKS*/}
            {isStatusValid([6], docs?.status) ? (
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
                justifyContent='center'
                alignItems='center'
                mt={3}
                mb={4}
                style={{ textAlign: 'center', width: '100%' }}
              >
                <Alert
                  icon={false}
                  sx={{
                    backgroundColor: '#D3E4F6',
                    fontSize: '16px',
                    lineHeight: '27px',
                    padding: '20px',
                    width: '100%',
                    justifyContent: 'center'
                  }}
                >
                  <strong style={{ color: '#F07F3C' }}>
                    {' '}
                    Congratulations!
                  </strong>{' '}
                  <br />
                  Your loan application process has been finalized. Our Team
                  will start the disbursement process. <br />
                  Thank you for giving us an opportunity to serve you. <br />
                  {/* <Link style={{ textDecoration: 'none' }} to="#"> */}
                  <Button
                    className={classes.ThankYouBtn}
                    Title='THANK YOU'
                    onClick={handleOpenModal}
                  />
                  {/* </Link> */}
                </Alert>
              </Stack>
            ) : null}
          </Stack>
        </>
      )}
      {!isStatusValid([3, 5, 6], docs?.status) && (
        <Button
        style={{ marginTop: '5rem', display: 'block' }}
        className={classes.ThankYouBtn}
        Title={'back'}
        onClick={() => navigate(-1)}
      />
      )}
      
    </Stack>
  )
}

export default ScheduleApproved
