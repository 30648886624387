import { baseUrl } from '../../baseUrl'



export const endpoints = {
  customer: `${baseUrl}/customer`,


  tokenKeyName: 'access_token',
  tokenType: 'Bearer',
  storageRefreshTokenKeyName: 'refreshToken',

  // ! Endpoints non-secured (START)
  assets: `${baseUrl}/assets`,
  assetsByCustomer: `${baseUrl}/customer-assets`,
  // ! non-secured (END)

}