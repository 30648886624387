import { Alert, Typography } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import Cross from "../../../assets/Images/Cross.png"
import Exclamation from '../../../assets/Images/exclamation.png'
import { Box } from "@mui/system"
import React, {useState} from "react"
import { useSelector, useDispatch } from "react-redux"
import Actions from "../../../Store/Types"
import { useStyles } from "./PoppupStyle"
import { Interceptor } from "../../../Utils/Interceptor"
import LoadingButton from "@mui/lab/LoadingButton"
import { useNavigate } from "react-router-dom"

const PopupAlertError = ({setconfirmEmail}) => {
  const EMAIL_RESPONSE = [
    "Code Valid",
    "Invalid code, please try again.",
    "Validation code expired",
    "A new code was sent, try again.",
    "You can proceed without the email validation; however, in doing so, you confirm that you are aware that important message from the Bank may not reach to you thus your loan application process might be extended."
  ]
  const navigate = useNavigate()
  const AxiosInterceptor = Interceptor()
  const classes = useStyles()
  const dispatch = useDispatch()
  const Error = useSelector((state) => state.OtpEmailReducer.showModal)
  const status = useSelector((state) => state.OtpEmailReducer.statusCode)
  const message = EMAIL_RESPONSE[status]
  const token = sessionStorage.getItem('code') || ''

  const [showLoading, setShowLoading] = useState(false)
  const sendData = async () => {
    const url = "/customers/confirmation/resend-code/email"
    return await AxiosInterceptor.post(url)
  }
  const sendDataConfirmationEmail = async () => {
    const url = "/customers/confirmation/email"
    return await AxiosInterceptor.post(url, {
      code: "string",
      token,
      continueWithoutValidation: true
    })
  }
  const handleReSend = async () => {
    setShowLoading(true)
    const send_data = await sendData()
   
    if(send_data.data.sent) {
      
      dispatch({
        type: Actions.EMAILRESPONSE,
        payload: 3,
      })
      dispatch({
        type: Actions.EMAILSHOWMODALERROR,
        payload: false,
      })
    }
    setShowLoading(false)
  }

  const handleWithoutValidation = async () => {
    setShowLoading(true);
    await sendDataConfirmationEmail();
    setShowLoading(false);
    dispatch({
      type: Actions.EMAILSHOWMODALERROR,
      payload: false,
    });
    setconfirmEmail(true);
    if (sessionStorage.getItem('loan') === 'unsecure') {
      // navigate('/Customer/yourLoanRequest')
      setconfirmEmail(true)
    } else {
      setconfirmEmail(true)
      // navigate('/Customer/loan-collateral')
    }
  }

  return (
    <Dialog
      className={classes.AlertBoxContainer}
      open={Error}
      aria-labelledby="draggable-dialog-title"
    >
      <Box className={classes.AlertBoxHeader}></Box>
      <Box className={classes.AlertMainSection}>
        <Box className={classes.HeaderLogoContainer} style={{filter: 'none'}}>
          <Box className={classes.HeaderLogoImage} style={{filter: 'none'}}>
            <img className={classes.CrossImage} src={Exclamation} alt="Cross" />
          </Box>
        </Box>
        <Typography className={classes.AlertText} style={{marginBottom: '1rem', textAlign: 'center'}}>{message}</Typography>
        <DialogActions style={{width: '60%'}}>
          {status !== 4 && (
              <>
                <Button
                    className={classes.AlertBtn}
                    autoFocus
                    onClick={() =>
                        dispatch({
                          type: Actions.EMAILSHOWMODALERROR,
                          payload: false,
                        })
                    }
                >
                  Re-Enter
                </Button>
                <LoadingButton
                    className={classes.AlertBtn}
                    loading={showLoading}
                    loadingPosition="end"
                    onClick={handleReSend}
                    autoFocus>
                  Re-Send
                </LoadingButton>
              </>
          )}
          {status === 4 && (
              <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '2rem'}}>
                <Button
                    style={{width: '100%', height: '40px'}}
                    className={classes.AlertBtnTry}
                    autoFocus
                    loading={showLoading}
                    loadingPosition="end"
                    onClick={handleReSend}
                >
                  Validate My email address
                </Button>
                <Button
                    style={{width: '100%', height: '40px'}}
                    className={classes.AlertBtnTry}
                    loadingPosition="end"
                    onClick={handleWithoutValidation}
                    autoFocus>
                  Proceed without validation
                </Button>
              </div>
          )}
    
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default PopupAlertError
