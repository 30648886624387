import React from 'react';

const Separator = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '1px', // Altura de la línea
        backgroundColor: '#00000033', // Color gris
        margin: '24px 0', // Margen superior e inferior para separar elementos
        
      }}
    />
  );
};

export default Separator;