import React, { useEffect, useState } from 'react'
import { Box, InputLabel, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import Input from '../../../../../../Components/Inputs/Input'
//import InputWithAdornment from '../../../../../../Inputs/InputWithAdornment'
import { useStyles } from './LoanCalculatorStyle'
import Recomend from '../../../../../../assets/Images/Recomend.png'
import Button from '../../../../../../Components/Button/Button'
import Radio from '@mui/material/Radio'
import { Link } from 'react-router-dom'
import Total from '../../../../../../Components/Inputs/Total'
import { useFormik } from 'formik'
import { loanRequest } from '../../../../../../Store/Actions/LoanRequest'
//import { PromoCode } from '../../Store/Actions/PromoCode'
import { useDispatch, useSelector } from 'react-redux'
import { TooltipMessage } from './TooltipMessage'
//import { InputAdornment } from '@mui/material'
import useDebounce from './useDebounce'
import InvalidPromoCode from './InvalidPromoCode'
import { SetValidPromoCode } from '../../../../../../Store/Actions/SetIsPromoValid'
import Alert from '@mui/material/Alert'
import Actions from '../../../../../../Store/Types'
import Appointments from '../appointments'

const LoanCalculator = ({
  amount,
  terms,
  setError,
  setErrorTerms,
  loanType,
  typeOfCollateral,
  cancellationSchemeRequested,
  landValue,
  typeOfLandTransfer,
  typeOfLoansRefSelected
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const loand_request = useSelector((state) => state.LoanRequestReducer)

  const [promoCode, setPromoCode] = useState(
    loand_request?.promoCode !== null ? true : false
  )
  const [promoCodeValue, setPromoCodeValue] = useState(loand_request?.promoCode)
  // const [promoCodeValue, setPromoCodeValue] = useState(
  //   loand_request.promoCode ? loand_request.promoCode : ''
  // )
  const [radioChecked, setRadioChecked] = useState(
    loand_request.debtCancellationSchemeRequested ?? null
  )
  // console.log('EL RADIO CHECK DE LOS TERMS', radioChecked)
  const [showPromoCode, setShowPromoCode] = useState(false)

  const [showModal, setShowModal] = useState(false)
  // const fees = useSelector((state) => state.LoanRequestReducer.fees)
  // const totalAmount = useSelector(
  //   (state) => state.LoanRequestReducer.totalAmount
  // )

  const debouncedPromoCode = useDebounce(promoCodeValue, 4000)

  // Obtener el valor de localStorage
  const warrantyTypeID = sessionStorage.getItem('warrantyTypeID')
  const loanTypeID = sessionStorage.getItem('loan')

  // Comparar el valor con '1' y asignar el resultado a una variable
  const isVehicle = warrantyTypeID === '2'

  // const { promoCode: pCode } = useSelector(
  //   (state) => state?.LoanRequestReducer?.promoCode
  // )

  const promoCodeData = useSelector(
    (state) => state?.LoanRequestReducer?.promoCode
  )

  if (promoCodeData !== null && promoCodeData !== undefined) {
    const { promoCode: pCode } = promoCodeData
  } else {
    const { promoCode: pCode } = ''
  }
  const monthlyInstallment = useSelector(
    (state) => state?.LoanRequestReducer?.monthlyInstallment
  )
  const currentMonthlyInstallment = useSelector(
    (state) => state?.LoanRequestReducer?.currentMonthlyInstallment
  )
  const monthlyInstallmentWithPromoCode = useSelector(
    (state) => state?.LoanRequestReducer?.monthlyInstallmentWithPromoCode
  )
  const debtCancellationSchemeFee = useSelector(
    (state) => state?.LoanRequestReducer.debtCancellationSchemeFee
  )
  const totalDebtCancellationSchemeFee = useSelector(
    (state) => state?.LoanRequestReducer.totalDebtCancellationSchemeFee
  )

  const debtCancellationSchemeRequested = useSelector(
    (state) => state?.LoanRequestReducer.debtCancellationSchemeRequested
  )

  const loanRate = useSelector(
    (state) => state?.LoanRequestReducer.interestRate
  )

  const isPromoValid = useSelector(
    (state) => state?.LoanRequestReducer?.isPromoValid
  )
  useEffect(() => {
    dispatch(SetValidPromoCode(true))
  }, [])

  useEffect(() => {
    if (isPromoValid !== null) {
      if (isPromoValid && isPromoValid.valid) {
        setShowModal(false)
        if (promoCode !== '') setShowPromoCode(true)
        else setShowPromoCode(false)
      } else {
        setShowModal(true)
        setShowPromoCode(false)
      }
    } else {
      setShowModal(false)
    }
  }, [isPromoValid])

  useEffect(() => {
    if (amount) {
      formik.setFieldValue('requestedAmount', amount)
    } else {
      formik.setFieldValue('requestedAmount', 0)
    }
    if (terms) {
      formik.setFieldValue('requestedTerms', terms)
    } else {
      formik.setFieldValue('requestedTerms', 0)
    }
    if (typeOfLandTransfer) {
      formik.setFieldValue('legalFeesTypeId', Number(typeOfLandTransfer))
    } else {
      formik.setFieldValue('legalFeesTypeId', 0)
    }

    if (amount >= 1000 && terms >= 12) {
      setError('')
      setErrorTerms('')
      formik.handleSubmit()
    } else {
      if (amount < 1000) {
        setError('must be greater than 1000')
      } else {
        setError('')
      }

      if (terms > 120) {
        setErrorTerms('Must be equal to or less than 120 months.')
      } else if (terms <= 120 && terms >= 12) {
        setErrorTerms('')
      }

      if (terms > 60 && loanTypeID === 'refinancing') {
        setErrorTerms('Must be equal to or less than 60 months.')
      } else if (terms <= 60 && terms >= 12) {
        setErrorTerms('')
      }

      if (terms < 12) {
        setErrorTerms('Must be equal to or greater than 12 months')
      } else if (loanType !== 'secure') {
        setErrorTerms('')
      }
    }
  }, [amount, terms, typeOfLandTransfer])

  useEffect(() => {
    if (typeof cancellationSchemeRequested === 'function') {
      cancellationSchemeRequested(radioChecked)
    }
  }, [radioChecked])

  const Monthly = monthlyInstallment

  const formik = useFormik({
    initialValues: {
      requestedAmount: amount,
      requestedTerms: terms,
      promoCode: promoCodeValue,
      interestRate: 1,
      debtCancellationSchemeRequested: radioChecked,
      landValue:
        warrantyTypeID === '3' && loanTypeID === 'land'
          ? Number(landValue)
          : null,
      legalFeesTypeId: Number(typeOfLandTransfer),
      token: sessionStorage.getItem('code')
    },

    onSubmit: (values) => {
      amount = +amount
      if (
        (amount >= 1000 && terms > 0 && warrantyTypeID !== '3') ||
        (amount >= 1000 &&
          terms > 0 &&
          loanTypeID === 'secure' &&
          warrantyTypeID === '3' &&
          Number(landValue !== null && Number(landValue >= Number(amount)))) ||
          (amount >= 1000 &&
            terms > 0 &&
            loanTypeID === 'refinancing' 
            // &&
            // warrantyTypeID === '3' &&
            // Number(landValue !== null && Number(landValue >= Number(amount)))
          )
      ) {
        // if (values.debtCancellationSchemeRequested !== null) {
          let formData = {
            requestedAmount: Number(amount),
            requestedTerms: Number(terms),
            promoCode: promoCodeValue,
            interestRate: 1,
            loanTypeId:
              sessionStorage.getItem('loan') === 'secure'
                ? 2
                : sessionStorage.getItem('loan') === 'refinancing'
                ? 3
                : 1,
            debtCancellationSchemeRequested:
              amount > 50000 ? false : radioChecked,
            warrantyTypeId: Number(warrantyTypeID),
            landValue: Number(landValue),
            // warrantyTypeID === '3' && loanTypeID === 'secure' && Number(landValue) > amount
            //   ? Number(landValue)
            //   : null,
            legalFeesTypeId: Number(typeOfLandTransfer),
            token: sessionStorage.getItem('code')
          }
          dispatch(loanRequest(formData))
          console.log("Sending request ...")
        // }
        //  else {
        //   let formData = {
        //     requestedAmount: Number(amount),
        //     requestedTerms: Number(terms),
        //     promoCode: promoCodeValue,
        //     interestRate: 1,
        //     loanTypeId:
        //       sessionStorage.getItem('loan') === 'secure'
        //         ? 2
        //         : sessionStorage.getItem('loan') === 'refinancing'
        //         ? 3
        //         : 1,
        //     debtCancellationSchemeRequested: false,
        //     warrantyTypeId: Number(warrantyTypeID),
        //     landValue:
        //       warrantyTypeID === '3' &&
        //       loanTypeID === 'secure' &&
        //       Number(landValue) > amount
        //         ? Number(landValue)
        //         : null,
        //     legalFeesTypeId: Number(typeOfLandTransfer),
        //     token: sessionStorage.getItem('code')
        //   }
        //   dispatch(loanRequest(formData))
        // }
      }
    }
  })
  const handleClick = () => {
    dispatch({ type: Actions.SETISVALIDPROMOCODE, payload: { valid: true } })
    setShowModal(false)
  }

  useEffect(() => {
    if (radioChecked !== null) {
      formik.setFieldValue('debtCancellationSchemeRequested', !radioChecked)
    }

    formik.handleSubmit()
  }, [radioChecked])

  useEffect(() => {
    formik.handleSubmit()
  }, [debouncedPromoCode, landValue])

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'BZD'
  })

  const [scheduleAppointment, setScheduleAppointment] = useState(false)

  useEffect(() => {
    if (amount > 50000) {
      setScheduleAppointment(true)
    }

    if (amount < 50000 && terms > 120) {
      setScheduleAppointment(true)
    }

    if (amount < 50000 && terms < 60) {
      setScheduleAppointment(true)
    }

    if (
      amount < 50000 &&
      terms > 60 &&
      loanTypeID === 'secure' &&
      warrantyTypeID === '2'
    ) {
      setScheduleAppointment(true)
    }

    if (
      amount > 50000 &&
      terms > 60 &&
      loanTypeID === 'secure' &&
      warrantyTypeID === '2'
    ) {
      setScheduleAppointment(true)
    }

    // validacion para loans land
    if (
      amount <= 150000 &&
      terms > 120 &&
      loanTypeID === 'secure' &&
      warrantyTypeID === '3'
    ) {
      setScheduleAppointment(true)
    }

    if (
      amount > 150000 &&
      terms < 120 &&
      loanTypeID === 'secure' &&
      warrantyTypeID === '3'
    ) {
      setScheduleAppointment(true)
    }

    if (
      amount > 150000 &&
      terms > 120 &&
      loanTypeID === 'secure' &&
      warrantyTypeID === '3'
    ) {
      setScheduleAppointment(true)
    }

    if (
      ((loand_request.totalLoanRequests > 50000 && terms < 60) ||
        (loand_request.totalLoanRequests < 50000 && terms > 60)) &&
      loanTypeID === 'refinancing'
    ) {
      setScheduleAppointment(true)
    }
  }, [amount, terms, typeOfLoansRefSelected])

  const [requiredAppointment, setRequiredAppointment] = useState({
    required: false,
    message: ''
  })

  useEffect(() => {
    if (
      amount > 50000 &&
      terms > 120 &&
      loanTypeID === 'secure' &&
      warrantyTypeID !== '2' &&
      loanTypeID === 'secure' &&
      warrantyTypeID !== '3'
    ) {
      setRequiredAppointment({
        required: true,
        message: '$50,000 and more than 120 months'
      })
      return
    } else if (
      amount <= 50000 &&
      terms > 120 &&
      loanTypeID === 'secure' &&
      warrantyTypeID !== '2' &&
      loanTypeID === 'secure' &&
      warrantyTypeID !== '3'
    ) {
      setRequiredAppointment({
        required: true,
        message: '120 months'
      })
      return
    } else if (
      amount > 50000 &&
      terms <= 120 &&
      loanTypeID === 'secure' &&
      warrantyTypeID !== '2' &&
      loanTypeID === 'secure' &&
      warrantyTypeID !== '3'
    ) {
      setRequiredAppointment({
        required: true,
        message: '$50,000'
      })
      return
    } else if (
      amount < 50000 &&
      terms > 60 &&
      loanTypeID === 'secure' &&
      warrantyTypeID === '2'
    ) {
      setRequiredAppointment({
        required: true,
        message: '60 months'
      })
      return
    } else if (
      amount > 50000 &&
      terms > 60 &&
      loanTypeID === 'secure' &&
      warrantyTypeID === '2'
    ) {
      setRequiredAppointment({
        required: true,
        message: '$50,000 and more than 60 months'
      })
      return
    } else if (
      amount > 50000 &&
      terms < 60 &&
      loanTypeID === 'secure' &&
      warrantyTypeID === '2'
    ) {
      setRequiredAppointment({
        required: true,
        message: '$50,000'
      })
      return
    } else if (
      amount > 150000 &&
      terms <= 120 &&
      loanTypeID === 'secure' &&
      warrantyTypeID === '3'
    ) {
      setRequiredAppointment({
        required: true,
        message: '$150,000'
      })
      return
    } else if (
      amount <= 150000 &&
      terms > 120 &&
      loanTypeID === 'secure' &&
      warrantyTypeID === '3'
    ) {
      setRequiredAppointment({
        required: true,
        message: '120 months'
      })
      return
    } else if (
      amount > 150000 &&
      terms > 120 &&
      loanTypeID === 'secure' &&
      warrantyTypeID === '3'
    ) {
      setRequiredAppointment({
        required: true,
        message: '$150,000 and more than 120 months'
      })
      return
    } else {
      setRequiredAppointment({
        required: false,
        message: ''
      })
      return
    }
  }, [amount, terms])

  return (
    <Box className={classes.LoanCalculatorContainer}>
      <Box>
        {scheduleAppointment &&
        loanTypeID !== 'refinancing' &&
        loanTypeID === 'secure' &&
        warrantyTypeID !== '2' &&
        warrantyTypeID !== '3'
          ? (amount > 50000 || terms > 120) && (
              <Appointments
                term={terms}
                amount={amount}
                message={requiredAppointment.message}
              />
            )
          : null}
      </Box>

      <Box>
        {scheduleAppointment &&
        loanTypeID === 'secure' &&
        warrantyTypeID === '2'
          ? (amount > 50000 || terms > 60) && (
              <Appointments
                amount={amount}
                term={terms}
                message={requiredAppointment.message}
                typeOfLoansRefSelected={typeOfLoansRefSelected}
              />
            )
          : null}
      </Box>

      <Box>
        {scheduleAppointment &&
        loanTypeID === 'secure' &&
        warrantyTypeID === '3'
          ? (amount > 150000 || terms > 120) && (
              <Appointments
                amount={amount}
                term={terms}
                message={requiredAppointment.message}
                typeOfLoansRefSelected={typeOfLoansRefSelected}
              />
            )
          : null}
      </Box>

      <Box>
        {scheduleAppointment && loanTypeID === 'refinancing'
          ? (loand_request.totalLoanRequests > 50000 || terms > 60) && (
              <Appointments
                amount={loand_request.totalLoanRequests}
                term={terms}
                s
                message={requiredAppointment.message}
                typeOfLoansRefSelected={typeOfLoansRefSelected}
              />
            )
          : null}
      </Box>
      {/*
      <Box>
        {amount && terms
          ? terms > 60 && loanTypeID === 'refinancing' && <Appointments />
          : null}
      </Box>
      */}
      {loanTypeID !== '' ? (
        // ((amount > 50000 ||
        //   terms > 120 ||
        //   loand_request.totalLoanRequests > 50000) &&
        //   loanTypeID === 'secure' &&
        //   warrantyTypeID !== '3') ||
        ((loand_request.totalLoanRequests > 50000 || terms > 60) &&
          loanTypeID === 'refinancing') ||
        ((amount > 50000 || terms > 60) &&
          loanTypeID === 'secure' &&
          warrantyTypeID === '2') ||
        // ((amount > 150000 || terms > 120) && warrantyTypeID === '3')
        ((amount > 150000 || terms > 120) &&
          warrantyTypeID === '3' &&
          scheduleAppointment) ? null : (
          <>
            <Box className={classes.LoanCalculatorHeader}>
              <Typography className={classes.CalculatorHeaderText}>
                LOAN CALCULATOR
              </Typography>
            </Box>

            {typeOfLoansRefSelected &&
              typeOfLoansRefSelected.includes('Secured') && (
                <Alert
                  icon={false}
                  sx={{
                    backgroundColor: '#F4EBFE',
                    fontStyle: 'normal',
                    fontWeight: '300',
                    fontSize: '18px',
                    lineHeight: '25px',
                    fontFamily: 'avenirMedium !important',
                    marginTop: 4,
                    padding: '1.5rem 1rem'
                  }}
                >
                  <Typography color={'#706F6F'}>
                    Fully or partially secured facilities may include additional
                    fees that have not been added to the total amount of your
                    loan. We will disclose the full details of these fees
                    immediately upon the revision of your loan facility where we
                    will be able to determine the exact amount, if any. This
                    information will also be available on the loan disclosure
                    form that you will be required to sign.
                  </Typography>
                </Alert>
              )}

            <form onSubmit={formik.handleSubmit}>
              <Stack
                direction='column'
                spacing={{ xs: 1, sm: 2, md: 4 }}
                mt={5}
                // mb={6}
              >
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2 }}
                >
                  {loanTypeID === 'secure' && isVehicle && (
                    <Input
                      // disabled
                      className={classes.BlankInput}
                      Lable={isVehicle ? 'LOAN AMOUNT' : 'LOAN VALUE'}
                      name='requestedAmount'
                      onChange={formik.handleChange}
                      value={
                        amount
                          ? formatter.format(amount)
                          : amount || loand_request.requestedAmount
                      }
                      readOnly
                      disabled={true}
                    />
                  )}
                  {loanTypeID === 'secure' && warrantyTypeID === '3' && (
                    <Input
                      // disabled
                      className={classes.BlankInput}
                      Lable='LOAN FEES'
                      name='loanFees'
                      onChange={formik.handleChange}
                      value={formatter.format(loand_request.fees)}
                      readOnly
                      disabled={true}
                    />
                  )}

                  {loanTypeID === 'refinancing' && (
                    <Input
                      // disabled
                      className={classes.BlankInput}
                      Lable='LOAN FEES'
                      name='loanFees'
                      onChange={formik.handleChange}
                      value={formatter.format(loand_request.fees)}
                      readOnly
                      disabled={true}
                    />
                  )}

                  {loanTypeID !== 'refinancing' && (
                    <Input
                      // disabled
                      className={classes.BlankInput}
                      Lable='LOAN TERM'
                      name='requestedTerms'
                      onChange={formik.handleChange}
                      value={
                        formik.values.requestedTerms ||
                        terms ||
                        loand_request.requestedTerms
                      }
                      readOnly
                      disabled={true}
                    />
                  )}

                  {/* {loanTypeID === 'refinancing' && (
            <Input
              // disabled
              className={classes.BlankInput}
              Lable='DEBT CANCELLATION SCHEME'
              name='debtCancellationSchemeFee'
              onChange={formik.handleChange}
              value={formatter.format(
                loand_request.debtCancellationSchemeFee
              )}
              readOnly
              disabled={true}
            />
          )} */}
                </Stack>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2 }}
                >
                  {((loanTypeID === 'secure' && warrantyTypeID === '3') ||
                    (loanTypeID === 'secure' && warrantyTypeID === '2')) && (
                    <div style={{ width: '100%' }}>
                      <Input
                        // disabled
                        className={classes.BlankInput}
                        Lable='PROCESSING FEES'
                        name='requestedAmount'
                        onChange={formik.handleChange}
                        value={
                          loanTypeID === 'secure' && warrantyTypeID === '3'
                            ? formatter.format(loand_request.legalFees)
                            : formatter.format(loand_request.billOfSale)
                        }
                        readOnly
                        disabled={true}
                      />
                      <Typography className={classes.Text}>
                        {warrantyTypeID === '3' && typeOfLandTransfer === 1
                          ? 'The fee presented is an estimate of the processing fees (stamp duty and professional fees) based on the assumption that your land is free of any charge. This amount is subject to change.'
                          : warrantyTypeID === '3' && typeOfLandTransfer === 2
                          ? ' The fee presented is an estimate of the legal fees (stamp duty and professional fees) based on type of transfer selected and the purchase price entered. This amount is subject to change.'
                          : 'The fee presented is an estimate of the bill of sale which is subject to change.'}
                      </Typography>
                    </div>
                  )}
                  {loanTypeID === 'secure' && warrantyTypeID === '2' && (
                    <Input
                      // disabled
                      className={classes.BlankInput}
                      Lable='LOAN FEES'
                      name='loanFees'
                      onChange={formik.handleChange}
                      value={formatter.format(loand_request.fees)}
                      readOnly
                      disabled={true}
                    />
                  )}
                  {/* {loanTypeID === 'secure' && warrantyTypeID === '3' && (
            <Input
              // disabled
              className={classes.BlankInput}
              Lable='DEBT CANCELLATION SCHEME'
              name='debtCancellationSchemeFee'
              onChange={formik.handleChange}
              value={formatter.format(
                loand_request.debtCancellationSchemeFee
              )}
              readOnly
              disabled={true}
            />
          )} */}
                </Stack>

                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2 }}
                >
                  {isVehicle && loanTypeID === 'secure' && (
                    <Input
                      // disabled
                      className={classes.BlankInput}
                      Lable='TOTAL LOAN AMOUNT'
                      name='requestedTerms'
                      onChange={formik.handleChange}
                      value={formatter.format(loand_request.totalAmount)}
                      readOnly
                      disabled={true}
                    />
                  )}
                  {/* {loanTypeID === 'secure' && warrantyTypeID === '2' && (
            <Input
              // disabled
              className={classes.BlankInput}
              Lable='DEBT CANCELLATION SCHEME'
              name='debtCancellationSchemeFee'
              onChange={formik.handleChange}
              value={formatter.format(
                loand_request.debtCancellationSchemeFee
              )}
              readOnly
              disabled={true}
            />
          )} */}
                </Stack>

                <Stack
                  style={{
                    marginTop: loanTypeID === 'refinancing' ? '-1rem' : '15px'
                  }}
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2 }}
                  width={'100%'}
                >
                  {(warrantyTypeID === '3' || loanTypeID === 'refinancing') && (
                    <Input
                      // disabled
                      className={classes.BlankInput}
                      Lable='TOTAL LOAN AMOUNT'
                      name='requestedTerms'
                      onChange={formik.handleChange}
                      value={formatter.format(loand_request.totalAmount)}
                      readOnly
                      disabled={true}
                    />
                  )}
                </Stack>

                {/* <Stack sx={{ width: '50%' }} style={{ display: 'none' }}>
           <InputLabel className={classes.InputLable}>
            LOAN RATE
          </InputLabel> 

          loanRate !== 0 && (
                <Input
                  className={classes.BlankInput}
                  Lable="LOAN RATE"
                  value={
                    loanRate
                      ? `${loanRate * 100 }%`
                      : loanRate
                  }
                  readOnly
                  disabled={true}
                />
                )
        </Stack> */}

                <Stack className={classes.debCancellaionWidth}>
                  {/* <InputLabel className={classes.InputLable}>
            DEBT CANCELLATION SCHEME
          </InputLabel> */}
                  {radioChecked === true && (
                    <>
                      {totalDebtCancellationSchemeFee !== 0 && (
                        <Input
                          className={classes.BlankInput}
                          Lable='TOTAL DEBT CANCELLATION AMOUNT'
                          value={
                            totalDebtCancellationSchemeFee
                              ? formatter.format(totalDebtCancellationSchemeFee)
                              : totalDebtCancellationSchemeFee
                          }
                          readOnly
                          disabled={true}
                        />
                      )}
                    </>
                  )}
                </Stack>
              </Stack>

              {amount < 50000 || loand_request.requestedAmount < 50000 ? (
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2 }}
                >
                  <Stack className={classes.ContainerTermsAndConditions}>
                    <>
                      <Link
                        to={
                          loanType === 'secure'
                            ? `/Customer/MonthlyInstallments/TermsAndConditions/secure`
                            : `/Customer/yourLoanRequest/TermsAndConditions`
                        } //`/Customer/yourLoanRequest/TermsAndConditions/unsecure`}
                        className={classes.Links}
                      >
                        <Button
                          className={classes.BtnTermsAndConditions}
                          Title='TERMS AND CONDITIONS'
                          BtnVariant='contained'
                          style={{ padding: 28 }}
                        />
                      </Link>
                      <Stack direction='row' alignItems='center' mt={1}>
                        <Radio
                          checked={radioChecked}
                          onClick={(e) => {
                            setRadioChecked((current) => !current)
                          }}
                        />
                        <InputLabel
                          style={{ whiteSpace: 'pre-wrap', fontSize: '1rem' }}
                        >
                          I accept the terms and conditions
                        </InputLabel>
                      </Stack>
                    </>
                  </Stack>
                  <Stack
                    // width="50%"
                    className={classes.recommend}
                    // style={{ marginTop: '-50px' }}
                  >
                    <img src={Recomend} alt='' style={{ width: '301.07px' }} />
                  </Stack>
                </Stack>
              ) : null}

              {/* <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2 }}
        mt={4}
      >
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          className={classes.RecomendLeftSec}
        >
          <Stack className={classes.RecomendedInputs} sx={{ width: '100%' }}>
            <Total
              className={classes.LoanFees}
              Lable='LOAN FEES'
              Text={fees || 0}
              iconTolltip={false}
            />
          </Stack>
        </Stack>

      </Stack> */}

              {loanTypeID === 'refinancing' && (
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2 }}
                  mt={4}
                  mb={2}
                >
                  <Stack direction='column' className={classes.Promo}>
                    {' '}
                    {/* className={classes.Promo} */}
                    <Total
                      className={classes.MonthlyInsurence}
                      Lable='CURRENT MONTHLY INSTALLMENT'
                      style={{ fontSize: 20, color: '#C2C7CC' }}
                      Text={currentMonthlyInstallment}
                      messageToltip={TooltipMessage.MONTHLY_INSTALLMENT}
                    />
                  </Stack>
                </Stack>
              )}

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2 }}
                mt={4}
                mb={2}
              >
                <Stack direction='column' className={classes.Promo}>
                  {' '}
                  {/* className={classes.Promo} */}
                  <Total
                    className={classes.MonthlyInsurence}
                    Lable={
                      loanTypeID === 'refinancing'
                        ? 'NEW ESTIMATED MONTHLY INSTALLMENT'
                        : 'ESTIMATED MONTHLY INSTALLMENT'
                    }
                    style={{ fontSize: 20 }}
                    Text={Monthly}
                    messageToltip={TooltipMessage.MONTHLY_INSTALLMENT}
                  />
                  <Box
                    className={classes.EnterPromoCode}
                    onClick={(values) => {
                      setPromoCode((current) => !current)
                    }}
                  >
                    <Typography>ENTER PROMO CODE</Typography>
                  </Box>
                </Stack>

                <Stack direction='column' className={classes.Promo}>
                  {promoCode && (
                    <Input
                      className={classes.PromoCode}
                      Lable='PROMO CODE'
                      onChange={(e) => {
                        formik.setFieldValue('promoCode', e.target.value)
                        setPromoCodeValue(e.target.value)
                      }}
                      value={promoCodeValue}
                      name='promoCode'
                    />
                  )}
                </Stack>
              </Stack>

              <Stack direction='column' className={classes.Promo}>
                {monthlyInstallmentWithPromoCode ? (
                  <Total
                    className={classes.MonthlyInsurence}
                    Lable='YOUR NEW MONTHLY INSTALLMENT'
                    style={{ fontSize: 20 }}
                    Text={monthlyInstallmentWithPromoCode || 0}
                    messageToltip={TooltipMessage.YOUR_NEW_MONTHLY_INSTALLMENT}
                  />
                ) : null}
              </Stack>
              <Alert
                icon={false}
                sx={{
                  backgroundColor: '#F4EBFE',
                  fontStyle: 'normal',
                  fontWeight: '300',
                  fontSize: '18px',
                  lineHeight: '25px',
                  fontFamily: 'avenirMedium !important',
                  marginTop: 5
                }}
              >
                The interest rate used to calculate your monthly installment is
                the Bank's base rate. This rate is subject to change based on
                risk. After the loan application is received, the interest rate
                will be adjusted accordingly, and the final rate will be
                presented in your final loan documentation.{' '}
                <strong>
                  As a Belize Bank customer you participate in the loyalty
                  program and enjoy lower interest rates.
                </strong>
              </Alert>
            </form>
            {showModal && <InvalidPromoCode handleClick={handleClick} />}
          </>
        )
      ) : null}
    </Box>
  )
}
export default LoanCalculator
