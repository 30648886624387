import Title from '../../atoms/Title/Title'
import Button from '../../../Button/Button'
import { Card, CardContent, Grid, Item } from '@mui/material'

//styles
import { useStyles } from './InfoCardStyles'
import Image from '../../atoms/Image/Image'
import ErrorIcon from '../../../../assets/Icons/Error-Icon.png'
import CalendarIcon from '../../../../assets/Icons/calendar-circle.png'

const InfoCard = ({ InfoCardData, Action }) => {
  const classes = useStyles()

  return (
    <Card style={{backgroundColor: InfoCardData.color}}>
        <CardContent>
            <Grid container className={classes.appointmentMobile}>
                <Grid item xs={1} md={1} justifyContent={'space-evenly'} alignItems={"center"}>
                    <Image src={InfoCardData.Icon} width={'32px'} height={'32px'}/>
                </Grid>
                <Grid item xs={12} md={8} justifyContent={"flex-end"} alignItems={"center"}>
                    <Title           
                    Text={InfoCardData.Title}
                    margin={'0px'} 
                    fontWeight={"100"} 
                    size={"12px"} 
                    aling='left'
                    />
                </Grid>
                <Grid item xs={3} md={3} justifyContent={"flex-end"} alignItems={"center"}>
                    {
                        InfoCardData.ShowButton &&
                        <Button
                        Title='CHANGE'
                        variant='h7'
                        color='primary'
                        className={classes.NextButton}
                        BtnVariant='contained'
                        onClick={Action}
                        />
                    }
                </Grid>
            </Grid>
        </CardContent>
    </Card>
  )
}

export default InfoCard
