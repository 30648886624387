import axios from "axios"
import Actions from "../Types"
import LocalStoreServices from "../../Utils/LocalStoreServices"
import { baseUrl } from "../../baseUrl"

const localStorageServices = LocalStoreServices.getService()

export function referenceValidation(bblCode) {
  return async (dispatch, getState) => {
    const url = `${baseUrl}/customers/referenceNumber/${bblCode}`
    dispatch({ type: Actions.LOADING, payload: true })
    dispatch({ type: Actions.REFERENCESUCCESS, payload: false })
    dispatch({ type: Actions.REFERENCEDECLINED, payload: false })
    dispatch({ type: Actions.REFERENCEFINALIZED, payload: false })
    dispatch({
      type: Actions.REFERENCELINK,
      payload: "/Customer/yourLoanRequest",
    })
    await axios
      .get(url)
      .then((response) => {
        const status = response.data.status
        dispatch({ type: Actions.STATUS, payload: status })
        switch (status) {
          case 0:
            dispatch({ type: Actions.REFERENCESUCCESS, payload: true })
            localStorageServices.setToken(response.data.jwt)
            localStorageServices.setExpirationDate(response.data.expirationDate)
            break
          case 1:
          case 2:
            dispatch({ type: Actions.REFERENCELINK, payload: "/Loan-Status" })
            dispatch({ type: Actions.REFERENCESUCCESS, payload: true })
            localStorageServices.setToken(response.data.jwt)
            localStorageServices.setExpirationDate(response.data.expirationDate)
            break
          case 3:
            dispatch({
              type: Actions.REFERENCELINK,
              payload: "/Customer/scheduleAppointment",
            })
            dispatch({ type: Actions.REFERENCESUCCESS, payload: true })
            localStorageServices.setToken(response.data.jwt)
            localStorageServices.setExpirationDate(response.data.expirationDate)
            break
          case 4:
            dispatch({ type: Actions.REFERENCEDECLINED, payload: true })
            break
          case 10:
            dispatch({ type: Actions.REFERENCEFINALIZED, payload: true })
            break
          default:
            dispatch({ type: Actions.REFERENCESTATUSERROR, payload: true })
            break
        }
        dispatch({ type: Actions.REFERENCECODE, payload: status }) // no delete
        localStorageServices.setReferenceStatus(status)
      })
      .catch((e) => {
        if (e.response.status == 404 ) {
          dispatch({ type: Actions.REFERENCESTATUSERROR, payload: true })
        }
      })
      .finally(() => {
        dispatch({ type: Actions.LOADING, payload: false })
      })
  }
}
