import React, { useEffect, useState } from "react"
import { Box } from "@mui/system"
import { useStyles } from "./LoanStatusStyle"
import SmilingFace from "../../../assets/Images/SmilingFace.png"
import { Stack, Typography } from "@mui/material"
import Input from "../../Inputs/Input"
import moment from "moment"
import ArrowButton from "../../ArrowButton/ArrowButton"
import { Interceptor } from "../../../Utils/Interceptor"
import axios from "axios"
import { useRef } from "react"
import { useDispatch } from "react-redux"
import Actions from "../../../Store/Types"

const Status = () => {
  const [userData, setUserData] = useState({})
  const [districtName, setDistrictName] = useState("")
  const [status, setStatus] = useState()
  const dispatch = useDispatch()
  const countPage = useRef(0)
  useEffect(() => {
    if (countPage.current === 0) {
      countPage.current = 1
      window.scroll(0, 0)
      return
    }
  }, [])

  useEffect(() => {
    dispatch({ type: Actions.LOADING, payload: true })
    const AxiosInterceptor = Interceptor()

    ;(async function () {
      const config = {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjU3ZDUxZGU4LTRhYTQtNDc2OS1hODJhLTk4OGUxYzZlNmE3MyIsIm5hbWUiOm51bGwsImVtYWlsIjoic2VyZ2lvLnVycnV0aWF3QGdtYWlsLmNvbSIsImlhdCI6MTY2NTcwMTgwMCwiZXhwIjoxNjY1NzIzNDAwfQ.hiq5alXwAK5fzZlLiq4jxrC6mcWAeJiM3PSeVV8jUPw`,
        },
      }
      await AxiosInterceptor.get("/customer/loan-status")
        .then((response) => {
          setUserData(response.data)
        })
        .catch((e) => {})
        .finally(() => {
          dispatch({ type: Actions.LOADING, payload: false })
        })
    })()
  }, [])
  useEffect(() => {
    ;(async function () {
      if (userData.districtId) {
        const AxiosInterceptor = Interceptor()
        await AxiosInterceptor.get(`districts/${userData.districtId}`)
          .then((response) => {
            setDistrictName(response.data.name)
          })
          .catch((e) => {})
      }
    })()
    switch (userData.status) {
      case 0:
        setStatus("created")
        break
      case 1:
        setStatus("Submitted")
        break
      case 2:
        setStatus("In Progress")
        break
      case 3:
        setStatus("Approved")
        break
    }
  }, [userData])
  const classes = useStyles()
  return (
    <Box m={4}>
      <Box className={classes.LoanStatusContainer}>
        <Box className={classes.WelcomeMessageContainer}>
          <Box className={classes.SmilingImageContainer}>
            <img className={classes.SmilingImage} src={SmilingFace} alt="" />
          </Box>
          <Typography className={classes.WelcomeMessage}>
            Welcome {userData.firstName}!
          </Typography>
        </Box>
        <Box className={classes.Details}>
          <Typography>
            {`${userData.firstName} ${userData.lastName}`} <br />
            {` ${userData.address} `} <br />
            {districtName}
            {/* Juliana Ceballos Independence Avenue # 342 Belize */}
          </Typography>
        </Box>

        <Box className={classes.LoanStatusSection}>
          <Box className={classes.LoanStatusSectionHeader}>Loan Status</Box>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2 }}
            mt={3}
          >
            <Input
              Lable="LOAN APPLICATION"
              value={userData.referenceNumber}
              readOnly={"readOnly"}
            />
            <Input
              type="date"
              Lable="DATE OF APPLICATION"
              value={moment(userData.createdDate).format("yyyy-MM-DD")}
              readOnly={"readOnly"}
            />
          </Stack>
          <Stack width={{ xs: "100%", sm: "49%" }} mt={2}>
            <Input Lable="LOAN STATUS" value={status} readOnly={"readOnly"} />
          </Stack>
        </Box>
      </Box>
      <ArrowButton link="" back="/ReferenceNumber" />
    </Box>
  )
}

export default Status
