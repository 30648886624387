import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  sizeVehicleStatus: {
    width: '90%',
    '@media (max-width: 900px)': {
      width: '100%',
    },
  },
  FullButton: {
    width: '100%',
    backgroundColor: "#275da6 !important",
    height: "50px"
  },
  Container: {
    width: '900px',
    margin: '0 auto 70px auto',
    '@media (max-width: 1000px)': {
      width: '100%'
    }
  },
  Titles: {
    fontFamily: theme.fonts.family.quaternary,
    fontSize: '1rem',
    fontWeight: '700 !important',
    color: '#00000080 !important'
  },
  Button: {
    width: 176,
    height: 39,
    margin: '0 auto',
    '&.MuiButtonBase-root': {
      margin: '0 auto',
      marginBottom: 30,
      marginTop: 50,
      backgroundColor: theme.colors.primary.Darkblue,
      color: 'white',
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: theme.colors.secondary.Green
      },
      '& .MuiTypography-root': {
        fontSize: 13,
        fontWeight: 600,
        letterSpacing: '0.2em'
      },
      '&.Mui-disabled': {
        backgroundColor: '#9E9E9E'
      }
    },
    '.makeStyles-Button-18.MuiButtonBase-root': {
      backgroundColor: '#9E9E9E'
    }
  },
  InputValue: {
    
    height: '3rem',
    "& .MuiInputBase-root": {
        marginTop: '1rem !important',
        width: '90%',
    },
    "& .MuiInputBase-input": {
      height: '2rem !important',
  },
  '@media (max-width: 800px)': {
    width: '100%',
  }
  },
  Input: {
    height: '3rem',
    "& .MuiInputBase-root": {
        marginTop: '1rem !important',
    },
    "& .MuiInputBase-input": {
      height: '2rem !important',
  }
  },
  SelectionItem: {
    fontFamily: theme.fonts.family.primary,
    margin: '0',
    "&:hover":{
      backgroundColor: `${theme.colors.primary.Darkblue} !important`,
      color: 'white'
    }
  },
  RedTitle: {
    fontFamily: theme.fonts.family.quaternary,
    fontSize: '1rem',
    fontWeight: '500 !important',
    color: '#EB5757 !important',
    // marginBottom: '2rem !important'
  },
  
}))
