import React from 'react'
import { useSelector } from 'react-redux'
import { Fragment } from 'react'
import Guarantees from './Guarantees'
import { useStyles } from '../styles'
import BackNext from '../../../components/back-previous-button/'
import { useNavigate } from 'react-router-dom'
import { Interceptor } from '../../../../Utils/Interceptor'

const Index = () => {
  const classes = useStyles()
  const { selectOption } = useSelector(state => state.initialLoanProcess)
  const navigate = useNavigate()

  const AxiosInterceptor = Interceptor()

  const handleSelectAssetCollateral = () => {
    if (!selectOption.guarantee) return
    sessionStorage.setItem('loan', `${selectOption.loanType}`)
    sessionStorage.setItem('warrantyType', `${selectOption.guarantee}`)
    sessionStorage.setItem('warrantyTypeID', `${selectOption.warrantyTypeId}`)
    navigate(`/Customer/PersonalInformationReview?loan=${selectOption.loanType}&collateral=${selectOption.guarantee}`)
  }

  return (
    <Fragment>
      <h2 className={classes.responsiveH2}>{String("Please asset as collateral for the loan.").toLocaleUpperCase()}</h2>
      <Guarantees />
      {selectOption.guarantee && ( <BackNext onNext={handleSelectAssetCollateral} /> )}
    </Fragment>
  )
}

export default Index