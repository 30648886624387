import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../auth/jwt/Jwt'

export const hasVehicleBrand = createAsyncThunk(
  'appVehicleBrand/vehicleBrand',
  async () => {
    const response = await Jwt.getvehicleBrand()
    return response.data
  }
)

const initialState = {
  brands: []
}

export const appvehicleBrandSlice = createSlice({
  name: 'appvehicleBrand',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hasVehicleBrand.fulfilled, (state, action) => {
      state.brands = action.payload
    })
  }
})

export const { handleGetBrands } = appvehicleBrandSlice.actions

export default appvehicleBrandSlice.reducer
