export class Guarantee {
  #guaranteeType = null
  #typesOfGuarantee = { cash: 'cash', vehicle: 'vehicle', land: 'land' }

  constructor() {
  }

  isValidGuaranteeType (guarantee) {
    return this.#typesOfGuarantee.hasOwnProperty(guarantee)
  }

}